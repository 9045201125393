import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import {
    Input,
} from 'reactstrap'

import LinearProgress from "@material-ui/core/LinearProgress";
import data from './data.json'
import { withRouter } from "react-router-dom";
import Map from './map'
import ReactLoading from "react-loading";
import worldMapData from 'city-state-country'
import { width } from '@mui/system'


class BlogAndPriceAdPicture extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            business_id: '',
            advertise_type: '',
            formErradvertise_type: '',
            page_type: '',
            formErrpageType: '',

            picture_type: '',
            formErrpictureType: '',
            location: '',
            formErrlocation: '',
            longitude: 23.53,
            latitude: 53.65,
            picture: '',
            formErrpicture: '',
            file: '',


        }
        this.deletePicture = this.deletePicture.bind(this)
        this.handlelatlong = this.handlelatlong.bind(this);
        this.handleUploadFile1 = this.handleUploadFile1.bind(this);
    }





    async updateState(data) {
        setTimeout(async () => {
            await this.setState(data)
        }, 1000)
    }
    handleUploadFile1 = async (event) => {
        await this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            picture: event.target.files[0],
            formErrpicture: ''
        })
    }
    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })

        if (key === "page_type") {
            await self.setState({
                page_type: value,
                formErrpageType: ''
            })
        }
        if (key === "picture_type") {
            await self.setState({
                picture_type: value,
                formErrpictureType: ''
            })
        }
        if (key === "advertise_type") {
            await self.setState({
                advertise_type: value,
                formErradvertise_type: ''
            })
        }
        if (key === "location") {
            await self.setState({
                location: value,
                formErrlocation: ''
            })
        }

        if (key === "country") {
            let result = await this.state.country_list.filter(function (item) {
                if (item.sortname == value) return item.name;
            });
            if (result.length) {
                await this.setState({
                    country_name: result[0].name
                })

            }
        }

    }
    handleSubmit = async () => {
        await this.setState({
            formErrpicture: (this.state.picture === '') ? "Please provide this field" : '',
            formErrpictureType: (this.state.picture_type === '') ? "Please provide this field" : '',
            formErrlocation: (this.state.location === '') ? "Please provide this field" : '',
            formErrpageType: this.state.advertise_type == 'blog' ? (this.state.page_type === '') ? "Please provide this field" : '' : '',
            formErradvertise_type: (this.state.advertise_type === '') ? "Please provide this field" : '',
        })
        if (this.state.picture !== '' && this.state.advertise_type !== '' && this.state.business_id !== '' && this.state.picture_type !== '') {

            const data = new FormData();
            data.append('businessId', this.state.business_id);
            if (this.state.advertise_type == 'blog' && this.state.page_type) {
                data.append('page_type', this.state.page_type);
            }
            data.append('advertise_type', this.state.advertise_type);
            if (this.state.page_type !== 'blogdetails') {
                data.append('latitude', this.state.latitude);
                data.append('longitude', this.state.longitude);
            }
            data.append('picture', this.state.picture);
            data.append('size_type', this.state.picture_type)
            data.append('media_type', 'picture')
            await this.props.advertisementPictureUpload(data);
            await this.setState({
                page_type: '',
                picture: '',
                file: '',
                picture_type: '',
                advertise_type: ''
            })

        }
    }
    UNSAFE_componentWillMount = async () => {
        // await this.props.fetchcountry()
        // await this.props.fetchcountry()
        // await this.setState({
        //   country_list:worldMapData.getAllCountries()
        // })
    }

    handleAddress = (value) => {
        this.setState({
            location: value
        })
    }

    async componentDidMount() {
        const country_array = await worldMapData.getAllCountries()
        await this.setState({
            country_list: country_array
        })
    }
    componentDidMount = async () => {
        let business_id = await this.props.match.params.id;
        if (business_id) {
            await this.setState({
                business_id: business_id
            });
            let data = { businessId: business_id, media_type: 'picture' }
            await this.props.getAdvertisementPictures(data)
        }
    };
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success } = this.props;
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
    }
    handlelatlong = (value) => {
        this.setState({
            latitude: value.latitude,
            longitude: value.longitude
        })
    }
    handlepincode = (value) => {

    }
    handlecityname = (value) => {

    }
    deletePicture = async (e, mediaId) => {
        e.preventDefault()
        let data = {
            mediaId: mediaId,
            businessId: this.state.business_id
        }
        await this.props.blogPriceAdvertisementPictureDelete(data)
    }
    cancelUpdate() {
        this.setState({ isEdit1: false })
    }


    render() {
        let business_id = this.props.match.params.id;
        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Add Blog And Pricing Advertisment Picture</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='clearfix'>
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Select Advertisement Type</label>
                                                        <Input
                                                            value={this.state.advertise_type}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('advertise_type', e.target.value)}
                                                        >
                                                            {['blog', 'pricing'].map((option) => (

                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErradvertise_type}</span>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.advertise_type == 'blog' ?
                                                        <div className='col-lg-6'>
                                                            <div className='form-group'>
                                                                <label className='form-control-label' for='input-first-name'>Select Page Type</label>
                                                                <Input
                                                                    value={this.state.page_type}
                                                                    type="select"
                                                                    onChange={(e) => this.onChangeAddName('page_type', e.target.value)}
                                                                >
                                                                    {['bloglist', 'blogdetails'].map((option) => (
                                                                        <option value={option}>{option}</option>
                                                                    ))}
                                                                </Input>
                                                                <span style={{ color: 'red' }}>{this.state.formErrpageType}</span>
                                                            </div>
                                                        </div> : ''
                                                }

                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Select Picture Type</label>
                                                        {/* size type*/}
                                                        <Input
                                                            value={this.state.picture_type}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('picture_type', e.target.value)}
                                                        >
                                                            {['banner', 'square'].map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrpictureType}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='text-center'>
                                                        <img style={{ maxWidth: "350px", margin: "0 auto" }}
                                                            src={
                                                                this.state.file === ''
                                                                    ? '../assets/img/theme/img-1-1000x600.jpg'
                                                                    : this.state.file
                                                            }
                                                            alt='upload picture here'
                                                            className='card-img-top'
                                                        />
                                                    </div>
                                                    <span style={{ color: 'red' }}>
                                                        {this.state.formErrpicture}
                                                    </span>
                                                    <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                                                        <Input
                                                            type='file'
                                                            // id="File-2"
                                                            className='btn btn-default float-right'
                                                            onChange={this.handleUploadFile1}
                                                        >
                                                            Upload
                                                        </Input>

                                                    </div>
                                                    <div>{this.props.progress > 0 ? <LinearProgress variant="determinate" value={this.props.progress} /> : ''}</div>

                                                </div>

                                                {this.state.page_type !== 'blogdetails' ?
                                                    <div className='col-lg-6'>
                                                        <div className='form-group'>
                                                            <label className='form-control-label' for='input-first-name'>Business Location</label>
                                                            <input type='text' id='input-first-name' className='form-control' placeholder='Business Location'
                                                                value={this.state.location} onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                                            />
                                                            <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                                                        </div>
                                                        <div
                                                            style={{
                                                                height: '500px',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Map
                                                                center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                                handlelatlong={(e) => this.handlelatlong(e)}
                                                                handleAddress={(e) => this.handleAddress(e)}
                                                                handlepincode={(e) => this.handlepincode(e)}
                                                                handlecityname={(e) => this.handlecityname(e)}
                                                                height='400px'
                                                                value={this.state.location}
                                                                onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                                                zoom={15}
                                                            />
                                                        </div>
                                                        <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                                                    </div>
                                                    : ''
                                                }

                                            </div>

                                            <button
                                                className='btn btn-success float-right'
                                                type='button'
                                                onClick={(e) => this.handleSubmit()}
                                            >Save</button>
                                            <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                                        </form>
                                    </div>
                                    <div className='img-history my-4'>
                                        <h1 className='mb-4'>Uploaded Pictures</h1>
                                        <div className='row'>
                                            {this.props.advertisementPicture.map((key) => (
                                                <div className='col-md-3'>
                                                    <div className='image-cover overflow-hidden position-relative'>
                                                        <img className='rounded img-fluid'
                                                            src={key.picture_url} style={{ height: '250px', width: '100%' }} />
                                                        <div className='d-flex align-items-center justify-content-between w-100 bg-primary bg-gradient text-white px-3 py-2 position-absolute bottom-0 start-0'>
                                                            <div className='blog-advertise-media mr-2'>
                                                                <div>Advertise Type:- {key.advertise_type}</div>
                                                                <div>Page Type:- {key.page_type}</div>
                                                                <div>Size Type:- {key.size_type}</div>
                                                            </div>
                                                            <button class="btn btn-icon btn-danger btn-sm" type="button"><span class="btn-inner--icon" onClick={(e) => this.deletePicture(e, key._id)}><i class="fas fa-trash"></i></span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {
                                        this.props.loading ?
                                            <div className='loader-wrapper'>
                                                <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                            </div> : ''
                                    }
                                </div>

                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.blogPriceAdvertiseMedia.success,
        error: state.blogPriceAdvertiseMedia.error,
        loading: state.blogPriceAdvertiseMedia.loading,
        advertisementPicture: state.blogPriceAdvertiseMedia.blogPriceAdvertisePicture,
        progress: state.blogPriceAdvertiseMedia.progress,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAdvertisementPictures: (data) => dispatch(actions.getBlogPriceAdvertisementPictureOnId(data)),
        advertisementPictureUpload: (data) => dispatch(actions.blogPriceAdvertisePictureUpload(data)),
        blogPriceAdvertisementPictureDelete: (data) => dispatch(actions.blogPriceAdvertisementPictureDelete(data)),

    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(BlogAndPriceAdPicture)))
