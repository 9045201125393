import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import * as actions from './store/actions/index';
import data from './data.json';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Map from './map';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import timeZone from 'moment-timezone';
class EditProfessionals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      businessId: '',
      formErrname: '',
      professionalPurpose: '',
      formErrprofessionalPurpose: '',
      email: '',
      formErremail: '',
      mobile_no: '',
      formErrmobile_no: '',
      description: '',
      formErrdescription: '',
      businessName: '',
      formErrbusinessName: '',
      country: '',
      formErrcountry: '',
      city: '',
      formErrcity: '',
      subCategory: '',
      formErrsubCategory: '',
      workingDay: [],
      formErrworkingDay: '',
      city2: '',
      formErrcity2: '',
      workingSlots: [
        {
          time: '1',
          hours_min: 'Hours',
          slot_open_hour: '1',
          slot_open_min: '1',
          slot_hours_min: 'AM',
        },
      ],
      slot: '',
      formErrslot: '',
      formErrworkingSlots: '',
      professional_images: '',
      formErrprofessional_images: '',
      min_cost: '',
      formErrmin_cost: '',
      file: '',
      user_type: 'professional',
      country_code: '+1',
      formErrcountry_code: '',
      formFields: [{ index: 0 }],
      pictures: [
        {
          src: '../assets/img/theme/img-1-1000x600.jpg',
          alt: 'Image placeholder',
          className: 'card-img-top',
        },
      ],
      // fields_slots:[{data :"data"}],
      password: 'pass@123',
      slot_open: 3,
      backgroundCheck: '', // "Yes","No","Pending","N/A"
      formErrbackgroundCheck: '',
      driver_licence: '', // "Yes","No","Pending","N/A"
      formErrdriver_licence: '',
      insured: '', // "Yes","No","Pending","N/A"
      certificate_check: '', // "Yes","No","Pending","N/A"
      tradeLicence: '', // "Yes","No","Pending","N/A"
      commercial_insurance: '', // "Yes","No","Pending","N/A"
      formErrinsured: '',
      formErrcertificate_check: '',
      formErrtradeLicence: '',
      formErrcommercial_insurance: '',
      documents: [],
      documents_image: [],
      formErrdocuments: '',
      longitude: 0,
      latitude: 0,
      is_business_user_professional_also: false,
      formErris_business_user_professional_also: '',
      customer_instructions: '',
      formErrcustomer_instructions: '',
      professional_id: '',
      radius: 1000,
      formErrradius: '',
      delivery:false,
      rideshare:false,
      category_product_service:false,
      report:false,
      happy_hour_discount:false,
      schedule:false,
    };

    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleAddSlotClick = this.handleAddSlotClick.bind(this);
    this.clickOnDelete = this.clickOnDelete.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleUploadDocument = this.handleUploadDocument.bind(this);
    this.updateState = this.updateState.bind(this);
  }
  async updateState(data) {
    // await this.setState(data);
    setTimeout(async () => {
      console.log('Our data is fetched');
      await this.setState(data)
    }, 5000)
  }
  handleChange = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: value === '' ? 'Please provide this field' : '',
    });
  };
  deleteCertificate = async (value) => {
    if(this.props.match.params.id){
      var image = this.state.documents;
      await image.splice(value, 1); 
      this.props.removeCerificatesAction(value,this.props.match.params.id)

    }
    else{
      var image = this.state.documents;
      await image.splice(value, 1);
    }
  };
  handleUploadFile = async (event) => {
    if(this.props.match.params.id){
      await this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        professional_images: event.target.files[0],
        formErrprofessional_images: '',
      });
      const data = new FormData();
      data.append('profile_pic', this.state.professional_images);
      this.props.editProfessionalImageAction(data,this.props.match.params.id)
    }
    else{
      await this.setState({
        file: URL.createObjectURL(event.target.files[0]),
        professional_images: event.target.files[0],
        formErrprofessional_images: '',
      });
    }
    
  };
  handleUploadDocument = async (event) => {
    if(this.props.match.params.id){
      var image = this.state.documents;
      image.push(URL.createObjectURL(event.target.files[0]));

      var image_documents = this.state.documents_image;
      image_documents.push(event.target.files[0]);

      await this.setState({
        documents: image,
        formErrdocuments: '',
        documents_image: image_documents,
      });
      const data = new FormData();
      data.append('certificates', event.target.files[0]);
      this.props.addCerificatesAction(data,this.props.match.params.id)
    }
    else{
      var image = this.state.documents;
      image.push(URL.createObjectURL(event.target.files[0]));

      var image_documents = this.state.documents_image;
      image_documents.push(event.target.files[0]);

      await this.setState({
        documents: image,
        formErrdocuments: '',
        documents_image: image_documents,
      });
    }
    
  };

  handleAddSlotClick = async () => {
    const slots = [
      ...this.state.formFields,
      {
        index:
          this.state.formFields[this.state.formFields.length - 1].index + 1,
      },
    ];
    this.setState({ formFields: slots });

    const data_slot = [
      ...this.state.workingSlots,
      {
        slot: '1',
        time: '1',
        hours_min: 'Hours',
        slot_open_hour: '1',
        slot_open_min: '1',
        slot_hours_min: 'AM',
        city: '',
      },
    ];
    this.setState({ workingSlots: data_slot });
  };
  clickOnDelete = (record) => {
    if (this.state.formFields.length !== 1) {
      var arrayData = this.state.formFields;
      arrayData.pop();
      this.setState({ formFields: arrayData });
      var data_slot = this.state.workingSlots;
      data_slot.pop();
      this.setState({ workingSlots: data_slot });
    }
  };

  UNSAFE_componentWillMount = async () => {
    await this.props.fetchallsubCategory();
    await this.props.fetchbussiness();
    await this.props.fetchcountry();
  };
  componentDidMount = async () => {
    // console.log(this.props.match,"match data_professional");
    let professional_id = await this.props.match.params.id;

    if (professional_id) {
      // console.log(professional_id);
      await this.setState({
        professional_id: professional_id,
        // category_name:this.props.match.params.name
      });
      await this.props.fetchprofessional(professional_id);
      const getData = await this.props.data_professional;
      this.props.fetchprofessional(professional_id);
      // console.log(this.state.workingDay);
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: 'success' });
    error && enqueueSnackbar(error, { variant: 'error' });
  }
  handleDayClick = async (day, { selected, disabled }) => {
    
    if (disabled) {
      // Day is disabled, do nothing
      return;
    }
    if (selected) {
      // var results = this.state.workingDay.filter(function (entry) {
      //   console.log(entry.getDate)
      //   console.log(day)
      //   return (entry.getDate() != day.getDate() && entry.getMonth() != day.getMonth() && entry.getYear() != day.getYear() );
      // });
      
      var results = []
      for (let index = 0; index < this.state.workingDay.length; index++) {
        let entry = this.state.workingDay[index];
        if(entry.getDate() != day.getDate() || entry.getMonth() != day.getMonth() || entry.getYear() != day.getYear()){
          results.push(entry)
        }
        console.log(this.state.workingDay.length)
        console.log(results)
      }
      this.setState({ workingDay: results });
      return;
    }
    else{
    var arrayData = this.state.workingDay;
    arrayData.push(day);
    await this.setState({ workingDay: arrayData });
    console.log(this.state.workingDay);
    }
  };
  handleSubmit = async () => {
    await this.setState({
      formErrname: this.state.name === '' ? 'Please provide this field' : '',
      formErrprofessionalPurpose:
        this.state.professionalPurpose === ''
          ? 'Please provide this field'
          : '',
      formErremail: this.state.email === '' ? 'Please provide this field' : '',
      formErrmobile_no:
        this.state.mobile_no === '' ? 'Please provide this field' : '',
      formErrdescription:
        this.state.description === '' ? 'Please provide this field' : '',
      formErrbusinessName:
        this.state.businessName === '' ? 'Please provide this field' : '',
      // formErrcountry: (this.state.country === '') ? "Please provide this field" : '',
      // formErrcity: (this.state.city === '') ? "Please provide this field" : '',
      formErrsubCategory:
        this.state.subCategory === '' ? 'Please provide this field' : '',
      formErrworkingDay:
        this.state.workingDay.length === 0 ? 'Please provide this field' : '',
      formErrcity2: this.state.city2 === '' ? 'Please provide this field' : '',
      formErrworkingSlots:
        this.state.workingSlots.length === 0 ? 'Please provide this field' : '',
      formErrprofessional_images:
        this.state.file === '' ? 'Please provide this field' : '',
      formErrmin_cost:
        this.state.min_cost === '' ? 'Please provide this field' : '',
      formErrbackgroundCheck:
        this.state.backgroundCheck === '' ? 'Please provide this field' : '',
      formErrdriver_licence:
        this.state.driver_licence === '' ? 'Please provide this field' : '',
      formErrinsured:
        this.state.insured === '' ? 'Please provide this field' : '',
      formErrcertificate_check:
        this.state.certificate_check === '' ? 'Please provide this field' : '',
      formErrtradeLicence:
        this.state.tradeLicence === '' ? 'Please provide this field' : '',
      formErrcommercial_insurance:
        this.state.commercial_insurance === ''
          ? 'Please provide this field'
          : '',
      formErrdocuments:
        this.state.documents.length === 0 ? 'Please provide this field' : '',
      formErrcountry_code:
        this.state.country_code === '' ? 'Please provide this field' : '',
      formErrcustomer_instructions:
        this.state.customer_instructions === ''
          ? 'Please provide this field'
          : '',
      formErrradius:
        this.state.radius === '' ? 'Please provide this field' : '',
    });
    if (this.state.professional_id === '') {
      if (
        !(
          this.state.name === '' &&
          this.state.professionalPurpose === '' &&
          this.state.mobile_no === '' &&
          this.state.email === '' &&
          this.state.mobile_no === '' &&
          this.state.description === '' &&
          this.state.businessName === '' &&
          // (this.state.country === '') && (this.state.city === '') &&
          this.state.subCategory === '' &&
          this.state.workingDay.length === 0 &&
          this.state.city2 === '' &&
          this.state.workingSlots.length === 0 &&
          this.state.file === '' &&
          this.state.min_cost === '' &&
          this.state.backgroundCheck === '' &&
          this.state.driver_licence === '' &&
          this.state.insured === '' &&
          this.state.certificate_check === '' &&
          this.state.tradeLicence === '' &&
          this.state.commercial_insurance === '' &&
          this.state.documents.length === 0 &&
          this.state.country_code === '' &&
          this.state.customer_instructions === '' &&
          this.state.radius === ''
        )
      ) {
        const data = new FormData();
        // this.state.formFields
        for (let index = 0; index < this.state.formFields.length; index++) {
          const element = this.state.formFields[index];
          // console.log(element)
          this.state.formFields[index].id = index
          if(this.state.formFields[index].slot_open_hour == undefined){
            this.state.formFields[index].slot_open_hour = "00"
          }
          if(this.state.formFields[index].slot_open_min == undefined){
            this.state.formFields[index].slot_open_min = "00"
          }
          if(this.state.formFields[index].slot_hours_min == undefined){
            this.state.formFields[index].slot_hours_min = "AM"
          }
          this.state.formFields[index].slot_opening_time = this.state.formFields[index].slot_open_hour+":" +this.state.formFields[index].slot_open_min+" " +this.state.formFields[index].slot_hours_min
          if(this.state.formFields[index].hours_min === "Hours"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *60
          }
          else if(this.state.formFields[index].hours_min === "days"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *24 *60
          }
          
          else if(this.state.formFields[index].hours_min === "Minutes"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60
          }
          else{
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *60

            this.state.formFields[index].hours_min = "Hours"
          }
        }
        data.append('country_code', this.state.country_code);
        data.append('mobile_no', this.state.mobile_no);
        data.append('user_type', this.state.user_type);
        data.append('name', this.state.name);
        data.append('professionalPurpose', this.state.professionalPurpose);
        // data.append('city', this.state.city);
        // data.append('country', this.state.country);
        data.append('subCategory', this.state.subCategory);
        data.append('description', this.state.description);
        // data.append('workingDay', this.state.workingDay);
        data.append('workingSlots', JSON.stringify(this.state.formFields));
        this.state.documents_image.map((element) => {
          data.append('certificates', element);
        });
        data.append('bussinessName', this.state.businessName);
        data.append('email', this.state.email);
        data.append('profile_pic', this.state.professional_images);
        data.append('workingOffDay', JSON.stringify(this.state.workingDay));
        data.append('local_city', this.state.city2);
        // this.state.longitude
        // this.state.latitude
        data.append('latitude', this.state.latitude);
        data.append('longitude', this.state.longitude);
        data.append('password', this.state.password);
        data.append('min_cost', this.state.min_cost);
        data.append('backgroundCheck', this.state.backgroundCheck);
        data.append('tradeLicence', this.state.tradeLicence);
        data.append('insured', this.state.insured);
        data.append('certificate_check', this.state.certificate_check);
        data.append('driver_licence', this.state.driver_licence);
        data.append('commercial_insurance', this.state.commercial_insurance);
        data.append('customer_instructions', this.state.customer_instructions);
        data.append('radius', this.state.radius);
        // this.props.addCategory(data)
        data.append(
          'is_business_user_professional_also',
          this.state.is_business_user_professional_also
        );
        data.append(
          'capabilities',
          JSON.stringify({
            delivery:this.state.delivery,
            rideshare:this.state.rideshare,
            category_product_service:this.state.category_product_service,
            report:this.state.report,
            happy_hour_discount:this.state.happy_hour_discount,
            schedule:this.state.schedule,
          })
        );
        

        this.props.addProfessionalAction(data, this.props.history);
      }
    } else {
      if (
        !(
          this.state.name === '' &&
          this.state.professionalPurpose === '' &&
          this.state.mobile_no === '' &&
          this.state.email === '' &&
          this.state.mobile_no === '' &&
          this.state.description === '' &&
          this.state.businessName === '' &&
          // (this.state.country === '') && (this.state.city === '') &&
          this.state.subCategory === '' &&
          this.state.workingDay.length === 0 &&
          this.state.city2 === '' &&
          this.state.workingSlots.length === 0 &&
          this.state.file === '' &&
          this.state.min_cost === '' &&
          this.state.backgroundCheck === '' &&
          this.state.driver_licence === '' &&
          this.state.insured === '' &&
          this.state.certificate_check === '' &&
          this.state.tradeLicence === '' &&
          this.state.commercial_insurance === '' &&
          this.state.documents.length === 0 &&
          this.state.country_code === '' &&
          this.state.customer_instructions === ''
        )
      ) {
        const data = new FormData();
        for (let index = 0; index < this.state.formFields.length; index++) {
          const element = this.state.formFields[index];
          // console.log(element)
          if(this.state.formFields[index].slot_open_hour == undefined){
            this.state.formFields[index].slot_open_hour = "00"
          }
          if(this.state.formFields[index].slot_open_min == undefined){
            this.state.formFields[index].slot_open_min = "00"
          }
          if(this.state.formFields[index].slot_hours_min == undefined){
            this.state.formFields[index].slot_hours_min = "AM"
          }
          this.state.formFields[index].id = index
          this.state.formFields[index].slot_opening_time = this.state.formFields[index].slot_open_hour+":" +this.state.formFields[index].slot_open_min+" " +this.state.formFields[index].slot_hours_min
          if(this.state.formFields[index].hours_min === "Hours"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *60
          }
          else if(this.state.formFields[index].hours_min === "days"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *24 *60
          }
          
          else if(this.state.formFields[index].hours_min === "Minutes"){
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60
          }
          else{
            this.state.formFields[index].slot_time_in_minutes = parseInt(this.state.formFields[index].time) * 60 *60
            this.state.formFields[index].hours_min = "Hours"
          }
          console.log(this.state.formFields)
        }
        data.append('country_code', this.state.country_code);
        data.append('mobile_no', this.state.mobile_no);
        data.append('user_type', this.state.user_type);
        data.append('name', this.state.name);
        data.append('professionalPurpose', this.state.professionalPurpose);
        // data.append('city', this.state.city);
        // data.append('country', this.state.country);
        data.append('subCategory', this.state.subCategory);
        data.append('description', this.state.description);
        // data.append('workingDay', this.state.workingDay);
        data.append('workingSlots', JSON.stringify(this.state.formFields));
        // this.state.documents_image.map((element) => {
        //   data.append('certificates', element);
        // });
        data.append('bussinessName', this.state.businessName);
        // data.append('email', this.state.email);
        // data.append('profile_pic', this.state.professional_images);
        data.append('workingOffDay', JSON.stringify(this.state.workingDay));
        data.append('local_city', this.state.city2);
        // this.state.longitude
        // this.state.latitude
        data.append('latitude', this.state.latitude);
        data.append('longitude', this.state.longitude);
        // data.append('password', this.state.password);
        data.append('min_cost', this.state.min_cost);
        data.append('backgroundCheck', this.state.backgroundCheck);
        data.append('tradeLicence', this.state.tradeLicence);
        data.append('insured', this.state.insured);
        data.append('certificate_check', this.state.certificate_check);
        data.append('driver_licence', this.state.driver_licence);
        data.append('commercial_insurance', this.state.commercial_insurance);
        data.append('customer_instructions', this.state.customer_instructions);
        // this.props.addCategory(data)
        data.append('radius', this.state.radius);
        data.append(
          'is_business_user_professional_also',
          this.state.is_business_user_professional_also
        );
        data.append(
          'capabilities',
          JSON.stringify({
            delivery:this.state.delivery,
            rideshare:this.state.rideshare,
            category_product_service:this.state.category_product_service,
            report:this.state.report,
            happy_hour_discount:this.state.happy_hour_discount,
            schedule:this.state.schedule,
          })
        );
        
        // console.log("edit")
        this.props.editProfessionalAction(
          data,
          this.props.history,
          this.state.professional_id
        );
      }
    }
  };
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: value === '' ? 'Please provide this field' : '',
    });
    // if (key === "country") {
    //   // await this.props.fetchcity("600921c703e76716847fac0c")
    //   await this.props.fetchcity(value)
    // }
  };
  onChangeSlot = async (index, key, value) => {
    var data = this.state.formFields;
    // if (key === "time" || key === "slot_open_hour" | key === "slot_open_min") {
    //   data[index][key] = parseInt(value)
    // }
    // else {
    data[index][key] = value;
    // }

    this.setState({ formFields: data });
  };
  handlelatlong = (value) => {
    // const formvalue = { ...formValues }
    // formvalue.address.x_coordinate = value.latitude
    // formvalue.address.y_coordinate = value.longitude
    // setFormValue(formvalue)
    this.setState({
      latitude: value.latitude,
      longitude: value.longitude,
    });
    console.log(this.state.latitude);
    console.log(this.state.longitude);
  };
  handleAddress = (value) => {};
  handlepincode = (value) => {};
  handlecityname = (value) => {};
  render() {
    let professional_id = this.props.match.params.id;
    let getData = this.props.data_professional;

    if (
      professional_id &&
      (!this.state.businessId || this.state.businessId !== getData._id) &&
      this.props.data_professional &&
      this.props.data_professional._id
    ) {
      let date_data = [];
      if (getData.workingOffDay && getData.workingOffDay) {
        for (let index = 0; index < getData.workingOffDay.length; index++) {
          // if(new Date(getData.workingOffDay[index]) >= new Date()){
            date_data.push(moment(getData.workingOffDay[index]).toDate());
          // }
        }
      }

      if(this.props.match.params.id){
        let date_data = [];
        if(getData.workingOffDay){
          console.log("jkhsddddddddddd")
          getData.workingOffDay.map(async data=>{
            // await date_data.push(moment(data).toDate());4
            // var getDate = (new Date(data)).getDate()+1
            const d = new Date(data);
            d.setDate(d.getDate() + 1);
            date_data.push(d)
          })
     
          this.updateState({
            workingDay: date_data,
          })
          console.log(date_data)
        }
      }
      this.updateState({
        businessId: getData._id,
        backgroundCheck: getData.backgroundCheck,
        certificate_check: getData.certificate_check,
        tradeLicence: getData.tradeLicence,
        email: getData.email,
        name: getData.name,
        mobile_no: getData.mobile_no,
        businessName: getData.bussinessName,
        professionalPurpose: getData.professionalPurpose,
        city2: getData.local_city,
        country_code: getData.country_code,
        min_cost: getData.min_cost,
        subCategory: getData.subCategory,
        description: getData.description,
        commercial_insurance: getData.commercial_insurance,
        // workingDay:getData.workingOffDay,
        driver_licence: getData.driver_licence,
        insured: getData.insured,
        documents: getData.certificates,
        formFields: getData.workingSlots,
        file: getData.profile_pic,
        professional_images: getData.profile_pic,
        workingSlots: getData.workingSlots,
        customer_instructions: getData.customer_instructions,
        delivery:(getData.capabilities)?getData.capabilities.delivery:'',
        rideshare:(getData.capabilities)?getData.capabilities.rideshare:'',
        category_product_service:(getData.capabilities)?getData.capabilities.category_product_service:'',
        report:(getData.capabilities)?getData.capabilities.report:'',
        happy_hour_discount:(getData.capabilities)?getData.capabilities.happy_hour_discount:'',
        schedule:(getData.capabilities)?getData.capabilities.schedule:'',
        
        radius: getData.radius,
        longitude: getData.location.coordinates[0],
        latitude: getData.location.coordinates[1],
      });
    }
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>
                    {this.props.match.params.id ? 'Update' : 'Add'} Professional
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col-xl-4 order-xl-2'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-12'>
                      <h3 className='mb-0'>Professional Picture </h3>
                    </div>
                  </div>
                </div>

                <div className='card-body'>
                  <img
                    src={
                      this.state.professional_images === ''
                        ? '../assets/img/theme/img-1-1000x600.jpg'
                        : this.state.file
                    }
                    alt='Image placeholder'
                    className='card-img-top'
                  />
                  <span style={{ color: 'red' }}>
                    {this.state.formErrprofessional_images}
                  </span>
                  <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                    <Input
                      type='file'
                      // id="File-2"
                      className='btn btn-default float-right'
                      onChange={this.handleUploadFile}
                    >
                      Upload
                    </Input>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name1'
                        >
                          Background check:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.backgroundCheck === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('backgroundCheck', 'Yes')
                            }
                            id='customRadioInline1'
                            name='customRadioInline1'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline1'
                          >
                            Yes / Clear
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.backgroundCheck === 'No'}
                            onChange={(e) =>
                              this.handleChange('backgroundCheck', 'No')
                            }
                            id='customRadioInline2'
                            name='customRadioInline1'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline2'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={this.state.backgroundCheck === 'Pending'}
                            onChange={(e) =>
                              this.handleChange('backgroundCheck', 'Pending')
                            }
                            id='customRadioInline3'
                            name='customRadioInline1'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline3'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.backgroundCheck === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('backgroundCheck', 'N/A')
                            }
                            id='customRadioInline4'
                            name='customRadioInline1'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline4'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formErrbackgroundCheck}
                      </span>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name2'
                        >
                          Driver License/State ID:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.driver_licence === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('driver_licence', 'Yes')
                            }
                            id='customRadioInline5'
                            name='customRadioInline2'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline5'
                          >
                            Verified
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.driver_licence === 'No'}
                            onChange={(e) =>
                              this.handleChange('driver_licence', 'No')
                            }
                            id='customRadioInline6'
                            name='customRadioInline2'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline6'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={this.state.driver_licence === 'Pending'}
                            onChange={(e) =>
                              this.handleChange('driver_licence', 'Pending')
                            }
                            id='customRadioInline7'
                            name='customRadioInline2'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline7'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.driver_licence === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('driver_licence', 'N/A')
                            }
                            id='customRadioInline8'
                            name='customRadioInline2'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline8'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formErrdriver_licence}
                      </span>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name3'
                        >
                          Insured:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.insured === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('insured', 'Yes')
                            }
                            id='customRadioInline9'
                            name='customRadioInline3'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline9'
                          >
                            Yes
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.insured === 'No'}
                            onChange={(e) => this.handleChange('insured', 'No')}
                            id='customRadioInline10'
                            name='customRadioInline3'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline10'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={this.state.insured === 'Pending'}
                            onChange={(e) =>
                              this.handleChange('insured', 'Pending')
                            }
                            id='customRadioInline11'
                            name='customRadioInline3'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline11'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.insured === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('insured', 'N/A')
                            }
                            id='customRadioInline12'
                            name='customRadioInline3'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline12'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formErrinsured}
                      </span>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name4'
                        >
                          Certificate:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.certificate_check === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('certificate_check', 'Yes')
                            }
                            id='customRadioInline13'
                            name='customRadioInline4'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline13'
                          >
                            Verified
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.certificate_check === 'No'}
                            onChange={(e) =>
                              this.handleChange('certificate_check', 'No')
                            }
                            id='customRadioInline14'
                            name='customRadioInline4'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline14'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={this.state.certificate_check === 'Pending'}
                            onChange={(e) =>
                              this.handleChange('certificate_check', 'Pending')
                            }
                            id='customRadioInline15'
                            name='customRadioInline4'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline15'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.certificate_check === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('certificate_check', 'N/A')
                            }
                            id='customRadioInline16'
                            name='customRadioInline4'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline16'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formErrcertificate_check}
                      </span>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name5'
                        >
                          Trade License:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.tradeLicence === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('tradeLicence', 'Yes')
                            }
                            id='customRadioInline17'
                            name='customRadioInline5'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline17'
                          >
                            Verified
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.tradeLicence === 'No'}
                            onChange={(e) =>
                              this.handleChange('tradeLicence', 'No')
                            }
                            id='customRadioInline18'
                            name='customRadioInline5'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline18'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={this.state.tradeLicence === 'Pending'}
                            onChange={(e) =>
                              this.handleChange('tradeLicence', 'Pending')
                            }
                            id='customRadioInline19'
                            name='customRadioInline5'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline19'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.tradeLicence === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('tradeLicence', 'N/A')
                            }
                            id='customRadioInline20'
                            name='customRadioInline5'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline20'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                      <span style={{ color: 'red' }}>
                        {this.state.formErrtradeLicence}
                      </span>
                    </div>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label
                          className='form-control-label mr-5'
                          for='input-first-name6'
                        >
                          You have not added commercial Insured:
                        </label>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Yes'
                            checked={this.state.commercial_insurance === 'Yes'}
                            onChange={(e) =>
                              this.handleChange('commercial_insurance', 'Yes')
                            }
                            id='customRadioInline21'
                            name='customRadioInline6'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline21'
                          >
                            Yes
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='No'
                            checked={this.state.commercial_insurance === 'No'}
                            onChange={(e) =>
                              this.handleChange('commercial_insurance', 'No')
                            }
                            id='customRadioInline22'
                            name='customRadioInline6'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline22'
                          >
                            No
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='Pending'
                            checked={
                              this.state.commercial_insurance === 'Pending'
                            }
                            onChange={(e) =>
                              this.handleChange(
                                'commercial_insurance',
                                'Pending'
                              )
                            }
                            id='customRadioInline23'
                            name='customRadioInline6'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline23'
                          >
                            Pending
                          </label>
                        </div>
                        <div className='custom-control custom-radio custom-control-inline'>
                          <input
                            type='radio'
                            value='N/A'
                            checked={this.state.commercial_insurance === 'N/A'}
                            onChange={(e) =>
                              this.handleChange('commercial_insurance', 'N/A')
                            }
                            id='customRadioInline24'
                            name='customRadioInline6'
                            className='custom-control-input'
                          />
                          <label
                            className='custom-control-label'
                            for='customRadioInline24'
                          >
                            N/A
                          </label>
                        </div>
                      </div>
                    </div>
                    <span style={{ color: 'red' }}>
                      {this.state.formErrcommercial_insurance}
                    </span>
                  </div>
                  <div className='col-lg-12'>
                    <div className='form-group'>
                      <label
                        className='form-control-label mr-5'
                        for='input-first-name1'
                      >
                        Is this Professional Business User also?
                      </label>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          type='radio'
                          value={true}
                          checked={
                            this.state.is_business_user_professional_also ===
                            true
                          }
                          onChange={(e) =>
                            this.onChangeAddName(
                              'is_business_user_professional_also',
                              true
                            )
                          }
                          id='customRadioInline54'
                          name='customRadioInline54'
                          className='custom-control-input'
                        />
                        <label
                          className='custom-control-label'
                          for='customRadioInline54'
                        >
                          Yes
                        </label>
                      </div>
                      <div className='custom-control custom-radio custom-control-inline'>
                        <input
                          type='radio'
                          value={false}
                          checked={
                            this.state.is_business_user_professional_also ===
                            false
                          }
                          onChange={(e) =>
                            this.onChangeAddName(
                              'is_business_user_professional_also',
                              false
                            )
                          }
                          id='customRadioInline541'
                          name='customRadioInline54'
                          className='custom-control-input'
                        />
                        <label
                          className='custom-control-label'
                          for='customRadioInline541'
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                <div className='row'>
                <div className='col-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Capabilities</label>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck1'checked={this.state.delivery === true}
                          onChange={(e) =>
                            this.onChangeAddName('delivery', !this.state.delivery)
                          } />
                          <label class='custom-control-label' for='customCheck1'>Delivery</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck2'
                          checked={this.state.rideshare === true}
                          onChange={(e) =>
                            this.onChangeAddName('rideshare', !this.state.rideshare)
                          }
                          />
                          <label class='custom-control-label' for='customCheck2'>Ride Share</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck3'
                          checked={this.state.category_product_service === true}
                          onChange={(e) =>
                            this.onChangeAddName('category_product_service', !this.state.category_product_service)
                          }
                          />
                          <label class='custom-control-label' for='customCheck3'>Category,product & service</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck4'
                          checked={this.state.report === true}
                          onChange={(e) =>
                            this.onChangeAddName('report', !this.state.report)
                          }
                          />
                          <label class='custom-control-label' for='customCheck4'>Report</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck5' 
                          checked={this.state.happy_hour_discount === true}
                          onChange={(e) =>
                            this.onChangeAddName('happy_hour_discount', !this.state.happy_hour_discount)
                          }/>
                          <label class='custom-control-label' for='customCheck5'>Happy Hour Discount</label>
                        </div>
                        
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck6' 
                          checked={this.state.schedule === true}
                          onChange={(e) =>
                            this.onChangeAddName('schedule', !this.state.schedule)
                          }/>
                          <label class='custom-control-label' for='customCheck6'>Schedule</label>
                        </div>
                        
                      </div>

                    </div>
                  
                </div>
                </div>
                
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-12'>
                      <h3 className='mb-0'>
                        Insurance, Certificate, background check information and
                        licence.{' '}
                      </h3>
                    </div>
                    </div>
                </div>

                <div className='card-body'>
                  <div className='row'>
                    {/* <div className='col'>
                        <img src='../assets/img/theme/img-1-1000x600.jpg' alt='Image placeholder' className='card-img-top' />
                        <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                          <a href='#' className='btn btn-default float-right'>Upload</a>
                        </div>
                      </div> */}
                    <div className='col'>
                      {this.state.documents.length === 0 ? (
                        <img
                          src='../assets/img/theme/img-1-1000x600.jpg'
                          alt='Image placeholder'
                          className='card-img-top'
                        />
                      ) : (
                        this.state.documents.map((key, value) => {
                          // console.log(key)
                          return (
                            <div>
                              <img
                                src={key}
                                alt='Image placeholder'
                                className='card-img'
                              />
                              <button
                                className="btn btn-icon btn-primary btn-sm"
                                onClick={(e)=>this.deleteCertificate(value)}
                              >Remove this image</button>
                            </div>
                            
                          );
                        })
                      )}

                      {/* <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'> */}
                      {/* <a href='#' className='btn btn-default float-right'>Upload</a> */}
                      <Input
                        type='file'
                        // id="File-2"
                        className='btn btn-default float-right'
                        onChange={this.handleUploadDocument}
                      >
                        Upload
                        
                      </Input>
                      {/* </div> */}
                      <span style={{ color: 'red' }}>
                        {this.state.formErrdocuments}
                      </span>
                    </div>
                  </div>
                  {/* <button type='button' class='btn btn-warning btn-sm'>Add More Pictures</button> */}
                </div>
              </div>
            </div>

            <div className='col-xl-8 order-xl-1'>
              <div className='card'>
                <div className='card-body'>
                  <form>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-username'
                            >
                              Professional Name
                            </label>
                            <input
                              type='text'
                              id='input-username'
                              className='form-control'
                              placeholder='Professional Name'
                              value={this.state.name}
                              onChange={(e) =>
                                this.onChangeAddName('name', e.target.value)
                              }
                              required
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrname}
                            </span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-email'
                            >
                              Purpose
                            </label>
                            <input
                              type='email'
                              id='input-email'
                              className='form-control'
                              placeholder='Purpose'
                              value={this.state.professionalPurpose}
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'professionalPurpose',
                                  e.target.value
                                )
                              }
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrprofessionalPurpose}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-username'
                            >
                              City 2
                            </label>
                            <input
                              type='text'
                              id='input-username'
                              className='form-control'
                              placeholder='City 2'
                              value={this.state.city2}
                              onChange={(e) =>
                                this.onChangeAddName('city2', e.target.value)
                              }
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrcity2}
                            </span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-username'
                            >
                              Minimum Cost
                            </label>
                            <input
                              type='text'
                              id='input-username'
                              className='form-control'
                              placeholder='Minimum Cost'
                              value={this.state.min_cost}
                              onChange={(e) =>
                                this.onChangeAddName('min_cost', e.target.value)
                              }
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrmin_cost}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-first-name'
                            >
                              Professional Email
                            </label>
                            <input
                              type='text'
                              id='input-first-name'
                              className='form-control'
                              placeholder='Professional Email'
                              value={this.state.email}
                              onChange={(e) =>
                                this.onChangeAddName('email', e.target.value)
                              }
                              required
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErremail}
                            </span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-last-name'
                            >
                              Professional Phone Number
                            </label>
                            <PhoneInput
                              country={'us'}
                              // value={this.state.country_code}
                              // onChange={(e) => this.onChangeAddName('country_code', e.target.value)}
                              onChange={(country_code) =>
                                this.setState({
                                  country_code: '+' + country_code,
                                })
                              }
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrcountry_code}
                            </span>
                            <input
                              type='text'
                              id='input-last-name'
                              className='form-control'
                              placeholder='Phone Number'
                              value={this.state.mobile_no}
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'mobile_no',
                                  e.target.value
                                )
                              }
                              required
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrmobile_no}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-first-name'
                            >
                              Business Name
                            </label>
                            {/* <input type='text' id='input-first-name' className='form-control' placeholder='Business Name' /> */}
                            <Input
                              value={this.state.businessName}
                              type='select'
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'businessName',
                                  e.target.value
                                )
                              }
                            >
                              <option value=''>Businesses Names</option>
                              {this.props.bussinesslisting.map((option) => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </Input>
                            <span style={{ color: 'red' }}>
                              {this.state.formErrbusinessName}
                            </span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label
                              className='form-control-label'
                              for='input-last-name'
                            >
                              Sub Category
                            </label>
                            <Input
                              value={this.state.subCategory}
                              type='select'
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'subCategory',
                                  e.target.value
                                )
                              }
                            >
                              <option value=''>Select Sub Category</option>
                              {this.props.alllisting.map((option) => (
                                <option value={option._id}>
                                  {option.name}
                                </option>
                              ))}
                            </Input>
                            <span style={{ color: 'red' }}>
                              {this.state.formErrsubCategory}
                            </span>
                            {/* <label className='form-control-label' for='input-last-name'>Country</label>
                            <Input
                              value={this.state.country}
                              type="select"
                              onChange={(e) => this.onChangeAddName('country', e.target.value)}
                            >
                              <option value="">Country Names</option>
                              {this.props.countrylisting.map((option) => (
                                <option value={option._id}>{option.country_name}</option>
                              ))}
                            </Input>
                            <span style={{ color: 'red' }}>{this.state.formErrcountry}</span> */}
                            {/* <input type='text' id='input-last-name' className='form-control' placeholder='Country' /> */}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            {/* <label className='form-control-label' for='input-first-name'>City</label> */}
                            {/* <input type='text' id='input-first-name' className='form-control' placeholder='First name' value='Lucky' /> */}
                            {/* <Input
                              value={this.state.city}
                              type="select"
                              onChange={(e) => this.onChangeAddName('city', e.target.value)}
                            >
                              <option value="">City Names</option>
                              {this.props.citylisting.map((option) => (
                                <option value={option._id}>{option.city_name}</option>
                              ))}
                            </Input>
                            <span style={{ color: 'red' }}>{this.state.formErrcity}</span> */}
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            {/* <label className='form-control-label' for='input-last-name'>Sub Category</label>
                            <Input value={this.state.subCategory} type="select" onChange={(e) => this.onChangeAddName('subCategory', e.target.value)}>
                              <option value="">Select Sub Category</option>
                              {this.props.alllisting.map((option) => (
                                <option value={option._id}>{option.name}</option>
                              ))}
                            </Input>
                            <span style={{ color: 'red' }}>{this.state.formErrsubCategory}</span> */}
                            {/* <input type='text' id='input-first-name' className='form-control' placeholder='Sub Category' /> */}
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>
                              Professional Description
                            </label>
                            <textarea
                              rows='4'
                              className='form-control'
                              placeholder='A few words about you ...'
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'description',
                                  e.target.value
                                )
                              }
                              required
                              value={this.state.description}
                            >
                              {this.state.description}
                            </textarea>
                            <span style={{ color: 'red' }}>
                              {this.state.formErrdescription}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>
                              Instructions for customers
                            </label>
                            <textarea
                              rows='4'
                              className='form-control'
                              placeholder='Write Instructions here....'
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'customer_instructions',
                                  e.target.value
                                )
                              }
                              value={this.state.customer_instructions}
                              required
                            >
                              {this.state.customer_instructions}
                            </textarea>
                            <span style={{ color: 'red' }}>
                              {this.state.formErrcustomer_instructions}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label'>Radius</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Radius'
                              onChange={(e) =>
                                this.onChangeAddName('radius', e.target.value)
                              }
                              value={this.state.radius}
                              required
                            />
                            {/* {this.state.customer_instructions}</textarea> */}
                            <span style={{ color: 'red' }}>
                              {this.state.formErrradius}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <div className='col-lg-12'>
                          <label className='form-control-label'>
                            Choose the day you do not want to work
                          </label>
                          <div className='calendarCont'>
                            <DayPicker
                              disabledDays={{ before: new Date() }}
                              onDayClick={this.handleDayClick}
                              selectedDays={this.state.workingDay}
                            />
                          </div>
                          <span style={{ color: 'red' }}>
                            {this.state.formErrworkingDay}
                          </span>
                        </div>
                      </div>

                      {this.state.formFields.map((item, idx) => {
                        return (
                          <div className='row'>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-slot'
                                >
                                  How Many number Slot/day/month $ time slot in
                                  min or hours
                                </label>
                                <input
                                  type='number'
                                  id='input-slot'
                                  className='form-control'
                                  placeholder='No. of slots'
                                  value={item.slot}
                                  min="1"
                                  max="20"
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'slot',
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                                <span style={{ color: 'red' }}>
                                  {this.state.formErrslot}
                                </span>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-slot'
                                >
                                  City
                                </label>
                                <input
                                  type='text'
                                  id='input-slot'
                                  className='form-control'
                                  placeholder='City'
                                  value={item.city}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'city',
                                      e.target.value
                                    )
                                  }
                                  required
                                />
                                <span style={{ color: 'red' }}>
                                  {this.state.formErrslot}
                                </span>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-first-name'
                                >
                                  Slots in Min or Hours
                                </label>
                                <Input
                                  // value={this.state.businessName}
                                  type='select'
                                  value={item.time}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'time',
                                      e.target.value
                                    )
                                  }
                                >
                                  {data.minutes_second.map((option) => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-first-name'
                                >
                                  &nbsp;
                                </label>
                                <Input
                                  // value={this.state.businessName}
                                  type='select'
                                  value={item.hours_min}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'hours_min',
                                      e.target.value
                                    )
                                  }
                                >
                                  {data.slots.map((option) => (
                                    <option value={option.option}>
                                      {option.option}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                            <div className='col-lg-4'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-first-name'
                                ></label>
                                <Input
                                  // value={this.state.businessName}
                                  type='select'
                                  value={item.slot_open_hour}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'slot_open_hour',
                                      e.target.value
                                    )
                                  }
                                >
                                  {data.hours.map((option) => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                            <div className='col-lg-4'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-first-name'
                                >
                                  &nbsp;
                                </label>
                                <Input
                                  // value={this.state.businessName}
                                  type='select'
                                  value={item.slot_open_min}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'slot_open_min',
                                      e.target.value
                                    )
                                  }
                                >
                                  {data.minutes_second.map((option) => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                            <div className='col-lg-4'>
                              <div className='form-group'>
                                <label
                                  className='form-control-label'
                                  for='input-first-name'
                                >
                                  &nbsp;
                                </label>
                                <Input
                                  // value={this.state.businessName}
                                  type='select'
                                  value={item.slot_hours_min}
                                  onChange={(e) =>
                                    this.onChangeSlot(
                                      idx,
                                      'slot_hours_min',
                                      e.target.value
                                    )
                                  }
                                >
                                  {data.format.map((option) => (
                                    <option value={option}>{option}</option>
                                  ))}
                                </Input>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        type='button'
                        className='btn btn-warning btn-sm'
                        onClick={this.handleAddSlotClick}
                      >
                        Add More Slots
                      </button>
                      {this.state.formFields.length > 1 ? (
                        <button
                          type='button'
                          className='btn btn-warning btn-sm'
                          onClick={this.clickOnDelete}
                        >
                          {/* <i className="fa fa-minus" aria-hidden="true" /> */}
                          Remove last slot
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    {this.state.latitude === 0 && this.state.longitude === 0 ?
                      <div
                      style={{
                        height: '500px',
                        width: '500px',
                      }}
                    ></div>:
                    <div
                      style={{
                        height: '500px',
                        width: '500px',
                      }}
                    >
                      <Map
                        center={{
                          lat: this.state.latitude,
                          lng: this.state.longitude,
                        }}
                        handlelatlong={(e) => this.handlelatlong(e)}
                        handleAddress={(e) => this.handleAddress(e)}
                        handlepincode={(e) => this.handlepincode(e)}
                        handlecityname={(e) => this.handlecityname(e)}
                        height='400px'
                        zoom={15}
                      />
                    </div>
                    }
                    <button
                      className='btn btn-success float-right'
                      type='button'
                      onClick={(e) => this.handleSubmit()}
                    >
                      {this.props.match.params.id ? 'Update' : 'Save'}
                    </button>
                    <button
                      className='btn btn-default float-right mr-4'
                      type='button'
                    >
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                  &copy; 2021 ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    success: state.professional.success,
    error: state.professional.error,
    alllisting: state.subCategory.alllisting,
    bussinesslisting: state.bussiness.bussinesslisting,
    countrylisting: state.country.countrylisting,
    citylisting: state.city.citylisting,
    data_professional: state.professional.data_professional,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProfessionalAction: (data, history) =>
      dispatch(actions.addProfessional(data, history)),
    fetchallsubCategory: () =>
      dispatch(actions.fetchallsubCategoryListAction()),
    fetchbussiness: () => dispatch(actions.fetchbussinessListAction()),
    fetchcountry: () => dispatch(actions.fetchcountrysListAction()),
    fetchcity: (data) => dispatch(actions.fetchcitysListAction(data)),
    fetchprofessional: (data) =>
      dispatch(actions.fetchprofessionalAction(data)),
    editProfessionalAction: (data, history, professional_id) =>
      dispatch(actions.editProfessional(data, history, professional_id)),
      editProfessionalImageAction: (data, professional_id) =>
      dispatch(actions.editProfessionalImage(data, professional_id)),
    removeCerificatesAction: (data, professional_id) =>
      dispatch(actions.removeCerificates(data, professional_id)),
    addCerificatesAction: (data, professional_id) =>
      dispatch(actions.addCerificates(data, professional_id)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(withRouter(EditProfessionals)));
