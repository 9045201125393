import axios from "../../axios-config";
import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const startDashboardAction = () => {
  return {
    type: actionTypes.START_DASHBOARD_ACTION
  };
};

export const fetchdashboardListSUCCESS = (categoryList,message = '') => {
  return {
    type: actionTypes.FETCH_DASHBOARD_LIST_SUCCESS,
    listing: categoryList,
    message: message ? message : ''
  };
};

export const dashboardActionFail = message => {
  return {
    type: actionTypes.DASHBOARD_ACTION_FAIL,
    message
  };
};
export const authSuccess = (token, userInfo) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userInfo: userInfo,
    error: "Login Successfully"
  };
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const logoutAction = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
export const showAlertNotification = (type, message) => {
  return {
    type: actionTypes.SHOW_ALERT_NOTIFICATION,
    alertType: type,
    alertMessage: message
  };
};
export const auth = (data) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      mobile_no: data.mobile_no,
      password: data.password,
      country_code: data.country_code,
      user_type: data.user_type,
    };

    axios
      .post(`/admin/login`, authData)
      .then(response => {
        if (response.status === 200) {
          let apiResponse = response.data;
          if (apiResponse.status === 0) {
            dispatch(authFail(apiResponse.message));
          } else {
            // console.log(response.data._id);
            
            localStorage.setItem("token", response.headers.authorization);
            localStorage.setItem("userId",response.data.data._id);
            localStorage.setItem("userInfo", JSON.stringify(response.data.data));
            // console.log(localStorage.getItem("token"))
            dispatch(
              authSuccess(response.headers.authorization, response.data.data)
            );
          }
        }
      })
      .catch(err => {
        console.log(err);
        //dispatch(authFail(err.response.data.error))
      });
  };
};
export const logout = () => {
  return dispatch => {
    let authCode = "Bearer " + localStorage.getItem("token");
      
        // if (response.status === 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userId");
          dispatch(logoutAction());
        // }
    
  };
};

export const fetchDashboard = (message = '') => {
  return dispatch => {
    dispatch(startDashboardAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/admin/dashboard", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchdashboardListSUCCESS(response.data,message));
          } else {
            dispatch(dashboardActionFail(response.data.message));
          }
        } else {
          dispatch(dashboardActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(dashboardActionFail(err.message));
      });
  };
};
