import React from 'react'
import {
  BrowserRouter as Router,
  Link,
  NavLink
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
class Advertisement extends React.Component {
  state ={
    businessId : "",
    open: false
  }
  componentWillMount() {
    this.props.fetchbussiness();
  }
  componentDidMount (){
    this.props.fetchbussiness();
  }
  deleteUserPermanently() {
		if(this.state.businessId) {
			this.props.businessDelete(this.state.businessId);
      this.close()
		}
  }
  
  open() {
    this.setState({ open: true });
  }

  close() {
      this.setState({ open: false });
  }
  
  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props);
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
  }
  render(){
    
    var sno = 1

    let businessList = [];
    let businessTable = <Spinner />;
    
    {this.props.bussinesslisting.length > 0?
      this.props.bussinesslisting.map(datas=>{
        // console.log(datas,'==================')
        businessList.push(
          {
        
            s_no:(sno++),
            bName:datas.name,
            website:datas.website,
            // city:datas.city,
            // CEmail:datas.email,
          // datas.country,
        //   cName:datas.country_code+" "+datas.mobile_no,
          action:<div>
{/* <Route path='/advertisement-picture'> <AdverismentPicture /></Route> */}
          <NavLink className='btn btn-icon btn-default btn-sm' to={'/advertisement-picture/'+datas._id} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /> Upload Ad Pictures</span>
          </NavLink>
          <NavLink className='btn btn-icon btn-default btn-sm' to={'/advertisement-video/'+datas._id} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /> Upload Ad Videos</span>
          </NavLink>
          <NavLink className='btn btn-icon btn-default btn-sm' to={'/blog-price-ad-picture/'+datas._id} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /> Upload Ad Blog&Price Picture</span>
          </NavLink>
          <NavLink className='btn btn-icon btn-default btn-sm' to={'/blog-price-ad-video/'+datas._id} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /> Upload Ad Blog&Price Video</span>
          </NavLink>
          </div>
        
          }
        )
      })
      
      :businessList.push(
        ["No record"]
        )}
        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Business Name",
              field: "bName",
              sort: "asc",
            },
            {
              label: "Website",
              field: "website",
              sort: "asc",
            },
            // {
            //   label: "City",
            //   field: "city",
            //   sort: "asc",
            // },
            // {
            //   label: "Contact Email",
            //   field: "CEmail",
            //   sort: "asc",
            // },
            
            {
              label: "Action",
              field: "action",
              sort: "asc",
            },
          ],
          rows: businessList,
        };
    
        businessTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Business</h6>
                </div>
              </div>
                  <Dialog
                      open={this.state.open}
                      onClose={() => this.close()}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                  <DialogTitle id="alert-dialog-title">Do you want to delete Business Owner?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.close()}>
                              No
                    </button>
                    <button class='btn btn-icon btn-success btn-sm' onClick={()=>this.deleteUserPermanently()} type='button'>
                              Yes
                    </button>
                  </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6'>
                    </div> 
                  </div>
                  <div className='table-responsive'>
                    {businessTable}
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )  
  }
  
}
const mapPropsToState = (state) => {
  return {
    success: state.bussiness.success,
    error: state.bussiness.error,
    bussinesslisting: state.bussiness.bussinesslisting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchbussiness: () => dispatch(actions.fetchbussinessListAction()),
    businessDelete: (data) => dispatch(actions.businessDeleteAction(data)),
    businessActiveInactiveAction: (data) => dispatch(actions.businessActiveInactive(data)),

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Advertisement));
