import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'

export default function Reports () {
  return (
    <div>
      <div className='header bg-primary pb-6'>
        <div className='container-fluid'>
          <div className='header-body'>
            <div className='row align-items-center py-4'>
              <div className='col-lg-6 col-7'>
                <h6 className='h2 text-white d-inline-block mb-0'>Reports</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid mt--6'>
        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-header border-0'>
                <div className='row align-items-center py-4'>
                  <div className='col-lg-3'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Order Status</option>
                      <option>Rejected by Service Person</option>
                      <option>Rejected by Admin</option>
                      <option>Rejected by Owner</option>
                      <option>Cancelled by Admin</option>
                    </select>
                  </div>
                  <div className='col-lg-3'>
                    <div className='row'>
                      <div className='col form-inline'>
                        <div className='form-group'>
                          <label className='form-control-label mr-2' for='from'>From</label>
                          <input type='date' id='from' className='form-control' />
                        </div>
                      </div>
                      <div className='col form-inline'>
                        <div className='form-group'>
                          <label className='form-control-label mr-2' for='to'>To Date</label>
                          <input type='date' id='to' className='form-control' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-5'>
                    <div class='input-group'>
                      <input type='text' class='form-control' placeholder='Search' aria-describedby='button-addon2' />
                      <div class='input-group-append'>
                        <button class='btn btn-outline-primary' type='button' id='button-addon2'>Search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table align-items-center table-flush'>
                  <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Sr.No</th>
                      <th scope='col'>Order Id</th>
                      <th scope='col'>Professional <br /> Name</th>
                      <th scope='col'>Business Name</th>
                      <th scope='col'>Total <br />Service Price</th>
                      <th scope='col'>Total <br />Product Price</th>
                      <th scope='col'>Total Tax</th>
                      <th scope='col'>Total Tip</th>
                      <th scope='col'>Total Product <br />Price + Tax</th>
                      <th scope='col'>Total Product <br />Price + Total Tax + <br />Total Service Price</th>
                      <th scope='col'>Total <br />Discount</th>
                      <th scope='col'>Total Happy <br />Hours</th>
                      <th scope='col'>Total Product <br /> + Tax + Total <br />Service + Tip</th>
                      <th scope='col'>Total <br />Servicehubconnect <br />Fee (%)</th>
                      <th scope='col'>Total Business Owner <br />Amount</th>
                      <th scope='col'>Total Service <br />Fee (%)</th>
                      <th scope='col'>Total Processing <br />Fee (%)</th>
                      <th scope='col'>Total Cancellation <br />charge</th>
                    </tr>
                  </thead>
                  <tbody className='list'>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>#15545</td>
                      <td>Mikayla Ervin</td>
                      <td>Mikayla Ervin</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>2%</td>
                      <td>25</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>00</td>
                      <td>0.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                      <td>$20.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='card-footer py-4'>
                <nav aria-label='...'>
                  <ul className='pagination justify-content-end mb-0'>
                    <li className='page-item disabled'>
                      <a className='page-link' href='#' tabindex='-1'>
                        <i className='fas fa-angle-left' />
                        <span className='sr-only'>Previous</span>
                      </a>
                    </li>
                    <li className='page-item active'>
                      <a className='page-link' href='#'>1</a>
                    </li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>2 <span className='sr-only'>(current)</span></a>
                    </li>
                    <li className='page-item'><a className='page-link' href='#'>3</a></li>
                    <li className='page-item'>
                      <a className='page-link' href='#'>
                        <i className='fas fa-angle-right' />
                        <span className='sr-only'>Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <footer className='footer pt-0'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6'>
              <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
              &copy; 2021  ServiceHubConnect 
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
