import React from 'react'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import {
  Input,
} from 'reactstrap'
class Customer extends React.Component {
  constructor(props){
    super(props)
    this.state={
      is_active : "All",
    }}
  componentWillMount() {
    this.props.fetchcustomerList();
  }
  changeActiveStatus (id) {
    if(id){
      this.props.customerActiveInactiveStatus(id);
    }
  }
  onChangeSlot = async (value) => {
    this.setState({ is_active: value })
    

  }
  render(){
    let businessList = [];
    let businessTable = <Spinner />;
    var sno = 1
    {this.props.customerlisting.length > 0?
      this.props.customerlisting.map(datas=>{
        if(this.state.is_active === "All"){
          businessList.push(
            {
          
              s_no:(sno++),
              pName:datas.name,
              cInfo:datas.country_code+datas.mobile_no,
              local_city:(datas.local_city)?datas.local_city:'',
              cemail:datas.email,
              // datas.country,
              // city:(datas.local_city == null)?null:datas.local_city,
              action:<div>
              {datas.is_active == true?<button class='btn btn-icon btn-success btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-check-bold' /></span>
              </button>:
              <button class='btn btn-icon btn-danger btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-fat-remove' /></span>
              </button>}
            </div>
          
          }
          )
        }
        else if(this.state.is_active === "Active" && datas.is_active == true){
          businessList.push(
            {
          
              s_no:(sno++),
              pName:datas.name,
              cInfo:datas.country_code+datas.mobile_no,
              local_city:datas.refferal_code,
              cemail:datas.email,
              // datas.country,
              // city:(datas.local_city == null)?null:datas.local_city,
              action:<div>
              {datas.is_active == true?<button class='btn btn-icon btn-success btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-check-bold' /></span>
              </button>:
              <button class='btn btn-icon btn-danger btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-fat-remove' /></span>
              </button>}
            </div>
          
          }
          )
        }
        else if(this.state.is_active === "InActive" && datas.is_active == false){
          businessList.push(
            {
          
              s_no:(sno++),
              pName:datas.name,
              cInfo:datas.country_code+datas.mobile_no,
              local_city:datas.refferal_code,
              cemail:datas.email,
              // datas.country,
              // city:(datas.local_city == null)?null:datas.local_city,
              action:<div>
              {datas.is_active == true?<button class='btn btn-icon btn-success btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-check-bold' /></span>
              </button>:
              <button class='btn btn-icon btn-danger btn-sm' onClick={(id) =>this.changeActiveStatus(datas._id)}>
                <span class='btn-inner--icon'><i class='ni ni-fat-remove' /></span>
              </button>}
            </div>
          
          }
          )
        }
      })
      
      :businessList.push(
        ["No record"]
        )}
        const columns = ["Sr.No","Professional Name", "Contact Info","Business Name","Professional Email", "City", "Action"];

        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Name",
              field: "pName",
              sort: "asc",
            },
            {
              label: "Contact Phone Info",
              field: "cInfo",
              sort: "asc",
            },
            {
              label: "City",
              field: "local_city",
              sort: "asc",
            },
            {
              label: "Contact Email",
              field: "cemail",
              sort: "asc",
            },
            // {
            //   label: "Profile Pic",
            //   field: "city",
            //   sort: "asc",
            // },
            
            {
              label: "Action",
              field: "action",
              sort: "asc",
            },
          ],
          rows: businessList,
        };
    
        businessTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );

    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Customer</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-3'>
                      {/* <select className='form-control' id='exampleFormControlSelect1'>
                        <option>Active</option>
                        <option>Deactive</option>
                        <option>All</option>
                      </select> */}
                      <Input
                                value={this.state.is_active}
                          type="select"
                          onChange={(e) => this.onChangeSlot(e.target.value)}
                        >
                          {/* {data.minutes_second.map((option) => (
                            <option value={option}>{option}</option>
                          ))} */}
                          <option value = "Active">Active</option>
                          <option value = "InActive">Inactive</option>
                          <option value = "All">All</option>
                        </Input>
                    </div>
                    
                  </div>
                </div>
                <div className='table-responsive'>
                  <table className='table align-items-center table-flush'>
                    {businessTable}
                  </table>
                </div>
                
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
  
}
const mapPropsToState = (state) => {
  return {
    success: state.customer.success,
    error: state.customer.error,
    customerlisting: state.customer.customerlisting
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchcustomerList: () => dispatch(actions.fetchcustomerListAction()),
    customerActiveInactiveStatus: (data) => dispatch(actions.customerActiveInactive(data))
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Customer));
