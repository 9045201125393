import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  bussinesslisting: [],
  subscriptionList:[],
  businessDetails:{},
  couponRankingReportlisting: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_COUPON_BUSSINESS_ACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.COUPON_BUSSINESS_ACTION_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
    case actionTypes.GET_COUPONRANKING_BUSINESS_USER:
      return {
        ...state,
        loading: null,
        bussinesslisting: action.listing,
        error: null,
        success: action.message,

      }
    case actionTypes.FETCH_BUSSINESS_DATA_SUCCESS:
      return {
        ...state,
        loading: null,
        data_bussiness: action.listing,
        error: null,
        success: action.message,

      }
      case actionTypes.GET_SUBSCRIPTION_PLAN_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        subscriptionList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_COUPON_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        businessDetails: action.details,
        error: null,
        success: action.message,
      }
      case actionTypes.EDIT_COUPON_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        businessDetails: action.details,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_COUPONRANKING_REPORT_LISTING:
      return {
        ...state,
        loading: null,
        couponRankingReportlisting: action.listing,
        error: null,
        success: action.message,
      }
      
    default:
      return state;
  }
};

export default reducer;
