import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  blogList: [],
  blogDetails:{},
  progress:0,
  // pictureProgress:0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_BLOG:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.GET_BLOG_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.GET_BLOG_SUCCESS :
      return {
          ...state,
          loading:null,
          blogList: action.blogList,
          error:null,
          success:action.message,
          
      }
      case actionTypes.GET_BLOG_DETAILS_SUCCESS :
      return {
          ...state,
          loading:null,
          blogDetails: action.blogDetails,
          error:null,
          success:action.message,
      }
        case actionTypes.PROGRESS_BAR :
        return {
            ...state,
            loading:null,
            error:null,
            progress:action.progress,
            
        }
    default:
      return state;
  }
};

export default reducer;
