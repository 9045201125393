import React from 'react'
import {
  BrowserRouter as Router,
  NavLink
} from 'react-router-dom'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import MUIDataTable from 'mui-datatables'
import { 
  Button , 
  Modal ,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup,
	Label,
	Input,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class Business extends React.Component {
  constructor(props){
    super(props)
    this.state={
      categoryName : '',
      open:false,
      formErr:'',
      categoryId:'',
      openDialgue:false,
      formErrImage:'',
      file:'',
      category_images:'',
      location_required:'',
      formErrlocation_required:'',
      rideShare_delivery:'',
      formErrrideShare_delivery:'',
      short_name:'',
      formErrshort_name:''
    }
		this.handleUploadFile = this.handleUploadFile.bind(this)
  }
  componentWillMount () {
    this.props.fetchCategory()
    console.log('this.props.listing')

    // console.log(this.props.categorylisting)
  }
  onChangeAddName= async (key,value)=>{
    var self=this;
    await self.setState({
      [key]: value
    })
    if(value){
      await this.setState({
        formErr:""
      })
    }
    console.log(self.state.categoryName)
  }
  handleSubmit = async()=>{
    if(this.state.category_images == ''){
      await this.setState({
        formErrImage:"Please provide a Image"
      })
    }
    else if(this.state.categoryName == ''){
      await this.setState({
        formErr:"Please provide a valid category"
      })
    }
    else if(this.state.location_required == ''){
      await this.setState({
        formErrlocation_required:"Please provide a valid category"
      })
    }
    else if(this.state.rideShare_delivery == ''){
      await this.setState({
        formErrrideShare_delivery:"Please provide a valid category"
      })
    }
    else if(this.state.short_name == ''){
      await this.setState({
        formErrshort_name:"Please provide a valid category"
      })
    }
    else if(this.state.categoryName !== '' && this.state.location_required !== '' && this.state.rideShare_delivery !== '' && this.state.short_name !== '' ){
      await this.setState({
        formErr:"",
        formErrlocation_required:""
      })
      
      if(this.state.categoryId){
        if(this.state.category_images && this.state.categoryName){
          this.props.updateCategory({name:this.state.categoryName,categoryId:this.state.categoryId,location_required:this.state.location_required,
            rideShare_delivery:this.state.rideShare_delivery, short_name:this.state.short_name})
        }
      }
      else{
        // e.preventDefault();
        const data = new FormData();
        
        if(this.state.category_images && this.state.categoryName){
          data.append('name', this.state.categoryName);
			    data.append('category_images', this.state.category_images);
			    data.append('location_required', this.state.location_required);
			    data.append('rideShare_delivery', this.state.rideShare_delivery);
          data.append('short_name', this.state.short_name);
          

          this.props.addCategory(data)
        }
        
      }
      
      this.close()
    }
  }
  handleOpen = (id,name,category_images,location_required,rideShare_delivery,short_name) => {
    if(id !== ""){
      this.setState({
        categoryId:id,
        categoryName:name,
        category_images:category_images,
        file:category_images,
        location_required:location_required,
        rideShare_delivery:rideShare_delivery,
        short_name:short_name
      })
    }else{
      this.setState({
        categoryId:"",
        categoryName:"",
        category_images:"",
        file:'',
        location_required:'',
        rideShare_delivery:'',
        short_name:''
      })
    }
    this.setState({
      open:true 
    });
  
  }
  async close() {
    await this.setState({ 
      open: false,
      categoryName:'',
      formErr:'',
      formErrImage:'',
      category_images:"",
      file:'',
      location_required:'',
      rideShare_delivery:'',
      short_name:''
    });
    // console.log(this.state.open) 
  }
  
  handleOpenDelete = (id) => {
    
		if(id) {

      this.setState({ 
        categoryId : id,
        openDialgue:true 
      });
		}
	
	}
  open() {
    this.setState({ openDialgue: true });
  }
  closeDialgue() {
    this.setState({ openDialgue: false });
  }
  deleteUserPermanently() {
		if(this.state.categoryId) {
			this.props.categoryDelete(this.state.categoryId);
      this.closeDialgue()
      this.setState({ categoryId: '' });
		}
  }
  handleUploadFile = async(event) => {
	
	
		await this.setState({file: URL.createObjectURL(event.target.files[0]),category_images:event.target.files[0],errimage:''})

    if(this.state.categoryId) {
      const data = new FormData();
        
      if(this.state.category_images){
        data.append('category_images', this.state.category_images);
        // name:this.state.categoryName,categoryId:this.state.categoryId
        this.props.updateCategoryImage(data,this.state.categoryId)
        
      }
    }
   	
  }
  render () {
    // console.log(this.state.rideShare_delivery,'===========rideshare')
    const { open } = this.state;
    var sno = 1
    let categoryList = [];
    let businessCategoryTable = <Spinner />;
    {this.props.categorylisting.length > 0
      ? this.props.categorylisting.map(datas => {
        // console.log(datas,'======')
        categoryList.push(
          {

            s_no:(sno++),
            cName:datas.name,
            cShortName:datas.short_name,
            cimage:
            <img src={datas.category_images} className="rounded-circle mb-15" width="100" height="100"></img>,
            location_required:datas.location_required,
            rideShare_delivery:datas.rideShare_delivery,
            action:<div>
              <NavLink className='btn btn-icon btn-default btn-sm' to={'/businesssubcategory/'+datas._id+'/'+datas.name} >
                <span className='btn-inner--icon'><i className='fas fa-plus' /></span>
              </NavLink>
              <Button className='btn btn-icon btn-info btn-sm' onClick={(data_id,name) =>this.handleOpen(datas._id,datas.name,datas.category_images,datas.location_required)}>
                <span class='btn-inner--icon'><i class='fas fa-pencil-alt' /></span>
              </Button>
              {/* <button class='btn btn-icon btn-danger btn-sm' type='button'>
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </button> */}
              <Button className='btn btn-icon btn-danger btn-sm' 
              onClick={(id) =>this.handleOpenDelete(datas._id)}
              >
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </Button>
            </div>

            }
        )
      })

      : categoryList.push(
        ['No record']
      )}
      const data = {
        columns: [
          {
            label: "Sr.No",
            field: "s_no",
            sort: "asc",
          },
          {
            label: "Category_image",
            field: "cimage",
            sort: "asc",
          },
          {
            label: "Category Name",
            field: "cName",
            sort: "asc",
          },
          {
            label: "Category Short Name",
            field: "cShortName",
            sort: "asc",
          },
          {
            label: "location",
            field: "location_required",
            sort: "asc",
          },
          {
            label: "rideShare delivery type",
            field: "rideShare_delivery",
            sort: "asc",
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
          },
        ],
        rows: categoryList,
      };
  
      businessCategoryTable = (
        <MDBDataTable striped bordered hover entries={10} data={data} />
      );
    const columns = ['Sr.No', 'Category Name', 'Action']
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Manage Business Categories</h6>
                </div>
              </div>
                <Dialog
                  open={this.state.openDialgue}
                  onClose={() => this.closeDialgue()}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">Do you want to delete Business Category?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.closeDialgue()}>
                            No
                  </button>
                  <button class='btn btn-icon btn-success btn-sm' onClick={()=>this.deleteUserPermanently()} type='button'>
                            Yes
                  </button>
                </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <Button className='btn btn-primary float-right' onClick={(data_id,name) =>this.handleOpen("","","")}>Add Category</Button>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {businessCategoryTable}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <Modal
              isOpen={open}
              toggle={() => this.close()}
          >
              <ModalHeader toggle={() => this.close()}>
                <Label for="title">Add Category</Label>      
              </ModalHeader>
              <ModalBody>
                  <FormGroup>
                    <img src={this.state.category_images == ''?'../assets/img/noimage.png':this.state.file}  alt="user prof" className="rounded-circle mb-15" width="100" height="100" />
                    <br/>
                    <Label for="File-2">Upload Image</Label>
                    <Input type="file" name="category_images" id="File-2" 
                    onChange={this.handleUploadFile}
                     />
                    <span style={{color:'red'}}>{this.state.formErrImage}</span>
                </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="Category Name" value={this.state.categoryName} onChange={(e)=>this.onChangeAddName('categoryName',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErr}</span>
                  </FormGroup>
                  <FormGroup>
                      {/* <Input type="text" name="link" id="link" placeholder="Category Name" value={this.state.categoryName} onChange={(e)=>this.onChangeAddName('categoryName',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErr}</span> */}
                      <Input
                                value={this.state.location_required}
                        type="select"
                        onChange={(e) => this.onChangeAddName("location_required", e.target.value)}
                      >
                          <option value="none">none</option>
                          <option value="single">single</option>
                          <option value="two">two</option>
                          <option value="multiple">multiple</option>
                       
                      </Input>
                      <span style={{color:'red'}}>{this.state.formErrlocation_required}</span>
                  </FormGroup>
                  <FormGroup>
                      {/* <Input type="text" name="link" id="link" placeholder="Category Name" value={this.state.categoryName} onChange={(e)=>this.onChangeAddName('categoryName',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErr}</span> */}
                      <Input
                      
                                value={this.state.rideShare_delivery}
                        type="select"
                        onChange={(e) => this.onChangeAddName("rideShare_delivery", e.target.value)}
                      >
                          <option value="none">none</option>
                          <option value="ride_share">ride_share</option>
                          <option value="delivery">delivery</option>
                      </Input>
                      <span style={{color:'red'}}>{this.state.formErrrideShare_delivery}</span>
                  </FormGroup>
                  <FormGroup>
                      <Input type="text" name="link" id="link" placeholder="Short Name" value={this.state.short_name} onChange={(e)=>this.onChangeAddName('short_name',e.target.value)} />
                      <span style={{color:'red'}}>{this.state.formErr}</span>
                  </FormGroup>
              </ModalBody>
              <ModalFooter>
                
                  <Button variant="raised" className="btn-success text-white mr-10"  onClick={(e)=>this.handleSubmit()}>{this.state.categoryId !== ''?"Update":"Save"}</Button>
                      <Button variant="raised" onClick={()=>this.close()} className="btn-danger text-white mr-10">Cancel</Button>
              </ModalFooter>
            </Modal>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    categorylisting: state.category.categorylisting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCategory: () => dispatch(actions.fetchcategoryListAction()),
    addCategory: (data) => dispatch(actions.addCategoryAction(data)),
    updateCategory: (data) => dispatch(actions.updateCategoryAction(data)),
    categoryDelete: (data) =>dispatch(actions.categoryDeleteAction(data)),
    updateCategoryImage: (data,categoryId) => dispatch(actions.updateCategoryImageAction(data,categoryId)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Business))
