import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  blogPriceAdvertisePicture: [],
  blogPriceAdvertiseVideo:[],
  progress:0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    
      case actionTypes.START_BLOGPRICE_ADVERTISEMENT_MEDIA:
        return {
            ...state,
            loading:true,
            error:null
        };
      case actionTypes.BLOGPRICE_ADVERTISEMENT_MEDIA_FAIL :
          return {
              ...state,
              loading:null,
              error:action.message,
              success:null,
          }
      case actionTypes.GET_BLOGPRICE_ADVERTISEMENT_PICTURE_SUCCESS :
        return {
            ...state,
            loading:null,
            blogPriceAdvertisePicture: action.blogPriceAdvertisePicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.BLOGPRICE_ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            blogPriceAdvertisePicture: action.blogPriceAdvertisePicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.BLOGPRICE_ADVERTISEMENT_PICTURE_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            blogPriceAdvertisePicture: action.blogPriceAdvertisePicture,
            error:null,
            success:action.message,
            
        }

        case actionTypes.GET_BLOGPRICE_ADVERTISEMENT_VIDEO_SUCCESS :
          return {
              ...state,
              loading:null,
              blogPriceAdvertiseVideo: action.blogPriceAdvertiseVideo,
              error:null,
              success:action.message,
              
          }
          case actionTypes.BLOGPRICE_ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS :
          return {
              ...state,
              loading:null,
              blogPriceAdvertiseVideo: action.blogPriceAdvertiseVideo,
              error:null,
              success:action.message,
              
          }
          case actionTypes.BLOGPRICE_ADVERTISEMENT_VIDEO_DELETE_SUCCESS :
          return {
              ...state,
              loading:null,
              blogPriceAdvertiseVideo: action.blogPriceAdvertiseVideo,
              error:null,
              success:action.message,
          }
        case actionTypes.PROGRESS_BAR :
          return {
              ...state,
              loading:null,
              error:null,
              progress:action.progress,
          }
    default:
      return state;
  }
};



export default reducer;
