import React, { Component } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";

const styles = {
    uploadedImgWrapper: {
        border: '2px dotted #ccc',
        maxWidth: 500,
        minHeight: 200,
        borderRadius: 5,
        margin: '0 auto',
    }
}

class CreateBlog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blog_title: '',
            formErrblog_title: '',
            blog_description: '',
            blog_picture: '',
            blog_picture_website_url: '',
            blog_video: '',
            blog_video_website_url: '',
            blogfile1: '',
            blogfile2: '',
            //story state
            story_title: '',
            story_content: '',
            story_picture_website_url: '',
            story_video_website_url: '',
            story_picture: '',
            story_video: '',
            storyfile1: '',
            storyfile2: '',
        }
        this.handleUploadFile = this.handleUploadFile.bind(this)
        this.handleUploadFile1 = this.handleUploadFile1.bind(this)
        this.handleUploadFile2 = this.handleUploadFile2.bind(this)
        this.handleUploadFile3 = this.handleUploadFile3.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success, loading } = this.props;
        // loading && enqueueSnackbar(loading, { variant: 'loading' });
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
    }


    onChangeText = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
        //blog set state
        if (key === "blog_title") {
            await self.setState({
                blog_title: value,
                formErrblog_title: ''
            })
        }
        if (key === "blog_description") {
            await self.setState({
                blog_description: value
            })
        }
        if (key === "blog_picture_website_url") {
            await self.setState({
                blog_picture_website_url: value,
            })
        }
        if (key === "blog_video_website_url") {
            await self.setState({
                blog_video_website_url: value,
            })
        }

        //story set state
        if (key === "story_title") {
            await self.setState({
                story_title: value
            })
        }
        if (key === "story_content") {
            await self.setState({
                story_content: value
            })
        }
        if (key === "story_picture_website_url") {
            await self.setState({
                story_picture_website_url: value,
            })
        }
        if (key === "story_video_website_url") {
            await self.setState({
                story_video_website_url: value,
            })
        }

    }

    handleUploadFile = async (event) => {
        await this.setState({
            blogfile1: URL.createObjectURL(event.target.files[0]), blog_picture: event.target.files[0],
        })
    }

    handleUploadFile1 = async (event) => {
        await this.setState({
            blogfile2: URL.createObjectURL(event.target.files[0]), blog_video: event.target.files[0],
        })
    }
    handleUploadFile2 = async (event) => {
        await this.setState({
            storyfile1: URL.createObjectURL(event.target.files[0]), story_picture: event.target.files[0],
        })
    }
    handleUploadFile3 = async (event) => {
        await this.setState({
            storyfile2: URL.createObjectURL(event.target.files[0]), story_video: event.target.files[0],
        })
    }

    handleSubmit = async () => {
        await this.setState({
            formErrblog_title: (this.state.blog_title === '') ? "Please provide this field" : '',
        })
        if (this.state.blog_title !== '') {
            const data = new FormData();
            data.append('blog_title', this.state.blog_title);
            data.append('blog_description', this.state.blog_description);
            data.append('blog_picture', this.state.blog_picture);
            data.append('blog_picture_website_url', this.state.blog_picture_website_url);
            data.append('blog_video', this.state.blog_video);
            data.append('blog_video_website_url', this.state.blog_video_website_url);
            //story details
            data.append('story_title', this.state.story_title);
            data.append('story_content', this.state.story_content);
            data.append('story_picture_website_url', this.state.story_picture_website_url);
            data.append('story_video_website_url', this.state.story_video_website_url);
            data.append('story_picture', this.state.story_picture);
            data.append('story_video', this.state.story_video);
            await this.props.createBlog(data, this.props.history);

        }
    }

    editor = null;
    render() {
        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Create Blog</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='create-blog blogs card-body'>
                                    <div className='clearfix'>
                                        <form style={{ maxWidth: '800px', margin: '0 auto' }}>
                                            <div className='blog-details'>
                                                <h1 className='mb-3'>Blog Details</h1>
                                                <div className='form-group mb-3'>
                                                    <TextField id="outlined-basic" label="Blog Title" variant="outlined" fullWidth
                                                        value={this.state.blog_title}
                                                        onChange={(e) => this.onChangeText('blog_title', e.target.value)}
                                                    />
                                                    <span style={{ color: 'red' }}>{this.state.formErrblog_title}</span>
                                                </div>
                                                <div className='form-group mb-3'>
                                                    <TextField id="outlined-multiline-static" label="Blog Description" multiline rows={4} fullWidth
                                                        value={this.state.blog_description}
                                                        onChange={(e) => this.onChangeText('blog_description', e.target.value)}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                            <img src={this.state.blogfile1 ? this.state.blogfile1 : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                        </div>
                                                        <div className='form-group mb-3'>
                                                            <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                value={this.state.blog_picture_website_url}
                                                                onChange={(e) => this.onChangeText('blog_picture_website_url', e.target.value)}
                                                            />
                                                        </div>
                                                        <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }} onChange={this.handleUploadFile}>Upload Blog Image
                                                            <input hidden accept="image/*" multiple type="file"
                                                                onChange={this.handleUploadFile} />
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                            {/* <video src={this.state.blogfile2 ? this.state.blogfile2 : '../assets/img/theme/img-1-1000x600.jpg'} /> */}
                                                            <iframe width="100%" height="315" src={this.state.blogfile2 ? this.state.blogfile2 : '../assets/img/theme/img-1-1000x600.jpg'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                            </iframe>
                                                        </div>
                                                        <div className='form-group mb-3'>
                                                            <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                value={this.state.blog_video_website_url}
                                                                onChange={(e) => this.onChangeText('blog_video_website_url', e.target.value)}
                                                            />
                                                        </div>
                                                        <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Blog Video
                                                            <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile1} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='story-details mt-4'>
                                                <h1 className='mb-3'>Story Details</h1>
                                                <div className='form-group mb-3'>
                                                    <TextField id="outlined-basic" label="Story Title" variant="outlined" fullWidth
                                                        value={this.state.story_title}
                                                        onChange={(e) => this.onChangeText('story_title', e.target.value)}
                                                    />
                                                </div>
                                                <div className='mb-3'>
                                                    <label className='mb-2'>Story Content</label>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data="<p>Hello from CKEditor 5!</p>"
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                        }}
                                                        config={{
                                                            link: {
                                                                decorators: {
                                                                    openInNewTab: {
                                                                        mode: 'manual',
                                                                        label: 'Open in a new tab',
                                                                        defaultValue: true,			// This option will be selected by default.
                                                                        attributes: {
                                                                            target: '_blank',
                                                                            rel: 'noopener noreferrer'
                                                                        }
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.onChangeText('story_content', data)
                                                        }}
                                                        value={this.state.story_content}
                                                        onBlur={(event, editor) => {
                                                        }}
                                                        onFocus={(event, editor) => {
                                                        }}

                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                            <img src={this.state.storyfile1 ? this.state.storyfile1 : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                        </div>
                                                        <div className='form-group mb-3'>
                                                            <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                value={this.state.story_picture_website_url}
                                                                onChange={(e) => this.onChangeText('story_picture_website_url', e.target.value)}
                                                            />
                                                        </div>
                                                        <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Image
                                                            <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile2} />
                                                        </Button>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                            <video src={this.state.storyfile2 ? this.state.storyfile2 : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                        </div>
                                                        <div className='form-group mb-3'>
                                                            <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                value={this.state.story_video_website_url}
                                                                onChange={(e) => this.onChangeText('story_video_website_url', e.target.value)}
                                                            />
                                                        </div>
                                                        <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Video
                                                            <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile3} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mt-5 text-left'>
                                                <button className='btn btn-success' type='button' onClick={(e) => this.handleSubmit()} >Save</button>
                                                <button className='btn btn-default ml-2' type='button'>Cancel</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {
                                    this.props.loading ?
                                        <div className='loader-wrapper'>
                                            <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                        </div> : ''
                                }
                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}


const mapPropsToState = (state) => {
    return {
        success: state.blog.success,
        error: state.blog.error,
        loading: state.blog.loading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        createBlog: (data, history) => dispatch(actions.createBlog(data, history))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(CreateBlog)))


