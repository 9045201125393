import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import { SnackbarProvider } from "notistack";
import Login from './Login'
import Verification from './Verification'


import Master from './Master'

export default function App () {
  return (
    <Router>
      <SnackbarProvider>
      <div>

      <Switch>

          <Route path='/dashboard'> <Master /></Route>
          <Route path='/verification'> <Verification /></Route>
          <Route path='/'><Login /></Route>
        </Switch>
      </div>
      </SnackbarProvider>
    </Router>
  )
}

function Users () {
  return <h2>Users</h2>
}
