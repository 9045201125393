import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  categoryPicture: [],
  categoryVideo:[],
  progress:0,
  pictureProgress:0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_CATEGORY_MEDIA:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.CATEGORY_MEDIA_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.GET_CATEGORY_MEDIA_SUCCESS :
      return {
          ...state,
          loading:null,
          categoryPicture: action.categoryPicture,
          categoryVideo:action.categoryVideo,
          error:null,
          success:action.message,
          
      }
      case actionTypes.CATEGORY_PICTURE_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            categoryPicture: action.categoryPicture,
            error:null,
            success:action.message,
            
        }

        case actionTypes.CATEGORY_PICTURE_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            categoryPicture: action.categoryPicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.CATEGORY_VIDEO_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            categoryVideo: action.categoryVideo,
            error:null,
            success:action.message,
            
        }
        case actionTypes.CATEGORY_VIDEO_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            categoryVideo: action.categoryVideo,
            error:null,
            success:action.message,
            
        }
        case actionTypes.CATEGORY_PROGRESS_BAR :
        return {
            ...state,
            loading:null,
            error:null,
            progress:action.progress,
            
        }
        case actionTypes.CATEGORY_PICTURE_PROGRESS:
          return {
              ...state,
              loading:null,
              error:null,
              pictureProgress:action.pictureProgress,
              
          }
    default:
      return state;
  }
};



export default reducer;
