import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startbussinessMedia= () => {
  return {
    type: actionTypes.START_BUSSINESS_MEDIA
  };
};

export const bussinessMediaFail = message => {
  return {
    type: actionTypes.BUSSINESS_MEDIA_FAIL,
    message
  };
};

export const getBusinessMediaSUCCESS = (data) => {
    // console.log(data.slide)
    return {
      type: actionTypes.GET_BUSINESS_MEDIA_SUCCESS,
      businessPicture: data.businessPicture,
      slide: data.slide,
      businessVideo:data.businessVideo
    };
  };
export const progressBar = (progress) => {
    return {
      type: actionTypes.PROGRESS_BAR,
      progress: progress,
    };
  };

// export const pictureProgressBar = (progress) => {
//     return {
//       type: actionTypes.PICTURE_PROGRESS,
//       pictureProgress: progress,
//     };
//   };
export const getbusinessMediaOnId = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("/admin/get-business-user/"+data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                
              dispatch(getBusinessMediaSUCCESS(response.data.data))
              // console.log(response.data.data)
              // dispatch(fetchprofessionalListSUCCESS(response.data.data,"Professional deleted sucessfully"));
            } else {
              dispatch(bussinessMediaFail(response.data.message));
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
        });
    };
  };


  export const getBusinessPictureUploadSucess = (data,message) => {
    return {
      type: actionTypes.BUSSINESS_PICTURE_UPLOAD_SUCCESS,
      businessPicture: data.businessPicture,
      slide: data.slide,
      message
    };
  };

  export const businessPictureUpload = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/upload-photo-video" , data, {
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };

  export const couponRankingBusinessPictureUpload = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/upload-coupon-ranking-business-picture" , data, {
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };
  export const couponRankingBusinessAdUpload = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/upload-coupon-ranking-business-ad" , data, {
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };

  export const couponRankingBusinessAdDelete = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-coupon-ranking-business-ad" , data, {
          headers: { Authorization: authCode },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };

  export const getBusinessVideoUploadSucess = (data,message) => {
    return {
      type: actionTypes.BUSSINESS_VIDEO_UPLOAD_SUCCESS,
      businessVideo: data.businessVideo,
      message
    };
  };

  export const businessVideoUpload = (data) => {
    
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      
      axios
        .post("/admin/upload-photo-video" , data,{
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
           dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessVideoUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };



  export const getBusinessPictureDeleteSucess = (data,message) => {
    return {
      type: actionTypes.BUSSINESS_PICTURE_DELETE_SUCCESS,
      businessPicture: data.businessPicture,
      message
    };
  };

  export const businessPictureDelete = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-photo-video" , data, {
          headers: { Authorization: authCode },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureDeleteSucess(response.data.data,response.data.message))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
        });
    };
  };
  export const couponRankingBusinessPictureDelete = (data) => {
    return dispatch => {
      dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-coupon-ranking-business-picture" , data, {
          headers: { Authorization: authCode },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessPictureDeleteSucess(response.data.data,response.data.message))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
        });
    };
  };


  export const getBusinessVideoDeleteSucess = (data,message) => {
    return {
      type: actionTypes.BUSSINESS_VIDEO_DELETE_SUCCESS,
      businessVideo: data.businessVideo,
      message
    };
  };

  export const businessVideoDelete = (data) => {
    return dispatch => {
      // dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-photo-video" , data, {
          headers: { Authorization: authCode},
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getBusinessVideoDeleteSucess(response.data.data,response.data.message))
            } else {
              dispatch(bussinessMediaFail(response.data.message));
            }
          } else {
            dispatch(bussinessMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(bussinessMediaFail(err.message));
        });
    };
  };




