import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  subcategoryList:[],
  advertisementPicture: [],
  advertisementVideo:[],
  progress:0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_SUBCATEGORY:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.GET_SUBCATEGORY_LIST_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.GET_SUBCATEGORY_LIST_SUCCCES :
      return {
          ...state,
          loading:null,
          subcategoryList: action.subcategoryList,
          error:null,
          success:action.message,
          
      }
      case actionTypes.START_ADVERTISEMENT_PICTURE:
        return {
            ...state,
            loading:true,
            error:null
        };
      case actionTypes.ADVERTISEMENT_PICTURE_FAIL :
          return {
              ...state,
              loading:null,
              error:action.message,
              success:null,
          }
      case actionTypes.GET_ADVERTISEMENT_PICTURE_SUCCESS :
        return {
            ...state,
            loading:null,
            advertisementPicture: action.advertisementPicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS :
        return {
            ...state,
            loading:null,
            advertisementPicture: action.advertisementPicture,
            error:null,
            success:action.message,
            
        }
        case actionTypes.ADVERTISEMENT_PICTURE_DELETE_SUCCESS :
        return {
            ...state,
            loading:null,
            advertisementPicture: action.advertisementPicture,
            error:null,
            success:action.message,
            
        }

        case actionTypes.START_ADVERTISEMENT_VIDEO:
          return {
              ...state,
              loading:true,
              error:null
          };
        case actionTypes.ADVERTISEMENT_VIDEO_FAIL :
            return {
                ...state,
                loading:null,
                error:action.message,
                success:null,
            }
        case actionTypes.GET_ADVERTISEMENT_VIDEO_SUCCESS :
          return {
              ...state,
              loading:null,
              advertisementVideo: action.advertisementVideo,
              error:null,
              success:action.message,
              
          }
          case actionTypes.ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS :
          return {
              ...state,
              loading:null,
              advertisementVideo: action.advertisementVideo,
              error:null,
              success:action.message,
              
          }
          case actionTypes.ADVERTISEMENT_VIDEO_DELETE_SUCCESS :
          return {
              ...state,
              loading:null,
              advertisementVideo: action.advertisementVideo,
              error:null,
              success:action.message,
              
          }
        case actionTypes.PROGRESS_BAR :
          return {
              ...state,
              loading:null,
              error:null,
              progress:action.progress,
              
          }
    default:
      return state;
  }
};



export default reducer;
