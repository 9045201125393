import React, { Component } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactPlayer from 'react-player'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'
const styles = {
    uploadedImgWrapper: {
        border: '2px dotted #ccc',
        maxWidth: 500,
        minHeight: 200,
        borderRadius: 5,
        margin: '0 auto',
    },
    storyContent: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '20px'
    }
}

class BlogDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isAddStory: false,
            story_title: '',
            story_content: '',
            story_picture_website_url: '',
            story_video_website_url: '',
            story_picture: '',
            story_video: '',
            storyfile1: '',
            storyfile2: '',
            //for delete story and blog
            storyId: '',
            blogId: '',
            deleteFor: '',
            isEditStory: false,
            isBlogEdit: false,
            //for edit blog
            blog_title: '',
            formErrblog_title: '',
            blog_description: '',
            blog_picture: '',
            blog_picture_website_url: '',
            blog_video: '',
            blog_video_website_url: '',
            blogfile1: '',
            blogfile2: '',
            //for edit blog story
            openEditStory: false,
            editStoryNo: '',
            edit_StoryId: '',
            edit_story_title: '',
            edit_story_content: '',
            edit_story_picture_website_url: '',
            edit_story_video_website_url: '',
            edit_story_picture: '',
            edit_story_video: '',
            edit_storyfile1: '',
            edit_storyfile2: '',

        }
        this.handleUploadFile2 = this.handleUploadFile2.bind(this)
        this.handleUploadFile3 = this.handleUploadFile3.bind(this)
        this.handleUploadFile = this.handleUploadFile.bind(this)
        this.handleUploadFile1 = this.handleUploadFile1.bind(this)
        this.handleUploadEditStoryVideo = this.handleUploadEditStoryVideo.bind(this);
        this.handleUploadEditStoryPicture = this.handleUploadEditStoryPicture.bind(this)

    }
    componentDidMount = async () => {
        let data = {
            blogId: this.props.match.params.id
        }
        await this.props.getBlogDetails(data)
    }

    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success, loading } = this.props;
        // loading && enqueueSnackbar(loading, { variant: 'loading' });
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
    }


    openStoryBox = async () => {
        this.setState({
            isAddStory: true
        })
    }
    removeStory = async () => {
        this.setState({
            isAddStory: false
        })
    }

    onChangeText = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })

        //story set state
        if (key === "story_title") {
            await self.setState({
                story_title: value
            })
        }
        if (key === "story_content") {
            await self.setState({
                story_content: value
            })
        }
        if (key === "story_picture_website_url") {
            await self.setState({
                story_picture_website_url: value,
            })
        }
        if (key === "story_video_website_url") {
            await self.setState({
                story_video_website_url: value,
            })
        }

    }

    handleUploadFile2 = async (event) => {
        await this.setState({
            storyfile1: URL.createObjectURL(event.target.files[0]), story_picture: event.target.files[0],
        })
    }
    handleUploadFile3 = async (event) => {
        await this.setState({
            storyfile2: URL.createObjectURL(event.target.files[0]), story_video: event.target.files[0],
        })
    }


    handleSubmit = async () => {
        // await this.setState({
        //     formErrblog_title: (this.state.blog_title === '') ? "Please provide this field" : '',
        // })
        if (this.props.blogDetails._id !== '') {
            const data = new FormData();
            //story details
            data.append('blogId', this.props.blogDetails._id)
            data.append('story_title', this.state.story_title);
            data.append('story_content', this.state.story_content);
            data.append('story_picture_website_url', this.state.story_picture_website_url);
            data.append('story_video_website_url', this.state.story_video_website_url);
            data.append('story_picture', this.state.story_picture);
            data.append('story_video', this.state.story_video);
            await this.props.addBlogStory(data, this.props.history);
            await this.removeStory()

        }
    }

    deleteBlogOrStoryPermanently() {
        if (this.state.deleteFor == 'story') {
            let data = {
                blogId: this.state.blogId,
                storyId: this.state.storyId
            }
            this.props.deleteBlogStory(data, this.props.history);
            this.close()
        } else {
            let data = { blogId: this.state.blogId }
            this.props.deleteBlog(data, this.props.history);
            this.close()
        }
    }

    handleOpenDelete = (type, blogId, storyId) => {
        if (type == 'story') {
            this.setState({
                blogId: blogId, storyId: storyId, open: true, deleteFor: 'story'
            });
        } else {
            this.setState({ blogId: blogId, open: true, deleteFor: 'blog' });
        }
    }
    open() {
        this.setState({ open: true });
    }
    close() {
        this.setState({ open: false, deleteFor: '' });
    }
    // for edit blog functionality
    openBlogEdit = () => {
        this.setState({ isBlogEdit: true });
        this.setState({
            blog_title: this.props.blogDetails.blog_title,
            blog_description: this.props.blogDetails.blog_description,
            blog_picture_website_url: this.props.blogDetails.blog_picture_website_url,
            blog_video_website_url: this.props.blogDetails.blog_video_website_url
        })
    }
    cancelBlogEdit = () => {
        this.setState({ isBlogEdit: false });
    }
    handleUploadFile = async (event) => {
        await this.setState({
            blogfile1: URL.createObjectURL(event.target.files[0]), blog_picture: event.target.files[0],
        })
    }

    handleUploadFile1 = async (event) => {
        await this.setState({
            blogfile2: URL.createObjectURL(event.target.files[0]), blog_video: event.target.files[0],
        })
    }

    onChangeText = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
        //blog set state
        if (key === "blog_title") {
            await self.setState({
                blog_title: value,
                formErrblog_title: ''
            })
        }
        if (key === "blog_description") {
            await self.setState({
                blog_description: value
            })
        }
        if (key === "blog_picture_website_url") {
            await self.setState({
                blog_picture_website_url: value,
            })
        }
        if (key === "blog_video_website_url") {
            await self.setState({
                blog_video_website_url: value,
            })
        }

    }
    handleBlogEditSubmit = async () => {
        await this.setState({
            formErrblog_title: (this.state.blog_title === '') ? "Please provide this field" : '',
        })
        if (this.state.blog_title !== '') {
            const data = new FormData();
            data.append('blogId', this.props.blogDetails._id)
            data.append('blog_title', this.state.blog_title);
            data.append('blog_description', this.state.blog_description);
            data.append('blog_picture', this.state.blog_picture);
            data.append('blog_picture_website_url', this.state.blog_picture_website_url);
            data.append('blog_video', this.state.blog_video);
            data.append('blog_video_website_url', this.state.blog_video_website_url);
            await this.props.editBlog(data, this.props.history);
            this.cancelBlogEdit()

        }
    }

    //edit blog story functionality
    closeEditModel = () => {
        this.setState({ openEditStory: false });
        this.setState({
            edit_story_title: '',
            edit_story_content: '',
            edit_story_picture_website_url: '',
            edit_story_video_website_url: '',
            edit_story_picture: '',
            edit_story_video: '',
            editStoryNo: '',
            edit_StoryId: '',
        })
    }
    openEditModel = (item, key) => {
        this.setState({ openEditStory: true });
        this.setState({
            edit_story_title: item.story_title,
            edit_story_content: item.story_content,
            edit_story_picture_website_url: item.story_picture_website_url,
            edit_story_video_website_url: item.story_video_website_url,
            edit_story_picture: item.story_picture,
            edit_story_video: item.story_video,
            editStoryNo: key + 1,
            edit_StoryId: item._id,
        })

    }

    onChangeEditStoryText = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })
        //story set state
        if (key === "story_title") {
            await self.setState({
                edit_story_title: value
            })
        }
        if (key === "story_content") {
            await self.setState({
                edit_story_content: value
            })
        }
        if (key === "story_picture_website_url") {
            await self.setState({
                edit_story_picture_website_url: value,
            })
        }
        if (key === "story_video_website_url") {
            await self.setState({
                edit_story_video_website_url: value,
            })
        }

    }
    handleUploadEditStoryVideo = async (event) => {
        await this.setState({
            edit_storyfile2: URL.createObjectURL(event.target.files[0]), edit_story_video: event.target.files[0]
        })
    }
    handleUploadEditStoryPicture = async (event) => {
        await this.setState({
            edit_storyfile1: URL.createObjectURL(event.target.files[0]), edit_story_picture: event.target.files[0]
        })
    }

    handleStoryEditSubmit = async () => {
        await this.setState({
            formErrblog_title: (this.state.blog_title === '') ? "Please provide this field" : '',
        })

        if (this.props.blogDetails._id !== '' && this.state.edit_StoryId !== '') {
            const data = new FormData();
            //story details
            data.append('blogId', this.props.blogDetails._id)
            data.append('storyId', this.state.edit_StoryId)
            data.append('story_title', this.state.edit_story_title);
            data.append('story_content', this.state.edit_story_content);
            data.append('story_picture_website_url', this.state.edit_story_picture_website_url);
            data.append('story_video_website_url', this.state.edit_story_video_website_url);
            data.append('story_picture', this.state.edit_story_picture);
            data.append('story_video', this.state.edit_story_video);
            await this.props.editBlogStory(data, this.props.history);
            await this.closeEditModel()

        }

    }

    editor = null;
    render() {
        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Blog Details</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={() => this.close()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Do you want to delete {this.state.deleteFor}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.close()}>
                            No
                        </button>
                        <button class='btn btn-icon btn-success btn-sm' onClick={() => this.deleteBlogOrStoryPermanently()} type='button'>
                            Yes
                        </button>
                    </DialogActions>
                </Dialog>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='create-blog blogs card-body'>
                                    <div className='clearfix'>
                                        <form style={{ maxWidth: '800px', margin: '0 auto' }}>
                                            {!this.state.isBlogEdit ?
                                                <div className='blog-details'>
                                                    <div className='mb-3 d-flex justify-content-between'>
                                                        <h1 >Blog Details</h1>
                                                        <button className='btn btn-primary ml-auto' type='button' onClick={() => this.openBlogEdit()} >Edit Blog</button>
                                                        <button className='btn btn-danger ml-2' type='button' onClick={() => this.handleOpenDelete('blog', this.props.blogDetails._id)}>Delete</button>
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <TextField id="outlined-basic" label="Blog Title" variant="outlined" defaultValue="Hello Title" value={this.props.blogDetails.blog_title} fullWidth inputProps={{ readOnly: true }} />
                                                    </div>
                                                    <div className='form-group mb-3'>
                                                        <TextField id="outlined-multiline-static" label="Blog Description" defaultValue="Hello Description" value={this.props.blogDetails.blog_description} multiline rows={4} fullWidth inputProps={{ readOnly: true }} />
                                                    </div>
                                                    <div className='row'>
                                                        {this.props.blogDetails.blog_picture ?
                                                            <div className='col-md-6'>
                                                                <a target="_blank" href={this.props.blogDetails.blog_picture_website_url ? 'https://' + this.props.blogDetails.blog_picture_website_url : '#'} onClick={(event) => !this.props.blogDetails.blog_picture_website_url ? event.preventDefault() : true}>
                                                                    <div className='uploaded-img-wrapper mb-3'>
                                                                        <img src={this.props.blogDetails.blog_picture} alt='' className='img-fluid' />
                                                                    </div>
                                                                </a>
                                                                {this.props.blogDetails.blog_picture_website_url ?
                                                                    <div className='form-group mb-3'>
                                                                        <TextField type="text" id="outlined-basic" variant="outlined" size="small" value={this.props.blogDetails.blog_picture_website_url} defaultValue="www.google.com" placeholder='Enter Redirection URL' fullWidth inputProps={{ readOnly: true }} />
                                                                    </div> : ''
                                                                }
                                                            </div> : ''
                                                        }
                                                        {this.props.blogDetails.blog_video ?
                                                            <div className='col-md-6'>
                                                                <a target="_blank" href={this.props.blogDetails.blog_video_website_url ? 'https://' + this.props.blogDetails.blog_video_website_url : '#'} onClick={(event) => !this.props.blogDetails.blog_video_website_url ? event.preventDefault() : true}>
                                                                    <div className='uploaded-img-wrapper mb-3'>
                                                                        <ReactPlayer url={this.props.blogDetails.blog_video} playing={false} volume={0} controls={true} width={'100%'} height="315"></ReactPlayer>
                                                                        {/* <iframe width="100%" src={this.props.blogDetails.blog_video} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                                                    </div>
                                                                </a>
                                                                {this.props.blogDetails.blog_video_website_url ?
                                                                    <div className='form-group mb-3'>
                                                                        <TextField type="text" id="outlined-basic" variant="outlined" size="small" value={this.props.blogDetails.blog_video_website_url} defaultValue="www.google.com" placeholder='Enter Redirection URL' fullWidth inputProps={{ readOnly: true }} />
                                                                    </div> : ''
                                                                }
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </div> :
                                                <div>
                                                    <form style={{ maxWidth: '800px', margin: '0 auto' }}>
                                                        <div className='blog-details'>
                                                            <h1 className='mb-3'> Edit Blog Details</h1>
                                                            <div className='form-group mb-3'>
                                                                <TextField id="outlined-basic" label="Blog Title" variant="outlined" fullWidth
                                                                    value={this.state.blog_title}
                                                                    onChange={(e) => this.onChangeText('blog_title', e.target.value)}
                                                                />
                                                                <span style={{ color: 'red' }}>{this.state.formErrblog_title}</span>
                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField id="outlined-multiline-static" label="Blog Description" multiline rows={4} fullWidth
                                                                    value={this.state.blog_description}
                                                                    onChange={(e) => this.onChangeText('blog_description', e.target.value)}
                                                                />
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                        <img src={this.state.blogfile1 ? this.state.blogfile1 : this.props.blogDetails.blog_picture ? this.props.blogDetails.blog_picture : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                                    </div>
                                                                    <div className='form-group mb-3'>
                                                                        <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                            value={this.state.blog_picture_website_url}
                                                                            onChange={(e) => this.onChangeText('blog_picture_website_url', e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }} onChange={this.handleUploadFile}>Upload Blog Image
                                                                        <input hidden accept="image/*" multiple type="file"
                                                                            onChange={this.handleUploadFile} />
                                                                    </Button>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                        {/* <video src={this.state.blogfile2 ? this.state.blogfile2 : '../assets/img/theme/img-1-1000x600.jpg'} /> */}
                                                                        <iframe width="100%" height="315" src={this.state.blogfile2 ? this.state.blogfile2 : this.props.blogDetails.blog_video ? this.props.blogDetails.blog_video : '../assets/img/theme/img-1-1000x600.jpg'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

                                                                        </iframe>
                                                                    </div>
                                                                    <div className='form-group mb-3'>
                                                                        <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                            value={this.state.blog_video_website_url}
                                                                            onChange={(e) => this.onChangeText('blog_video_website_url', e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Blog Video
                                                                        <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile1} />
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-5 text-left'>
                                                            <button className='btn btn-success' type='button' onClick={(e) => this.handleBlogEditSubmit()} >Update Blog</button>
                                                            <button className='btn btn-default ml-2' type='button' onClick={(e) => this.cancelBlogEdit()} >Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>

                                            }


                                            {/* isBlogEdit */}
                                            {/* story functionality */}
                                            {
                                                this.props.blogDetails.blog_story ?
                                                    this.props.blogDetails.blog_story.map((item, key) => (
                                                        <div className='story-details mt-4'>
                                                            <div className='mb-3 d-flex align-items-center justify-content-between'>
                                                                <h1>Story Details ({key + 1})</h1>
                                                                <button className='btn btn-sm btn-primary ml-auto' type='button' onClick={() => this.openEditModel(item, key)}><i class="fas fa-pencil-alt"></i></button>
                                                                <button className='btn btn-icon btn-danger btn-sm ml-2' type='button' onClick={() => this.handleOpenDelete('story', this.props.blogDetails._id, item._id)}><i class="fas fa-trash"></i></button>
                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField id="outlined-basic" label="Story Title" variant="outlined" value={item.story_title} defaultValue="Hello Title" fullWidth inputProps={{ readOnly: true }} />
                                                            </div>
                                                            {item.story_content ?
                                                                <div className='mb-3'>
                                                                    <label className='mb-2'>Story Content</label>
                                                                    <div style={styles.storyContent} dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            (item.story_content !== null) ? item.story_content : "waiting"
                                                                    }}></div>
                                                                </div> : ''
                                                            }

                                                            <div className='row'>
                                                                {item.story_picture ?
                                                                    <div className='col-md-6'>
                                                                        <a target="_blank" href={item.story_picture_website_url ? 'https://' + item.story_picture_website_url : "#"} onClick={(event) => !item.story_picture_website_url ? event.preventDefault() : true} >
                                                                            <div className='uploaded-img-wrapper mb-3'>
                                                                                <img src={item.story_picture} alt='' className='img-fluid' />
                                                                            </div>
                                                                        </a>
                                                                        {
                                                                            item.story_picture_website_url ?
                                                                                <div className='form-group mb-3'>
                                                                                    <TextField type="text" id="outlined-basic" variant="outlined" size="small" value={item.story_picture_website_url} defaultValue="www.google.com" placeholder='Enter Redirection URL' fullWidth inputProps={{ readOnly: true }} />
                                                                                </div> : ''
                                                                        }
                                                                    </div> : ''
                                                                }
                                                                {
                                                                    item.story_video ?
                                                                        <div className='col-md-6'>
                                                                            <a target="_blank" href={item.story_video_website_url ? 'https://' + item.story_video_website_url : '#'} onClick={(event) => !item.story_video_website_url ? event.preventDefault() : true}>
                                                                                <div className='uploaded-img-wrapper mb-3'>
                                                                                    <ReactPlayer url={item.story_video} playing={false} volume={0} controls={true} width={'100%'} height="315"></ReactPlayer>
                                                                                    {/* <iframe width="100%" height="315" src={item.story_video} title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe> */}
                                                                                </div>
                                                                            </a>
                                                                            {item.story_video_website_url ?
                                                                                <div className='form-group mb-3'>
                                                                                    <TextField type="text" id="outlined-basic" variant="outlined" value={item.story_video_website_url} size="small" defaultValue="www.google.com" placeholder='Enter Redirection URL' fullWidth />
                                                                                </div> : ''
                                                                            }
                                                                        </div> : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                    : ''
                                            }
                                            <button className='btn btn-success mt-3' type='button' onClick={() => this.openStoryBox()}>Add Story</button>
                                        </form>

                                        {this.state.isAddStory ?
                                            <form style={{ maxWidth: '800px', margin: '0 auto' }}>
                                                <div className='story-details mt-4'>
                                                    <h1 className='mb-3'>Story Details</h1>
                                                    <div className='form-group mb-3'>
                                                        <TextField id="outlined-basic" label="Story Title" variant="outlined" fullWidth
                                                            value={this.state.story_title}
                                                            onChange={(e) => this.onChangeText('story_title', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='mb-3'>
                                                        <label className='mb-2'>Story Content</label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data="<p>Hello from CKEditor 5!</p>"
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                            }}
                                                            config={{
                                                                link: {
                                                                    decorators: {
                                                                        // addTargetToExternalLinks: {
                                                                        //     mode: "automatic",
                                                                        //     callback: (url) => /^(https?:)?\/\//.test(url),
                                                                        //     attributes: {
                                                                        //         target: "_blank",
                                                                        //         rel: "noopener noreferrer",
                                                                        //     },
                                                                        // },
                                                                        openInNewTab: {
                                                                            mode: 'manual',
                                                                            label: 'Open in a new tab',
                                                                            defaultValue: true,			// This option will be selected by default.
                                                                            attributes: {
                                                                                target: '_blank',
                                                                                rel: 'noopener noreferrer'
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                            }}

                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.onChangeText('story_content', data)
                                                            }}
                                                            value={this.state.story_content}
                                                            onBlur={(event, editor) => {
                                                            }}
                                                            onFocus={(event, editor) => {

                                                            }}

                                                        />
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                <img src={this.state.storyfile1 ? this.state.storyfile1 : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                    value={this.state.story_picture_website_url}
                                                                    onChange={(e) => this.onChangeText('story_picture_website_url', e.target.value)}
                                                                />
                                                            </div>
                                                            <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Image
                                                                <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile2} />
                                                            </Button>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                <video src={this.state.storyfile2 ? this.state.storyfile2 : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                    value={this.state.story_video_website_url}
                                                                    onChange={(e) => this.onChangeText('story_video_website_url', e.target.value)}
                                                                />
                                                            </div>
                                                            <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Video
                                                                <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadFile3} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mt-5 text-left'>
                                                    <button className='btn btn-success' type='button' onClick={(e) => this.handleSubmit()}>Update</button>
                                                    <button className='btn btn-default ml-2' type='button' onClick={() => this.removeStory()}>Cancel</button>
                                                </div>
                                            </form> : ''
                                        }
                                        {
                                            this.props.loading ?
                                                <div className='loader-wrapper'>
                                                    <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                                </div> : ''
                                        }
                                    </div>
                                    {/* for edit story */}
                                    <Modal isOpen={this.state.openEditStory} toggle={() => this.closeEditModel()} size='lg'>
                                        {/* <ModalHeader toggle={() => this.close()}>
                                            <Label for="title">Add Subscription</Label>
                                        </ModalHeader> */}
                                        <ModalBody>
                                            <form style={{ maxWidth: '800px', margin: '0 auto' }}>
                                                <div className='story-details mt-2'>
                                                    <h1 className='mb-3'>Edit Story Details ({this.state.editStoryNo})</h1>
                                                    <div className='form-group mb-3'>
                                                        <TextField id="outlined-basic" label="Story Title" variant="outlined" fullWidth
                                                            value={this.state.edit_story_title}
                                                            onChange={(e) => this.onChangeEditStoryText('story_title', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='mb-3'>
                                                        <label className='mb-2'>Story Content</label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={this.state.edit_story_content ? this.state.edit_story_content : "<p>Hello from CKEditor 5!</p>"}
                                                            onReady={editor => {
                                                                // You can store the "editor" and use when it is needed.
                                                            }}
                                                            config={{
                                                                link: {
                                                                    decorators: {
                                                                        openInNewTab: {
                                                                            mode: 'manual',
                                                                            label: 'Open in a new tab',
                                                                            defaultValue: true,			// This option will be selected by default.
                                                                            attributes: {
                                                                                target: '_blank',
                                                                                rel: 'noopener noreferrer'
                                                                            }
                                                                        }
                                                                    },
                                                                },
                                                            }}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                this.onChangeEditStoryText('story_content', data)
                                                            }}
                                                            value={this.state.edit_story_content}
                                                            onBlur={(event, editor) => {
                                                            }}
                                                            onFocus={(event, editor) => {
                                                            }}

                                                        />
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                <img src={this.state.edit_storyfile1 ? this.state.edit_storyfile1 : this.state.edit_story_picture ? this.state.edit_story_picture : '../assets/img/theme/img-1-1000x600.jpg'} />
                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                    value={this.state.edit_story_picture_website_url}
                                                                    onChange={(e) => this.onChangeEditStoryText('story_picture_website_url', e.target.value)}
                                                                />
                                                            </div>
                                                            <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Image
                                                                <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadEditStoryPicture} />
                                                            </Button>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='uploaded-img-wrapper mb-3' style={styles.uploadedImgWrapper}>
                                                                <iframe width="100%" height="315" src={this.state.edit_storyfile2 ? this.state.edit_storyfile2 : this.state.edit_story_video ? this.state.edit_story_video : '../assets/img/theme/img-1-1000x600.jpg'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                                                </iframe>

                                                            </div>
                                                            <div className='form-group mb-3'>
                                                                <TextField type="text" id="outlined-basic" variant="outlined" size="small" placeholder='Enter Redirection URL' fullWidth
                                                                    value={this.state.edit_story_video_website_url}
                                                                    onChange={(e) => this.onChangeEditStoryText('story_video_website_url', e.target.value)}
                                                                />
                                                            </div>
                                                            <Button variant="contained" component="label" size="large" sx={{ textTransform: 'capitalize' }}>Upload Story Video
                                                                <input hidden accept="image/*" multiple type="file" onChange={this.handleUploadEditStoryVideo} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='mt-5 mb-4 text-left'>
                                                    <button className='btn btn-success' type='button' onClick={(e) => this.handleStoryEditSubmit()} >Update</button>
                                                    <button className='btn btn-default ml-2' type='button' onClick={() => this.closeEditModel()}>Cancel</button>
                                                </div>
                                            </form>
                                        </ModalBody>
                                    </Modal>

                                </div>
                            </div>

                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.blog.success,
        error: state.blog.error,
        loading: state.blog.loading,
        blogDetails: state.blog.blogDetails
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBlogDetails: (data) => dispatch(actions.getBlogDetails(data)),
        addBlogStory: (data, history) => dispatch(actions.addBlogStory(data, history)),
        deleteBlogStory: (data, history) => dispatch(actions.deleteBlogStory(data, history)),
        deleteBlog: (data, history) => dispatch(actions.deleteBlog(data, history)),
        editBlog: (data, history) => dispatch(actions.editBlog(data, history)),
        editBlogStory: (data, history) => dispatch(actions.editBlogStory(data, history))
    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BlogDetails));


