import React from 'react'
import {
  BrowserRouter as Router,
  Link
} from 'react-router-dom'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack'

class FirstOrderDiscount  extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reciever_amount_in_percentage: '',
      formErrreciever_amount_in_percentage: '',
      reciever_validity: '',
      formErrreciever_validity: '',
      is_active:true,
      business_id:this.props.match.params.id,
      check:false
    }
    this.updateState = this.updateState.bind(this);

  }
  async updateState() {
    // await this.setState(data);
    setTimeout(async () => {
      console.log('Our data is fetched');
      if(this.props.firstorderdiscountListing){
        await this.setState({
          reciever_amount_in_percentage: this.props.firstorderdiscountListing.reciever_amount_in_percentage,
          reciever_validity: this.props.firstorderdiscountListing.reciever_validity,
          is_active:this.props.firstorderdiscountListing.is_active,
          check:true
        })
      }
      else{
        await this.setState({
          reciever_amount_in_percentage: '',
          reciever_validity: '',
          is_active:true,
          check:true
        })
      }
      
    }, 1000)
  }
  
  onChangeAddName = async (key, value) => {
   var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }
  async componentWillMount () {
    await this.props.fetchfirstorderdiscountList(this.props.match.params.id)
    
    const discount = await this.props.firstorderdiscountListing
    await this.updateState()
    
  }
  handleSubmit = async()=>{
    
    if(this.state.reciever_amount_in_percentage == ''){
      await this.setState({
        formErrreciever_amount_in_percentage:"Please provide this field"
      })
    }
    else if(this.state.reciever_validity == ''){
      await this.setState({
        formErrreciever_validity:"Please provide a this field"
      })
    }
    else if(this.state.reciever_amount_in_percentage !== '' && this.state.reciever_validity !== ''){
      await this.setState({
        formErrreciever_amount_in_percentage:"",
        formErrsender_amount_in_percentage:"",
        formErrreciever_validity:"",
        formErrsender_validity:""
      })
      
      this.props.addFirstOrderDiscount({
        reciever_amount_in_percentage: this.state.reciever_amount_in_percentage,
        reciever_validity: this.state.reciever_validity,
        is_active:this.state.is_active,
        business_id:this.state.business_id
      })
      
    }
  }
  render(){
    console.log(this.props.firstorderdiscountListing)
    if(!this.state.check){
      this.updateState()
    }
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Business Owner First Order Discount</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-6'>
                      <h3 className='mb-0'>First Order Discount</h3>
                    </div>
                  </div>
                </div>
  
                <div className='card-body'>
                  <div className='row'>
  
                    <div className='col-6'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Reciever Amount in percentage</label>
                            <input type='text' id='input-slot' className='form-control'    value={this.state.reciever_amount_in_percentage} onChange={(e)=>this.onChangeAddName('reciever_amount_in_percentage',e.target.value)}/>
                              <span style={{color:'red'}}>{this.state.formErrreciever_amount_in_percentage}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-email'>Reciever Validity (days)</label>
                            <input type='text' id='input-slot' className='form-control'   value={this.state.reciever_validity} onChange={(e)=>this.onChangeAddName('reciever_validity',e.target.value)}/>
                            <span style={{color:'red'}}>{this.state.formErrreciever_validity}</span>

                          </div>
                        </div>
                      </div>
  
                      {/* <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Sender Amount in percentage</label>
                            <input type='text' id='input-slot' className='form-control'   value={this.state.sender_amount_in_percentage} onChange={(e)=>this.onChangeAddName('sender_amount_in_percentage',e.target.value)}/>
                            <span style={{color:'red'}}>{this.state.formErrsender_amount_in_percentage}</span>

                          </div>
                        </div>
  
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Sender Validity(days)</label>
                            <input type='text' id='input-slot' className='form-control'   value={this.state.sender_validity} onChange={(e)=>this.onChangeAddName('sender_validity',e.target.value)}/>
                            <span style={{color:'red'}}>{this.state.formErrsender_validity}</span>

                          </div>
                        </div>
                      </div> */}
  
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label float-left mr-3'>Active</label>
                            <label class='custom-toggle'>
                              <input type='checkbox' checked={this.state.is_active === true}
                              
                              />
                              <span class='custom-toggle-slider rounded-circle'
                              onClick={(e)=>this.onChangeAddName('is_active',!this.state.is_active)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
  
                      <button className='btn btn-success float-right' 
                       onClick={(e)=>this.handleSubmit()}
                      type='button'>Update</button>
                      <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    success: state.firstOrderDiscount.success,
    error: state.firstOrderDiscount.error,
    firstorderdiscountListing:state.firstOrderDiscount.firstorderdiscountListing,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    addFirstOrderDiscount: (data) => dispatch(actions.addFirstOrderDiscountAction(data)),
    fetchfirstorderdiscountList: (data) => dispatch(actions.fetchfirstorderdiscountListAction(data)),
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(withRouter(FirstOrderDiscount)))
