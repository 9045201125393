import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startBlog = () => {
  return {
    type: actionTypes.START_BLOG
  };
};

export const blogFail = message => {
  return {
    type: actionTypes.GET_BLOG_FAIL,
    message
  };
};

export const getBlogSuccess = (data,message) => {
  return {
    type: actionTypes.GET_BLOG_SUCCESS,
    blogList: data,
    message
  };
};


export const progressBar = (progress) => {
  return {
    type: actionTypes.PROGRESS_BAR,
    progress: progress,
  };
};

export const getBlogList = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("admin/get-blog-list", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogSuccess(response.data.data))
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const createBlog = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/create-blog", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogList(response.data.data, response.data.message))
            const load = () => { history.push('./blogs') }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const getBlogDetailSuccess = (data,message) => {
  return {
    type: actionTypes.GET_BLOG_DETAILS_SUCCESS,
    blogDetails: data,
    message
  };
};


export const getBlogDetails = (data) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/get-blog-details",data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data))
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const addBlogStory = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/add-blog-story", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data, response.data.message))
            const load = () => { history.push(`/blog-details/${response.data.data._id}`) }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const deleteBlogStory = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/delete-blog-story", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data, response.data.message))
            const load = () => { history.push(`/blog-details/${response.data.data._id}`) }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const deleteBlog = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/delete-blog", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogList(response.data.data, response.data.message))
            const load = () => { history.push('/blogs') }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};


export const editBlog = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/edit-blog", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data, response.data.message))
            const load = () => { history.push(`/blog-details/${response.data.data._id}`) }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};

export const editBlogStory = (data,history) => {
  return dispatch => {
    dispatch(startBlog());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("admin/edit-blog-story", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBlogDetailSuccess(response.data.data, response.data.message))
            const load = () => { history.push(`/blog-details/${response.data.data._id}`) }
            setTimeout(load, 2000);
          } else {
            dispatch(blogFail(response.data.message));
          }
        } else {
          dispatch(blogFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogFail(err.message));
      });
  };
};





