import React from 'react'
import {
  BrowserRouter as Router,
  Link,
  NavLink
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import moment from 'moment';

class OrdersCurrentService extends React.Component {
  componentWillMount() {
    this.props.fetchOrderList({});
  }
  changeActiveStatus(id,data) {
    
    if(id){
      this.props.updateOrderList(id,data);
    }
  }
  render(){

    var sno = 1

    let orderList = [];
    let orderTable = <Spinner />;
    
    {this.props.orderListing.length > 0?
      this.props.orderListing.map(datas=>{
        if(datas.status === 'accept' || datas.status ==='accepted' || datas.status ==='reschedule'){
          orderList.push(
            {
          
              s_no:(sno++),
              OrderId:datas._id,
              CustomerName:datas.user_id[0].name,
              CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
              ProfessionalName:datas.professional_id.length > 0?datas.professional_id[0].name:"",
              ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
              BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
              Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
              SlotTime:datas._id,
              GrandTotal:datas.total_Ammount,
              PaymentMethod:datas.payment_method,
              OrderStatus:datas.status,
              trackingStatus:datas.trackingStatus,
              order_type:datas.order_type,

              action:<div>
                {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                  <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                  <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                </NavLink>},
              
                  <button type='button' className='btn btn-success' data-toggle='tooltip' data-placement='top' title='completed'
                  onClick={(id) =>this.changeActiveStatus(datas._id,{status:'completed',status_updated_by:localStorage.getItem("userId")})}
                  >
                    <span className='btn-inner--icon'><i className='fas fa-check' /></span>
                  </button>
              <button type='button' className='btn btn-danger' data-toggle='tooltip' data-placement='top' title='Reject'
              onClick={(id) =>this.changeActiveStatus(datas._id,{status:'reject',status_updated_by:localStorage.getItem("userId")})}
              >
                <span className='btn-inner--icon'><i className='fas fa-ban' /></span>
              </button>
              
              <button type='button' className='btn btn-danger' data-toggle='tooltip' data-placement='top' title='cancel'
              onClick={(id) =>this.changeActiveStatus(datas._id,{status:'cancel',status_updated_by:localStorage.getItem("userId")})}
              >
                <span className='btn-inner--icon'><i className='fas fa-ban' /></span>
              </button>
              
            </div>
          
            }
          )
        }
        
      })
      
      :orderList.push(
        ["No record"]
        )}
        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Order Id",
              field: "OrderId",
              sort: "asc",
            },
            {
              label: "Customer Name",
              field: "CustomerName",
              sort: "asc",
            },
            {
              label: "Customer Phone",
              field: "CustomerPhone",
              sort: "asc",
            },
            {
              label: "Professional Name",
              field: "ProfessionalName",
              sort: "asc",
            },
            {
              label: "Professional Phone",
              field: "ProfessionalPhone",
              sort: "asc",
            },
            {
              label: "Business Name",
              field: "BusinessName",
              sort: "asc",
            },
            {
              label: "Order Date",
              field: "Date",
              sort: "asc",
            },
            {
              label: "performance date",
              field: "SlotTime",
              sort: "asc",
            },
            {
              label: "Grand Total",
              field: "GrandTotal",
              sort: "asc",
            },
            {
              label: "Payment Method",
              field: "PaymentMethod",
              sort: "asc",
            },
            {
              label: "Order Status Upcoming Service",
              field: "OrderStatus",
              sort: "asc",
            },
            {
              label: "Tracking Status Upcoming Service",
              field: "trackingStatus",
              sort: "asc",
            },
            {
              label: "order type",
              field: "order_type",
              sort: "asc",
            },
            
            {
              label: "Action",
              field: "action",
              sort: "asc",
            },
            
          ],
          rows: orderList,
        };
    
        orderTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );
  
  return (
    <div>
      <div className='header bg-primary pb-6'>
        <div className='container-fluid'>
          <div className='header-body'>
            <div className='row align-items-center py-4'>
              <div className='col-lg-6 col-7'>
                <h6 className='h2 text-white d-inline-block mb-0'>Orders</h6>
              </div>
            </div>

            <ul className='nav nav-pills nav-fill flex-column flex-sm-row' id='tabs-text' role='tablist'>
              <li className='nav-item'>
                <Link to='/orders' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-1-tab' data-toggle='tab' href='#tabs-text-1' role='tab' aria-controls='tabs-text-1' aria-selected='true'>New Service</Link>
              </li>
              <li className='nav-item'>
                <Link to='/currentservice' className='nav-link mb-sm-3 mb-md-0 active' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Current Service</Link>
              </li>
              <li className='nav-item'>
                <Link to='/orderhistory' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Service History</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container-fluid mt--6'>
        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-header'>
                <div className='row align-items-center'>
                  <div className='col-4'>
                    <h3 className='mb-0'>Current Service</h3>
                  </div>
                  {/* <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Countries</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div>
                  <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Cities</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div>
                  <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Order Status</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div>
                  <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Professional Name</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div> */}
                </div>
              </div>

              <div className='card-body'>

                <div className='table-responsive'>
                  <table className='table align-items-center table-flush'>
                    {orderTable}
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <footer className='footer pt-0'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6'>
              <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
}
const mapPropsToState = (state) => {
  return {
    success: state.order.success,
    error: state.order.error,
    orderListing: state.order.orderListing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrderList: (data) => dispatch(actions.fetchOrderListAction(data)),
    updateOrderList: (orderId,data) => dispatch(actions.updateOrderListAction(orderId,data)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(OrdersCurrentService));
