import React from 'react'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
import { Editor } from '@tinymce/tinymce-react';
import Map from './map'
import PhoneInput from 'react-phone-input-2'
import {
  Input,
} from 'reactstrap'
import worldMapData from 'city-state-country'
class AddCity extends React.Component {
  constructor(props) {
    super(props)
    this.state ={
      longitude:0,
      latitude:0,
      
      formErrlongitude:'',
      formErrlatitude:'',
      
      country: '',
      formErrcountry: '',
      city: '',
      formErrcity: '',
      country_list:[],
      country_name:'',
      address:'',
      radius:0,
      checkque_limit_min:0,
      formErrcheckque_limit_min:'',
      checkque_limit_max:0,
      formErrcheckque_limit_max:'',
      formErrradius:'',
      cancellation_fees:0,
      formErrcancellation_fees:'',
      tip:0,
      formErrtip:''
  }
  }
  handlelatlong = (value) => {
    // const formvalue = { ...formValues }
    // formvalue.address.x_coordinate = value.latitude
    // formvalue.address.y_coordinate = value.longitude
    // setFormValue(formvalue)
    this.setState({
      latitude:value.latitude,
      longitude:value.longitude
    })
  }
  handleAddress = (value) => {
    this.setState({
      address:value
    })
  }
  handlepincode = (value)=>{
    
  }
  handlecityname = (value)=>{
    
  }
  async componentWillMount(){
    await this.setState({
      country_list:worldMapData.getAllCountries()
    })
  }
  async componentDidMount(){
    const country_array = await worldMapData.getAllCountries()
    await this.setState({
      country_list:country_array
    })
  }
  onChangeAddName = async (key, value) => {

    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
    
    
    if(key === 'country'){
      let result = await this.state.country_list.filter(function(item){
        if(item.sortname == value) return  item.name;
      });
      if(result.length){
        await this.setState({
          country_name:result[0].name
        })
        
      }
      
    }
  }
  handleSubmit = async () => {
    // console.log((this.state.processingFees.split("+")).length)
    // return
    await this.setState({
      formErrcountry: (this.state.country === '') ? "Please provide this field" : '',
      formErrcity: (this.state.city == '') ? "Please provide this field" : '',
      formErrcancellation_fees: (this.state.cancellation_fees == '') ? "Please provide this field" : '',
      formErrradius: (this.state.radius == '') ? "Please provide this field" : '',
      formErrtip:(this.state.tip == '') ? "Please provide this field" : '',
      formErrcheckque_limit_min:(this.state.checkque_limit_min == '') ? "Please provide this field" : '',
      formErrcheckque_limit_max:(this.state.checkque_limit_max == '') ? "Please provide this field" : '',

    })
    
    if (!((this.state.country === '') && (this.state.city === '') && (this.state.cancellation_fees == '') && (this.state.radius == '') && (this.state.tip == '') && (this.state.checkque_limit_min == '') && (this.state.checkque_limit_max == ''))) {
      
      const data =await {
        city_name:this.state.city,
        country_id:this.state.country,
        region_name:this.state.address,
        radius:this.state.radius,
        checkque_limit:{
          min:this.state.checkque_limit_min,
          max:this.state.checkque_limit_max
        },
        cancellation_fees:this.state.cancellation_fees
      }
      this.props.addcitys(data,this.props.history)
      
    }
  }
  render(){
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Add City</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col-xl-4 order-xl-2'>
              <div className='card'>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div style={{ height: 400, width: '100%', border: '1px solid #000' }}>
                              <Map
                                center={{ lat: this.state.latitude, lng: this.state.longitude,radius:this.state.radius }}
                                handlelatlong={(e)=>this.handlelatlong(e)}
                                handleAddress={(e)=>this.handleAddress(e)}
                                handlepincode={(e)=>this.handlepincode(e)}
                                handlecityname={(e)=>this.handlecityname(e)}
                                height='400px'
                                value={this.state.address}
                                // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                zoom={15}
                              />
                        </div>
                    </div>
                  </div>
  
                </div>
  
              </div>
            </div>
  
            <div className='col-xl-8 order-xl-1'>
              <div className='card'>
                <div className='card-body'>
                  <form>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Country</label>

                            <select
                              value={this.state.country}
                              // type="select"
                              // className="form-control"
                              onChange={(e) => this.onChangeAddName('country', e.target.value)}
                            >
                              <option value="">Country Names</option>
                              {this.state.country_list.map((option) => (
                                <option value={option.sortname}>{option.name}</option>
                              ))}
                            </select>
                            
                            <span style={{ color: 'red' }}>{this.state.formErrcountry}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-email'>State Name</label>
                            <select
                              value={this.state.city}
                              onChange={(e) => this.onChangeAddName('city', e.target.value)}
                            >
                              <option value="">State Names</option>
                              {worldMapData.getAllStatesFromCountry(this.state.country_name).map((option) => (
                                <option value={option.sortname}>{option.name}</option>
                              ))}
                            </select>
                            <span style={{ color: 'red' }}>{this.state.formErrcity}</span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>City Name</label>
                            <input type='text' id='input-first-name' className='form-control' placeholder='City Name' 
                            onChange={(e) => this.onChangeAddName('address', e.target.value)}
                            value={this.state.address}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcity}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-last-name'>Radius(in meters)</label>
                            <input type="number" className='form-control' min="1" onChange={(e) => this.onChangeAddName('radius', e.target.value)} />
                            <span style={{ color: 'red' }}>{this.state.formErrradius}</span>
                          </div>
                        </div>
                      </div>
  
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Cancellation</label>
                            <input type='number' min="1" id='input-first-name' className='form-control' placeholder='10%' value={this.state.cancellation_fees}
                             onChange={(e) => this.onChangeAddName('cancellation_fees', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcancellation_fees}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tip</label>
                            <input type='number' min="1" max="100" id='input-first-name' className='form-control' placeholder='10%' value={this.state.tip}
                            onChange={(e) => this.onChangeAddName('tip', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrtip}</span>
                          </div>
                        </div>
                      </div>
  
                      <div className='row'>
                        <div className='col-6'>
                          <h4>Cash and Cheque</h4>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>$0 - $99.99</label>
                            <input type='number' min="1" max="100" id='input-first-name' className='form-control' placeholder='' value={this.state.checkque_limit_min} 
                            onChange={(e) => this.onChangeAddName('checkque_limit_min', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcheckque_limit_min}</span>
                          </div>
                        </div>
                        <div className='col-6'>
                          <h4>&nbsp;</h4>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>$99.99 - Above</label>
                            <input type='number' min="1" id='input-first-name' className='form-control' placeholder='' value={this.state.checkque_limit_max} onChange={(e) => this.onChangeAddName('checkque_limit_max', e.target.value)}
                            />

                            <span style={{ color: 'red' }}>{this.state.formErrcheckque_limit_max}</span>
                          </div>
                        </div>
                      </div>
  
                    </div>
  
                    <button className='btn btn-success float-right' type='button' 
                    onClick={(e) => this.handleSubmit()}
                    >Save</button>
                    <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
  
      </div>
    )
  }
  
}
const mapPropsToState = (state) => {
  return {
    success: state.pages.success,
    error: state.pages.error,
    contact: state.pages.contact,
    countrylisting: state.country.countrylisting,
    citylisting: state.city.citylisting

  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchcountry: () => dispatch(actions.fetchcountrysListAction()),
    addcitys: (data,history) => dispatch(actions.addcitysAction(data,history)),

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(AddCity));
