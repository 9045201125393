import React, { Component } from 'react'
import {
    BrowserRouter as Router,
    NavLink,
    Link,
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { Box } from '@material-ui/core';
import { withSnackbar } from "notistack";

const Data = [
    {
        img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
        label: "Case Study",
        title: "How Zoom Championed The WFH World With Grammarly Business",
        date: "October 17, 2022"
    },
    {
        img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
        label: "Blog Post",
        title: "What Leaders Should Learn from Adam Grant: 5 Ways to Empower Successful Teams",
        date: "October 18, 2022"
    },
    {
        img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
        label: "Blog Post",
        title: "The Top 10 Takeaways From Rewriting the Future of Work With Adam Grant",
        date: "October 19, 2022"
    },
    {
        img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
        label: "Blog Post",
        title: "The Ultimate Guide to Team Communication",
        date: "October 20, 2022"
    },
    {
        img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
        label: "Blog Post",
        title: "7 Ways to Avoid the Hidden Costs of Haphazard Onboarding",
        date: "October 20, 2022"
    },
    {
        img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
        label: "Blog Post",
        title: "Achieve Business Results Faster With Consistent On-Brand Communication",
        date: "October 22, 2022"
    },
];

class BlogList extends Component {

    componentDidMount() {
        this.props.getBlogList()
    }
    render() {
        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Blog Listing</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-header border-0'>
                                    <div className='text-right'>
                                        <Link class='btn btn-primary' to='/create-blog'><i class="fas fa-plus"></i> Create new blog</Link>
                                    </div>
                                    <div className='row py-4'>
                                        {this.props.blogList.map((item) => (
                                            <div className='col-md-6 col-lg-4 my-3' key={item}>
                                                <Link to={"/blog-details/" + item._id} className="blog-post">
                                                    <div className="img-wrapper">
                                                        {item.blog_video ?
                                                            <iframe className="blog-image" src={item.blog_video} frameborder="0" allow="accelerometer; encrypted-media; picture-in-picture" allowfullscreen></iframe>
                                                            :
                                                            <img className="blog-image" src={item.blog_picture} alt="" />
                                                        }
                                                    </div>
                                                    <div className="blog-label">Blog Post</div>
                                                    <div className="blog-title">{item.blog_title}</div>
                                                    <div className="blog-date">{new Date(item.createdAt).toDateString()}</div>
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.blog.success,
        error: state.blog.error,
        blogList: state.blog.blogList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBlogList: () => dispatch(actions.getBlogList()),
    };
};

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(BlogList));
