import React from 'react'
import 'react-day-picker/lib/style.css'

import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { Input } from 'reactstrap';

class AddManageProfile extends React.Component {
  constructor(props){
    super(props)
    this.state={
      name : '',
      formErrname : '',
      email:'',
      formErremail:'',
      mobile_no:'',
      formErrmobile_no:'',
      is_active:true,
      formErris_active:true,
      manage_professionals:false,
      manage_business_user:false,
      manage_order:false,
      manage_report:false,
      manage_customer:false,
      adminUserId:''
    }
    this.updateState = this.updateState.bind(this);

  }
  async updateState(data) {
    setTimeout(async () => {
      console.log('Our data is fetched');
      await this.setState(data)
    }, 1000)
  }
  componentDidMount = async () => {
    
    let adminUserId = localStorage.getItem("userId");
    console.log(this.props.match,"match dataadminuser");

    if (adminUserId) {
      // console.log(adminUserId);
      await this.setState({
        adminUserId: adminUserId,
      });
      await this.props.fetchAdminUserByIdAction(adminUserId);
      const getData = await this.props.dataadminuser;
      this.props.fetchAdminUserByIdAction(adminUserId);
      this.updateState(
        {
          name : getData.name,
          email:getData.email,
          mobile_no:getData.mobile_no,
          is_active:getData.is_active,
          manage_professionals:(getData.capabilities)?getData.capabilities.manage_professionals:'',
          manage_business_user:(getData.capabilities)?getData.capabilities.manage_business_user:'',
          manage_order:(getData.capabilities)?getData.capabilities.manage_order:'',
          manage_report:(getData.capabilities)?getData.capabilities.manage_report:'',
          manage_customer:(getData.capabilities)?getData.capabilities.manage_customer:'',
        }
      );
    }
  };
  handleSubmit = async () => {
    // console.log(this.state)
    await this.setState({
      formErrname: (this.state.name === '') ? "Please provide this field" : '',
      // formErrlast_name: (this.state.last_name == '') ? "Please provide this field" : '',
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      // formErrbussinessName: (this.state.bussinessName === '') ? "Please provide this field" : '',
      formErrmobile_no: (this.state.mobile_no === '') ? "Please provide this field" : '',
      // formErrsubCategory: (this.state.subCategory === '') ? "Please provide this field" : '',
      // formErris_active: (this.state.is_active === '') ? "Please provide this field" : '',
      
    })

    if (!((this.state.name === '') && (this.state.email === '') && (this.state.mobile_no === ''))){
      if(this.state.adminUserId){

        const data = {
          name:this.state.name,
          email:this.state.email,
          // bussinessName:this.state.bussinessName,
          mobile_no:this.state.mobile_no,
          // subCategory:this.state.subCategory,
          is_active:this.state.is_active,
          capabilities:JSON.stringify({
            manage_professionals:this.state.manage_professionals,
            manage_business_user:this.state.manage_business_user,
            manage_order:this.state.manage_order,
            manage_report:this.state.manage_report,
            manage_customer:this.state.manage_customer,
          })
        }
        // console.log(data)
        this.props.editAdminUser(this.state.adminUserId,data,this.props.history)
  
      }
      
    }
  }
  onChangeAddName = async (key, value) => {
    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: value === '' ? 'Please provide this field' : '',
    });
  };
  render () {
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Manage Admin User</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card'>
                <div className='card-body'>
                  <form>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-8'>
                          <div className='row'>
                            <div className='col-lg-6'>
                              <div className='form-group'>
                                <label className='form-control-label' for='input-username'>First Name</label>
                                <input type='text' id='input-username' className='form-control' placeholder='First Name' value={this.state.name} onChange={(e)=>this.onChangeAddName('name',e.target.value)} />
                                <span style={{color:'red'}}>{this.state.formErrname}</span>
                              </div>
                              <div className='form-group'>
                                <label className='form-control-label' for='input-username'>Email</label>
                                <input type='text' id='input-username' className='form-control' placeholder='Email' value={this.state.email} onChange={(e)=>this.onChangeAddName('email',e.target.value)} />
                                <span style={{color:'red'}}>{this.state.formErremail}</span>
                              </div>
                              <div className='form-group'>
                                <label className='form-control-label' for='input-username'>Phone Number</label>
                                <input type='text' id='input-username' className='form-control' placeholder='Phone Number' value={this.state.mobile_no} onChange={(e)=>this.onChangeAddName('mobile_no',e.target.value)}/>
                                <span style={{ color: 'red' }}>{this.state.formErrmobile_no}</span>
                              </div>
                              <div className='form-group'>
                                <label className='form-control-label' for='input-username'>Status</label>
                                <Input
                                  value={this.state.is_active}
                                  type='select'
                                  onChange={(e) =>
                                    this.onChangeAddName(
                                      'is_active',
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value={true}>Active</option>
                                  <option value={false}>In Active</option>
                                  
                                </Input>
                                <span style={{ color: 'red' }}>
                                  {this.state.formErris_active}
                                </span>
                                
                              </div>
                              <button className='btn btn-success float-right' type='button'
                              // onClick={(e) => this.handleSubmit()}
                              >Save</button>
                              <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                            </div>

                            <div className='col-lg-5 offset-1'>
                            <div className='form-group'>
                                <label className='form-control-label' for='input-username'>Capabilities</label>
                                <div class='custom-control custom-checkbox mb-2'>
                                  <input type='checkbox' class='custom-control-input' id='customCheck1'checked={this.state.manage_professionals === true}
                                  onChange={(e) =>
                                    this.onChangeAddName('manage_professionals', !this.state.manage_professionals)
                                  } />
                                  <label class='custom-control-label' for='customCheck1'>Manage Professional</label>
                                </div>
                                <div class='custom-control custom-checkbox mb-2'>
                                  <input type='checkbox' class='custom-control-input' id='customCheck2'
                                  checked={this.state.manage_business_user === true}
                                  onChange={(e) =>
                                    this.onChangeAddName('manage_business_user', !this.state.manage_business_user)
                                  }
                                  />
                                  <label class='custom-control-label' for='customCheck2'>Manage Business Owner</label>
                                </div>
                                <div class='custom-control custom-checkbox mb-2'>
                                  <input type='checkbox' class='custom-control-input' id='customCheck3'
                                  checked={this.state.manage_order === true}
                                  onChange={(e) =>
                                    this.onChangeAddName('manage_order', !this.state.manage_order)
                                  }
                                  />
                                  <label class='custom-control-label' for='customCheck3'>Manage Order</label>
                                </div>
                                <div class='custom-control custom-checkbox mb-2'>
                                  <input type='checkbox' class='custom-control-input' id='customCheck4'
                                  checked={this.state.manage_report === true}
                                  onChange={(e) =>
                                    this.onChangeAddName('manage_report', !this.state.manage_report)
                                  }
                                  />
                                  <label class='custom-control-label' for='customCheck4'>Report</label>
                                </div>
                                <div class='custom-control custom-checkbox mb-2'>
                                  <input type='checkbox' class='custom-control-input' id='customCheck5' 
                                  checked={this.state.manage_customer === true}
                                  onChange={(e) =>
                                    this.onChangeAddName('manage_customer', !this.state.manage_customer)
                                  }/>
                                  <label class='custom-control-label' for='customCheck5'>Customer</label>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>

      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    success: state.adminUser.success,
    error: state.adminUser.error,
    adminUserListing: state.adminUser.adminUserListing,
    bussinesslisting: state.bussiness.bussinesslisting,
    alllisting: state.subCategory.alllisting,
    dataadminuser: state.adminUser.dataadminuser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminUserList: () => dispatch(actions.fetchAdminUserListAction()),
    editAdminUser: (data,is_active) => dispatch(actions.editAdminUserAction(data,is_active)),
    deleteAdminUser: (data) => dispatch(actions.deleteAdminUserAction(data)),
    fetchbussiness: () => dispatch(actions.fetchbussinessListAction()),
    fetchallsubCategory: () => dispatch(actions.fetchallsubCategoryListAction()),
    addAdminUser: (data,history) => dispatch(actions.addAdminUserAction(data,history)),
    editAdminUser: (adminUserId,data,history) => dispatch(actions.editAdminUserAction(adminUserId,data,history)),
    fetchAdminUserByIdAction: (adminById) => dispatch(actions.fetchAdminUserById(adminById)),

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(AddManageProfile));
