import React from 'react'
import {
  BrowserRouter as Router,
  Link,
  NavLink
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import moment from 'moment';
import {
  Input,
} from 'reactstrap'

class OrdersHistory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: moment(new Date()).format('D/MMMM/YY'),
      end: '',
      set_data:"0"
    }}
  componentWillMount() {
    this.props.fetchOrderList({});
  }
  changeActiveStatus(id,data) {
    
    if(id){
      this.props.updateOrderList(id,data);
    }
  }
  onChangeValue = async (value) => {
    this.setState({ set_data: value })
    console.log(this.state.set_data)
  }
  async getDate() {
    var date = await new Date(document.getElementById("from").value);//new Date($('#from').val());
    var date1 = await new Date(document.getElementById("to").value);//new Date($('#from').val());
    await this.setState({
      start:date,
      end:date1
    })
    await this.props.fetchOrderList({
      start:date,
      end:date1
    })
  }
  render(){

    var sno = 1

    let orderList = [];
    let orderTable = <Spinner />;
    
    {this.props.orderListing.length > 0?
      this.props.orderListing.map(datas=>{
          if(this.state.set_data == "1" && datas.status === 'cancel' && datas.status_updated_by_user_type == 'user'){
            
          orderList.push(
            {
          
              s_no:(sno++),
              OrderId:datas._id,
              service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
              service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
              CustomerName:datas.user_id[0].name,
              bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
              CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
              ProfessionalName:datas.professional_id[0].name,
              ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
              BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
              Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
              SlotTime:datas._id,
              GrandTotal:datas.total_Ammount,
              PaymentMethod:datas.payment_method,
              OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
              order_type:datas.order_type,
              total_service_charge:datas.total_service_charge,
              item_total:datas.item_total,
              total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
              waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
              promocode_Discount:datas.promocode_Discount,
              happy_hour_discount:datas.happy_hour_discount,
              total_item:datas.product.length,
              product_tax:datas.product_tax,
              service_tax:datas.service_tax,
              service_fee:datas.service_fee,
              tip_Ammount:datas.tip_Ammount,
              processing_fee:datas.processing_fee,
              bussiness_owner_money:datas.bussiness_owner_money,
              servicehubConnetFees:datas.servicehubConnetFees,
              action:<div>
                {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                  <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                  <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                </NavLink>}
            </div>
          
            }
          ) 
          }
          else if(this.state.set_data == "2" && datas.status === 'cancel' && datas.status_updated_by_user_type == 'professional'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          else if(this.state.set_data == "3" && datas.status === 'cancel' && datas.status_updated_by_user_type == 'admin'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          
          else if(this.state.set_data == "4" && datas.status === 'reject' && datas.status_updated_by_user_type == 'professional'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          
          else if(this.state.set_data == "5" && datas.status === 'reject' && datas.status_updated_by_user_type == 'admin'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          
          else if(this.state.set_data == "6" && datas.status === 'completed' && datas.status_updated_by_user_type == 'user'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          
          else if(this.state.set_data == "7" && datas.status === 'completed' && datas.status_updated_by_user_type == 'admin'){
            
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
          else if(this.state.set_data == "0" && (datas.status === 'completed' || datas.status === 'reject' || datas.status === 'cancel') ){
            orderList.push(
              {
            
                s_no:(sno++),
                OrderId:datas._id,
                service_start_time:moment(new Date(datas.service_start_time)).format('D MMMM YYYY HH:mm'),//datas.service_start_time,
                service_end_time:moment(new Date(datas.service_end_time)).format('D MMMM YYYY HH:mm'),//datas.service_end_time,  
                CustomerName:datas.user_id[0].name,
                bussinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                CustomerPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                ProfessionalName:datas.professional_id[0].name,
                ProfessionalPhone:datas.user_id[0].country_code+datas.user_id[0].mobile_no,
                BusinessName:(datas.bussiness_id.length)?datas.bussiness_id[0].name:'',
                Date:moment(new Date(datas.createdAt)).format('D MMMM YYYY HH:mm'),//datas.createdAt,
                SlotTime:datas._id,
                GrandTotal:datas.total_Ammount,
                PaymentMethod:datas.payment_method,
                OrderStatus:datas.status+" by "+datas.status_updated_by_user_type,
                order_type:datas.order_type,
                total_service_charge:datas.total_service_charge,
                item_total:datas.item_total,
                total_product_service:(datas.total_service_charge === undefined)?"":datas.total_service_charge+(datas.item_total === undefined)?"":datas.item_total,
                waiting_time:(datas.waiting_time)?moment(new Date(datas.waiting_time)).format('D MMMM YYYY HH:mm'):'',//datas.waiting_time,
                promocode_Discount:datas.promocode_Discount,
                happy_hour_discount:datas.happy_hour_discount,
                total_item:datas.product.length,
                product_tax:datas.product_tax,
                service_tax:datas.service_tax,
                service_fee:datas.service_fee,
                tip_Ammount:datas.tip_Ammount,
                processing_fee:datas.processing_fee,
                bussiness_owner_money:datas.bussiness_owner_money,
                servicehubConnetFees:datas.servicehubConnetFees,
                action:<div>
                  {datas.order_type === 'productAndService'?<NavLink className='btn btn-icon btn-default' to={'/getorderdetails/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>:<NavLink className='btn btn-icon btn-default' to={'/getorderdetailsRideShareDelivery/'+datas._id} >
                    <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
                  </NavLink>}
              </div>
            
              }
            ) 
          }
        
        
      })
      
      :orderList.push(
        ["No record"]
        )}
        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Order Id",
              field: "OrderId",
              sort: "asc",
            },
            {
              label: "Ordered Date",
              field: "Date",
              sort: "asc",
            },
            {
              label: "Start time",
              field: "service_start_time",
              sort: "asc",
            },
            {
              label: "End time",
              field: "service_end_time",
              sort: "asc",
            },
            {
              label: "Customer Name",
              field: "CustomerName",
              sort: "asc",
            },
            {
              label: "Professional Name",
              field: "ProfessionalName",
              sort: "asc",
            },
            {
              label: "Business Name",
              field: "BusinessName",
              sort: "asc",
            },
            {
              label: "Total service",
              field: "total_service_charge",
              sort: "asc",
            },
            {
              label: "Total Product",
              field: "item_total",
              sort: "asc",
            },
            {
              label: "Total Product and service",
              field: "total_product_service",
              sort: "asc",
            },
            {
              label: "Waiting Time",
              field: "waiting_time",
              sort: "asc",
            },
            {
              label: "Promo Code Discount",
              field: "promocode_Discount",
              sort: "asc",
            },
            {
              label: "Happy hour discount",
              field: "happy_hour_discount",
              sort: "asc",
            },
            {
              label: "Total Item",
              field: "total_item",
              sort: "asc",
            },
            {
              label: "Product Tax",
              field: "product_tax",
              sort: "asc",
            },
            {
              label: "Service Tax",
              field: "service_tax",
              sort: "asc",
            },
            {
              label: "Tip",
              field: "tip_Ammount",
              sort: "asc",
            },
            {
              label: "Gross Total",
              field: "GrandTotal",
              sort: "asc",
            },
            {
              label: "Service hub connect fees",
              field: "servicehubConnetFees",
              sort: "asc",
            },
            {
              label: "Business owner money",
              field: "bussiness_owner_money",
              sort: "asc",
            },
            {
              label: "Service Fees",
              field: "service_fee",
              sort: "asc",
            },
            {
              label: "Processing fees",
              field: "processing_fee",
              sort: "asc",
            },
            {
              label: "Order Status Upcoming Service",
              field: "OrderStatus",
              sort: "asc",
            },
            {
              label: "Service Status",
              field: "order_type",
              sort: "asc",
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
            },
            
          ],
          rows: orderList,
        };
    
        orderTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );
  
  return (
    <div>
      <div className='header bg-primary pb-6'>
        <div className='container-fluid'>
          <div className='header-body'>
            <div className='row align-items-center py-4'>
              <div className='col-lg-6 col-7'>
                <h6 className='h2 text-white d-inline-block mb-0'>Orders</h6>
              </div>
            </div>

            <ul className='nav nav-pills nav-fill flex-column flex-sm-row' id='tabs-text' role='tablist'>
              <li className='nav-item'>
                <Link to='/orders' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-1-tab' data-toggle='tab' href='#tabs-text-1' role='tab' aria-controls='tabs-text-1' aria-selected='true'>New Service</Link>
              </li>
              <li className='nav-item'>
                <Link to='/currentservice' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Current Service</Link>
              </li>
              <li className='nav-item'>
                <Link to='/orderhistory' className='nav-link mb-sm-3 mb-md-0 active' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Service History</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='container-fluid mt--6'>
        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-header'>
                <div className='row align-items-center'>
                  <div className='col-3'>
                    <h3 className='mb-0'>Service History</h3>
                  </div>
                  <div className='col-lg-9'>
                    <div className='row'>
                      <div className='col form-inline'>
                        <div className='form-group'>
                          <label className='form-control-label mr-2' for='from'>From</label>
                          <input 
                          type='date' id='from' className='form-control' />
                        </div>
                      </div>
                      <div className='col form-inline'>
                        <div className='form-group'>
                          <label className='form-control-label mr-2' for='to'>To Date</label>
                          <input type='date' id='to' max={this.state.start} className='form-control' />
                        </div>
                      </div>
                      <div className='col form-inline'>
                        <div className='form-group'>
                          <button
                            className='btn btn-success float-right'
                            type='button'
                            onClick={(e) => this.getDate()}
                          >Get Data</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Countries</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div>
                  <div className='col-lg-2'>
                    <select className='form-control' id='exampleFormControlSelect1'>
                      <option>All Cities</option>
                      <option>Deactive</option>
                      <option>All</option>
                    </select>
                  </div> */}
                  <div className='col-lg-2'>
                    {/* <select className='form-control' id='exampleFormControlSelect1'> */}
                    <Input
                                value={this.state.set_data}
                          type="select"
                          onChange={(e) => this.onChangeValue(e.target.value)}
                        >
                      <option value = "0">All</option> 
                      <option value = "1">Canceled by customer</option> 
                      <option value = "2">Canceled by professional</option>
                      <option value = "3">Canceled by admin</option>
                      <option value = "4">Rejected by professional</option>
                      <option value = "5">Rejected by admin</option>
                      <option value = "6">Completed by customer</option>
                      <option value = "7">Completed by admin</option>
                    </Input>
                  </div>
                </div>
              </div>

              <div className='card-body'>

                <div className='table-responsive'>
                  <table className='table align-items-center table-flush'>
                    {orderTable}
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <footer className='footer pt-0'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6'>
              <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
}
const mapPropsToState = (state) => {
  return {
    success: state.order.success,
    error: state.order.error,
    orderListing: state.order.orderListing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrderList: (data) => dispatch(actions.fetchOrderListAction(data)),
    updateOrderList: (orderId,data) => dispatch(actions.updateOrderListAction(orderId,data)),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(OrdersHistory));
