import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startSubcategory = () => {
  return {
    type: actionTypes.START_SUBCATEGORY
  };
};

export const subcategoryListFail = message => {
  return {
    type: actionTypes.GET_SUBCATEGORY_LIST_FAIL,
    message
  };
};

export const getSubcategorySuccess = (data) => {
    return {
      type: actionTypes.GET_SUBCATEGORY_LIST_SUCCCES,
      subcategoryList: data,
    };
  };
export const progressBar = (progress) => {
    return {
      type: actionTypes.PROGRESS_BAR,
      progress: progress,
    };
  };

export const getSubcategoryList = (data) => {
    return dispatch => {
      dispatch(startSubcategory());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/get-subcategory" ,{businessId:data}, {
          headers: { Authorization: authCode }
        })
        .then(response => {
            
          if (response.status === 200) {
            if (response.data.status === 200) {
                
                dispatch(getSubcategorySuccess(response.data.data,response.data.message))
              
              // dispatch(fetchprofessionalListSUCCESS(response.data.data,"Professional deleted sucessfully"));
            } else {
              dispatch(subcategoryListFail(response.data.message));
            }
          } else {
            dispatch(subcategoryListFail(response.message));
          }
        })
        .catch(err => {
          dispatch(subcategoryListFail(err.message));
        });
    };
  };


  //uploading pictures
  export const startAdvertisementPicture = () => {
    return {
      type: actionTypes.START_ADVERTISEMENT_PICTURE
    };
  };
  export const AdvertisementPictureFail = message => {
    return {
      type: actionTypes.ADVERTISEMENT_PICTURE_FAIL,
      message
    };
  };
  
  export const getAdvertismentPictureSuccess = (data) => {
      return {
        type: actionTypes.GET_ADVERTISEMENT_PICTURE_SUCCESS,
        advertisementPicture: data,
      };
  };
  export const getAdvertisementPictureOnId = (data) => {
    return dispatch => {
      dispatch(startAdvertisementPicture());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/get-ad-picture" ,{businessId:data}, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          console.log(response,'what is response')
          if (response.status === 200) {
            if (response.data.status === 200) { 
              
              dispatch(getAdvertismentPictureSuccess(response.data.data))
            } else {
              dispatch(AdvertisementPictureFail(response.data.message));
            }
          } else {
            dispatch(AdvertisementPictureFail(response.message));
          }
        })
        .catch(err => {
          dispatch(AdvertisementPictureFail(err.message));
        });
    };
  };


  export const getAdvertisementPictureUploadSucess = (data,message) => {
    return {
      type: actionTypes.ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS,
      advertisementPicture: data,
      message
    };
  };

  export const advertisementPictureUpload = (data) => {
    
    return dispatch => {
      dispatch(startAdvertisementPicture());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/upload-ad-picture" , data, {
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getAdvertisementPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(AdvertisementPictureFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(AdvertisementPictureFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(AdvertisementPictureFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };

  export const getAdvertisementPictureDeleteSuccess = (data,message) => {
    return {
      type: actionTypes.ADVERTISEMENT_PICTURE_DELETE_SUCCESS,
      advertisementPicture: data,
      message
    };
  };

  export const advertisementPictureDelete = (data) => {
    return dispatch => {
      dispatch(startAdvertisementPicture());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-ad-picture" , data, {
          headers: { Authorization: authCode },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getAdvertisementPictureDeleteSuccess(response.data.data,response.data.message))
            } else {
              dispatch(AdvertisementPictureFail(response.data.message));
            }
          } else {
            dispatch(AdvertisementPictureFail(response.message));
          }
        })
        .catch(err => {
          dispatch(AdvertisementPictureFail(err.message));
        });
    };
  };

  //videos actions

  export const startAdvertisementVideo = () => {
    return {
      type: actionTypes.START_ADVERTISEMENT_VIDEO
    };
  };

  export const AdvertisementVideoFail = message => {
    return {
      type: actionTypes.ADVERTISEMENT_VIDEO_FAIL,
      message
    };
  };

  export const getAdvertismentVideoSuccess = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_VIDEO_SUCCESS,
      advertisementVideo: data,
    };
};

export const getAdvertisementVideoOnId = (data) => {
  return dispatch => {
    dispatch(startAdvertisementVideo());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-ad-video" ,{businessId:data}, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) { 
            
            dispatch(getAdvertismentVideoSuccess(response.data.data))
          } else {
            dispatch(AdvertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(AdvertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(AdvertisementVideoFail(err.message));
      });
  };
};


export const getAdvertisementVideoUploadSucess = (data,message) => {
  return {
    type: actionTypes.ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS,
    advertisementVideo: data,
    message
  };
};

export const advertisementVideoUpload = (data) => {
    
  return dispatch => {
    dispatch(startAdvertisementVideo());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/upload-ad-video" , data, {
        headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          let progress = Math.round((100 * data.loaded) / data.total)
          dispatch(progressBar(progress))
        },
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) { 
            dispatch(getAdvertisementVideoUploadSucess(response.data.data,response.data.message))
            dispatch(progressBar(0))
          } else {
            dispatch(AdvertisementVideoFail(response.data.message));
            dispatch(progressBar(0))
          }
        } else {
          dispatch(AdvertisementVideoFail(response.message));
          dispatch(progressBar(0))
        }
      })
      .catch(err => {
        dispatch(AdvertisementVideoFail(err.message));
        dispatch(progressBar(0))
      });
  };
};


export const getAdvertisementVideoDeleteSuccess = (data,message) => {
  return {
    type: actionTypes.ADVERTISEMENT_VIDEO_DELETE_SUCCESS,
    advertisementVideo: data,
    message
  };
};

export const advertisementVideoDelete = (data) => {
  return dispatch => {
    dispatch(startAdvertisementVideo());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/delete-ad-video" , data, {
        headers: { Authorization: authCode },
        
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) { 
            dispatch(getAdvertisementVideoDeleteSuccess(response.data.data,response.data.message))
          } else {
            dispatch(AdvertisementVideoFail(response.data.message));
          }
        } else {
          dispatch(AdvertisementVideoFail(response.message));
        }
      })
      .catch(err => {
        dispatch(AdvertisementVideoFail(err.message));
      });
  };
};

