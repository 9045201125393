import React from 'react'
import {
    BrowserRouter as Router,
    NavLink,
    Link,
} from 'react-router-dom'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import MUIDataTable from 'mui-datatables'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from 'reactstrap'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import moment from 'moment-timezone'

class SubscribedBusinessUserList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryName: '',
            open: false,
            subscribedplanId: '',
            openDialgue: false,
        }
    }
    componentWillMount() {
        this.props.advertiseSubscribedUserList()
    }


    closeDialgue() {
        this.setState({ openDialgue: false, subscribedplanId: '' });
    }

    handleOpenDelete = (id) => {
        if (id) {
            this.setState({
                subscribedplanId: id,
                openDialgue: true
            });
        }
    }
    deleteUserPermanently() {
        if (this.state.subscribedplanId) {
            let data = {planId:this.state.subscribedplanId}
            this.props.deactivateBusinessUserPlan(data);
            this.closeDialgue()
            this.setState({ subscribedplanId: '' });
        }
    }


    open() {
        this.setState({ openDialgue: true });
    }

    render() {
        // console.log(this.props.subscribedUserList, '=======user list')
        const { open } = this.state;
        var sno = 1
        let advSubscribedUserList = [];
        let subscribedUserTable = <Spinner />;
        {
            this.props.subscribedUserList.length > 0
                ? this.props.subscribedUserList.map(datas => {
                    advSubscribedUserList.push(
                        {
                            s_no: (sno++),
                            owner_name: datas.business_owner_name,
                            name: datas.name,
                            email: datas.email,
                            phone: datas.country_code + datas.mobile_no,
                            subscriptionType: <div className='d-flex'>
                                {
                                    datas.onetimePlanInfo ? <div>
                                      {/* {console.log(datas.onetimePlanInfo,'========')} */}
                                        <a className="nav-link" data-toggle="modal" data-target={`#onetime_${datas._id}`}>
                                            <Button className='btn btn-icon btn-success btn-sm'>
                                                OneTime
                                            </Button>
                                        </a>
                                        <div className="modal fade" id={`onetime_${datas._id}`} aria-labelledby={`onetime_${datas._id}`} tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-bottom"><h2 className="m-0 text-capitalize">{datas.name} Plan Details</h2></div>
                                                    <div className="modal-body" style={{ whiteSpace: "normal" }}>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Description:</strong>{datas.onetimePlanInfo.planDescription}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Purchase On: </strong>{new Date(`${datas.onetimePlanInfo.plan_purchase_date}`).toDateString()}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Expire On: </strong> Unlimited</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Type: </strong>{datas.onetimePlanInfo.plan_type.toUpperCase()}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Days: </strong>{datas.onetimePlanInfo.planDay}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Price: </strong>$ {datas.onetimePlanInfo.price}</div>
                                                    </div>
                                                    <div className="modal-footer border-top">
                                                        <button type="button" className="btn btn-danger" onClick={() => this.handleOpenDelete(datas.onetimePlanInfo._id)}>
                                                            Deactivate
                                                        </button>
                                                        <button type="button" className="btn btn-primary" data-dismiss="modal">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''}
                                {
                                    datas.monthlyYearlyPlanInfo ? <div>
                                        <a className="nav-link" data-toggle="modal" data-target={`#monthly${datas._id}`}>
                                            <Button className='btn btn-icon btn-success btn-sm'>
                                                {datas.monthlyYearlyPlanInfo.plan_type}
                                            </Button>
                                        </a>
                                        <div className="modal fade" id={`monthly${datas._id}`} aria-labelledby="{`monthly${datas._id}`" tabIndex="-1" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-bottom"><h2 className="m-0 text-capitalize">{datas.name} Plan Details</h2></div>
                                                    <div className="modal-body" style={{ whiteSpace: "normal" }}>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Description:</strong>{datas.monthlyYearlyPlanInfo.planDescription}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Purchase On: </strong>{new Date(datas.monthlyYearlyPlanInfo.plan_purchase_date).toDateString()}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Expire On: </strong>{new Date(datas.monthlyYearlyPlanInfo.plan_expire_date).toDateString()}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Type: </strong>{datas.monthlyYearlyPlanInfo.plan_type.toUpperCase()}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Plan Days: </strong>{datas.monthlyYearlyPlanInfo.planDay}</div>
                                                        <div className='d-flex flex-wrap my-2'><strong className='mr-2'>Price: </strong>$ {datas.monthlyYearlyPlanInfo.price}</div>
                                                    </div>
                                                    <div className="modal-footer border-top">
                                                        <button type="button" className="btn btn-danger" onClick={() => this.handleOpenDelete(datas.monthlyYearlyPlanInfo._id)}>
                                                            Deactivate
                                                        </button>
                                                        <button type="button" className="btn btn-primary" data-dismiss="modal">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ''
                                }</div>



                        }
                    )
                })
                : advSubscribedUserList.push(
                    ['No record']
                )
        }
        const data = {
            columns: [
                {
                    label: "Sr.No",
                    field: "s_no",
                    sort: "asc",
                },
                {
                    label: "Business Owner Name",
                    field: "owner_name",
                    sort: "asc",
                },
                {
                    label: "Business Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Email",
                    field: "email",
                    sort: "asc",
                },
                {
                    label: "Mobile No.",
                    field: "phone",
                    sort: "asc",
                },
                {
                    label: "Types of Subscription Plan",
                    field: "subscriptionType",
                    sort: "asc",
                },
            ],
            rows: advSubscribedUserList,
        };

        subscribedUserTable = (
            <MDBDataTable striped bordered hover entries={10} data={data} />
        );


        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Subscribed Business User List</h6>
                                </div>
                            </div>
                        </div>
                        <Dialog open={this.state.openDialgue} onClose={() => this.closeDialgue()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Do you want to delete this plan?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    After delete you can not retrieve plan.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.closeDialgue()}>
                                    No
                                </button>
                                <button class='btn btn-icon btn-success btn-sm' onClick={() => this.deleteUserPermanently()} type='button'>
                                    Yes
                                </button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-header border-0'>
                                    <div className='table-responsive'>
                                        {subscribedUserTable}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}
const mapPropsToState = (state) => {
    return {
        subscribedUserList: state.advertiseSubscription.subscribedUserList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        advertiseSubscribedUserList: () => dispatch(actions.advertiseSubscribedUserList()),
        deactivateBusinessUserPlan: (data) => dispatch(actions.deactivateBusinessUserPlan(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(SubscribedBusinessUserList))
