export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SHOW_ALERT_NOTIFICATION = "SHOW_ALERT_NOTIFICATION";

//professional 
export const START_PROFESSIONAL_ACTION = "START_PROFESSIONAL_ACTION";
export const PROFESSIONAL_ACTION_FAIL = "PROFESSIONAL_ACTION_FAIL";
export const FETCH_PROFESSIONAL_LIST_SUCCESS = "FETCH_PROFESSIONAL_LIST_SUCCESS";
export const FETCH_PROFESSIONAL_DATA_SUCCESS = "FETCH_PROFESSIONAL_DATA_SUCCESS";
//bussiness

export const START_BUSSINESS_ACTION = "START_BUSSINESS_ACTION";
export const BUSSINESS_ACTION_FAIL = "BUSSINESS_ACTION_FAIL";
export const FETCH_BUSSINESS_LIST_SUCCESS = "FETCH_BUSSINESS_LIST_SUCCESS";
export const FETCH_BUSSINESS_DATA_SUCCESS="FETCH_BUSSINESS_DATA_SUCCESS";

//category 
export const START_CATEGORY_ACTION = "START_CATEGORY_ACTION";
export const CATEGORY_ACTION_FAIL = "CATEGORY_ACTION_FAIL";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS"

// sub category 
export const START_SUB_CATEGORY_ACTION = "START_SUB_CATEGORY_ACTION";
export const SUB_CATEGORY_ACTION_FAIL = "SUB_CATEGORY_ACTION_FAIL";
export const FETCH_SUB_CATEGORY_LIST_SUCCESS = "FETCH_SUB_CATEGORY_LIST_SUCCESS"
export const FETCH_ALL_SUB_CATEGORY_LIST_SUCCESS = "FETCH_ALL_SUB_CATEGORY_LIST_SUCCESS"

//sub business 
export const START_SUB_BUSINESS_ACTION = "START_SUB_BUSINESS_ACTION";
export const SUB_BUSINESS_ACTION_FAIL = "SUB_BUSINESS_ACTION_FAIL";
export const FETCH_SUB_BUSINESS_LIST_SUCCESS = "FETCH_SUB_BUSINESS_LIST_SUCCESS"
export const FETCH_SUB_BUSINESS_EXTRA_PRODUCT = "FETCH_SUB_BUSINESS_EXTRA_PRODUCT"
export const FETCH_SUB_BUSINESS_EXTRA_SERVICE = "FETCH_SUB_BUSINESS_EXTRA_SERVICE"
export const START_SUB_BUSINESS_ACTION_EXTRA_PRODUCT = "START_SUB_BUSINESS_ACTION_EXTRA_PRODUCT"
export const START_SUB_BUSINESS_ACTION_EXTRA_SERVICE = "START_SUB_BUSINESS_ACTION_EXTRA_SERVICE"
export const START_SUB_BUSINESS_ACTION_PRODUCT = "START_SUB_BUSINESS_ACTION_PRODUCT"
export const FETCH_SUB_BUSINESS_PRODUCT = "FETCH_SUB_BUSINESS_PRODUCT"
export const START_SUB_BUSINESS_ACTION_SERVICE = "START_SUB_BUSINESS_ACTION_SERVICE"
export const FETCH_SUB_BUSINESS_SERVICE = "FETCH_SUB_BUSINESS_SERVICE"
//country
export const START_COUNTRY_ACTION = "START_COUNTRY_ACTION";
export const COUNTRY_ACTION_FAIL = "COUNTRY_ACTION_FAIL";
export const FETCH_COUNTRY_LIST_SUCCESS = "FETCH_COUNTRY_LIST_SUCCESS";

//city
export const START_CITY_ACTION = "START_CITY_ACTION";
export const CITY_ACTION_FAIL = "CITY_ACTION_FAIL";
export const FETCH_CITY_LIST_SUCCESS = "FETCH_CITY_LIST_SUCCESS";

//CUSTOMER

export const FETCH_CUSTOMER_LIST_SUCCESS = "FETCH_CUSTOMER_LIST_SUCCESS"
export const START_CUSTOMER_ACTION = "START_CUSTOMER_ACTION"
export const CUSTOMER_ACTION_FAIL = "CUSTOMER_ACTION_FAIL"

//pages
export const START_PAGES_ACTION = "START_PAGES_ACTION"
export const PAGES_ACTION_FAIL = "PAGES_ACTION_FAIL"
export const FETCH_PAGES_LIST_SUCCESS = "FETCH_PAGES_LIST_SUCCESS"
export const START_PROMO_CODE = "START_PROMO_CODE"
export const FETCH_PROMO_CODE_LIST_SUCCESS = "FETCH_PROMO_CODE_LIST_SUCCESS"

//contactus
export const CONTACT_ACTION_FAIL = "CONTACT_ACTION_FAIL"
export const START_CONTACT_ACTION = "START_CONTACT_ACTION"
export const FETCH_CONTACT_LIST_SUCCESS = "FETCH_CONTACT_LIST_SUCCESS"

//subscription

export const START_SUBSCRIPTION_ACTION = "START_SUBSCRIPTION_ACTION"
export const SUBSCRIPTION_ACTION_FAIL = "SUBSCRIPTION_ACTION_FAIL"
export const FETCH_SUBSCRIPTION_LIST_SUCCESS = "FETCH_SUBSCRIPTION_LIST_SUCCESS"

//first order discount
export const START_FIRST_ORDER_DISCOUNT_ACTION = "START_FIRST_ORDER_DISCOUNT_ACTION"
export const FIRST_ORDER_DISCOUNT_ACTION_FAIL = "FIRST_ORDER_DISCOUNT_ACTION_FAIL"
export const FETCH_FIRST_ORDER_DISCOUNT_LIST_SUCCESS = "FETCH_FIRST_ORDER_DISCOUNT_LIST_SUCCESS"

//Admin User
export const START_ADMIN_USER_ACTION = "START_ADMIN_USER_ACTION"
export const ADMIN_USER_ACTION_FAIL = "ADMIN_USER_ACTION_FAIL"
export const FETCH_ADMIN_USER_LIST_SUCCESS = "FETCH_ADMIN_USER_LIST_SUCCESS"
export const FETCH_ADMIN_USER_LIST_OBJECT = "FETCH_ADMIN_USER_LIST_OBJECT"

//Order
export const START_ORDER_ACTION = "START_ORDER_ACTION"
export const ORDER_ACTION_FAIL = "ORDER_ACTION_FAIL"
export const FETCH_ORDER_LIST_SUCCESS = "FETCH_ORDER_LIST_SUCCESS"
export const FETCH_ORDER_DATA_DATA_SUCCESS = "FETCH_ORDER_DATA_DATA_SUCCESS"

// report

export const START_REPORT_ACTION = "START_REPORT_ACTION"
export const REPORT_ACTION_FAIL = "REPORT_ACTION_FAIL"
export const FETCH_REPORT_LIST_SUCCESS = "FETCH_REPORT_LIST_SUCCESS"
export const FETCH_REPORT_DATA_DATA_SUCCESS = "FETCH_REPORT_DATA_DATA_SUCCESS"

export const START_PROFESSIONAL_REPORT_ACTION = "START_PROFESSIONAL_REPORT_ACTION"
export const FETCH_PROFESSIONAL_REPORT_LIST_SUCCESS = "FETCH_PROFESSIONAL_REPORT_LIST_SUCCESS"

export const START_CUSTOMER_REPORT_ACTION = "START_CUSTOMER_REPORT_ACTION"
export const FETCH_CUSTOMER_REPORT_LIST_SUCCESS = "FETCH_CUSTOMER_REPORT_LIST_SUCCESS"

export const START_DASHBOARD_ACTION = "START_DASHBOARD_ACTION"
export const FETCH_DASHBOARD_LIST_SUCCESS = "FETCH_DASHBOARD_LIST_SUCCESS"
export const DASHBOARD_ACTION_FAIL = "DASHBOARD_ACTION_FAIL"


export const FETCH_SUBSCRIPTION_LIST_USER_SUCCESS = "FETCH_SUBSCRIPTION_LIST_USER_SUCCESS"
export const START_SUBSCRIPTION_USER_ACTION = "START_SUBSCRIPTION_USER_ACTION"

//get busiiness pictures and videos
export const GET_BUSINESS_MEDIA_SUCCESS = "GET_BUSINESS_MEDIA_SUCCESS";
export const START_BUSSINESS_MEDIA = "START_BUSSINESS_MEDIA";
export const BUSSINESS_MEDIA_FAIL = "BUSSINESS_ACTION_FAIL";
export const BUSSINESS_PICTURE_UPLOAD_SUCCESS = "BUSSINESS_PICTURE_UPLOAD_SUCCESS ";
export const BUSSINESS_PICTURE_UPLOAD_FAIL = "BUSSINESS_PICTURE_UPLOAD_FAIL";
export const BUSSINESS_VIDEO_UPLOAD_SUCCESS = "BUSSINESS_VIDEO_UPLOAD_SUCCESS";
export const BUSSINESS_VIDEO_UPLOAD_FAIL = "BUSSINESS_VIDEO_UPLOAD_FAIL";
export const BUSSINESS_PICTURE_DELETE_SUCCESS = "BUSSINESS_VIDEO_DELETE_SUCCESS";
export const BUSSINESS_VIDEO_DELETE_SUCCESS = "BUSSINESS_VIDEO_DELETE_SUCCESS";
export const PROGRESS_BAR = "PROGRESS_BAR"
export const PICTURE_PROGRESS = "PICTURE_PROGRESS"

// get category pictures and videos

export const GET_CATEGORY_MEDIA_SUCCESS = "GET_CATEGORY_MEDIA_SUCCESS";
export const START_CATEGORY_MEDIA = "START_CATEGORY_MEDIA";
export const CATEGORY_MEDIA_FAIL = "CATEGORY_MEDIA_FAIL";
export const CATEGORY_PICTURE_UPLOAD_SUCCESS = "CATEGORY_PICTURE_UPLOAD_SUCCESS ";
export const CATEGORY_PICTURE_UPLOAD_FAIL = "CATEGORY_PICTURE_UPLOAD_FAIL";
export const CATEGORY_VIDEO_UPLOAD_SUCCESS = "CATEGORY_VIDEO_UPLOAD_SUCCESS";
export const CATEGORY_VIDEO_UPLOAD_FAIL = "CATEGORY_VIDEO_UPLOAD_FAIL";
export const CATEGORY_PICTURE_DELETE_SUCCESS = "CATEGORY_PICTURE_DELETE_SUCCESS";
export const CATEGORY_VIDEO_DELETE_SUCCESS = "CATEGORY_VIDEO_DELETE_SUCCESS";
export const CATEGORY_PROGRESS_BAR = "CATEGORY_PROGRESS_BAR";
export const CATEGORY_PICTURE_PROGRESS = "CATEGORY_PICTURE_PROGRESS"

//Advertisement 
export const START_SUBCATEGORY = 'START_SUBCATEGORY'
export const GET_SUBCATEGORY_LIST_SUCCCES = "GET_SUBCATEGORY_LIST_SUCCCES";
export const GET_SUBCATEGORY_LIST_FAIL = "GET_SUBCATEGORY_LIST_FAIL";
//uploading pictures
export const START_ADVERTISEMENT_PICTURE = "START_CATEGORY_MEDIA";
export const ADVERTISEMENT_PICTURE_FAIL = "ADVERTISEMENT_PICTURE_FAIL";
export const GET_ADVERTISEMENT_PICTURE_SUCCESS = "GET_ADVERTISEMENT_PICTURE_SUCCESS";
export const ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS = "ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS";
export const ADVERTISEMENT_PICTURE_UPLOAD_FAIL = "ADVERTISEMENT_PICTURE_UPLOAD_FAIL";
export const  ADVERTISEMENT_PICTURE_DELETE_SUCCESS = "ADVERTISEMENT_PICTURE_DELETE_SUCCESS"

//uploading videos
export const START_ADVERTISEMENT_VIDEO = "START_ADVERTISEMENT_VIDEO";
export const ADVERTISEMENT_VIDEO_FAIL = "ADVERTISEMENT_VIDEO_FAIL";
export const GET_ADVERTISEMENT_VIDEO_SUCCESS = "GET_ADVERTISEMENT_VIDEO_SUCCESS";
export const ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS = "ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS";
export const ADVERTISEMENT_VIDEO_UPLOAD_FAIL = "ADVERTISEMENT_VIDEO_UPLOAD_FAIL";
export const  ADVERTISEMENT_VIDEO_DELETE_SUCCESS = "ADVERTISEMENT_VIDEO_DELETE_SUCCESS"

// Advertisement subscription
export const START_ADVERTISEMENT_SUBSCRIPTION_PLAN = "START_ADVERTISEMENT_SUBSCRIPTION_PLAN";
export const ADVERTISEMENT_SUBSCRIPTION_PLAN_FAIL = "ADVERTISEMENT_SUBSCRIPTION_PLAN_FAIL";
export const GET_ADVERTISEMENT_SUBSCRIPTION_SUCCESS = "GET_ADVERTISEMENT_SUBSCRIPTION_SUCCESS";
export const ADD_ADVERTISEMENT_SUBSCRIPTION_PLAN = "ADD_ADVERTISEMENT_SUBSCRIPTION_PLAN";
export const GET_ADVERTISEMENT_SUBSCRIBED_USER_LIST = "GET_ADVERTISEMENT_SUBSCRIBED_USER_LIST";
export const GET_ADVERTISEMENT_SUBSCRIPTION_PLAN_DETAILS = "GET_ADVERTISEMENT_SUBSCRIPTION_PLAN_DETAILS";
export const DEACTIVATE_USER_SUBSCRIPTION_PLAN = "DEACTIVATE_USER_SUBSCRIPTION_PLAN";

// blog 
export const START_BLOG = "START_BLOG";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";


//uploading BLOG PRICE ADVERTISE pictures
export const START_BLOGPRICE_ADVERTISEMENT_MEDIA = "START_BLOGPRICE_ADVERTISEMENT_MEDIA";
export const BLOGPRICE_ADVERTISEMENT_MEDIA_FAIL = "BLOGPRICE_ADVERTISEMENT_MEDIA_FAIL";
export const GET_BLOGPRICE_ADVERTISEMENT_PICTURE_SUCCESS = "GET_BLOGPRICE_ADVERTISEMENT_PICTURE_SUCCESS";
export const BLOGPRICE_ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS = "ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS";
export const BLOGPRICE_ADVERTISEMENT_PICTURE_DELETE_SUCCESS = "BLOGPRICE_ADVERTISEMENT_PICTURE_DELETE_SUCCESS";
export const GET_BLOGPRICE_ADVERTISEMENT_VIDEO_SUCCESS = "GET_BLOGPRICE_ADVERTISEMENT_VIDEO_SUCCESS";
export const BLOGPRICE_ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS = "BLOGPRICE_ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS";
export const BLOGPRICE_ADVERTISEMENT_VIDEO_DELETE_SUCCESS = "BLOGPRICE_ADVERTISEMENT_VIDEO_DELETE_SUCCESS";

// coupon rankings 
export const START_COUPON_BUSSINESS_ACTION="START_COUPON_BUSSINESS_ACTION"
export const COUPON_BUSSINESS_ACTION_FAIL="COUPON_BUSSINESS_ACTION_FAIL"
export const EDIT_COUPON_BUSINESS_DETAILS_SUCCESS="EDIT_COUPON_BUSINESS_DETAILS_SUCCESS"
export const GET_COUPONRANKING_BUSINESS_USER = "GET_COUPONRANKING_BUSINESS_USER";
export const GET_SUBSCRIPTION_PLAN_LIST_SUCCESS = "GET_SUBSCRIPTION_PLAN_LIST_SUCCESS"
export const GET_COUPON_BUSINESS_DETAILS_SUCCESS ="GET_COUPON_BUSINESS_DETAILS_SUCCESS"
export const GET_COUPONRANKING_REPORT_LISTING ="GET_COUPONRANKING_REPORT_LISTING"


