import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import {
  Input,
} from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import data from './data.json'
import { withRouter } from "react-router-dom";
import Map from './map'
import worldMapData from 'city-state-country'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class AddBusiness extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      formErrname: '',
      business_owner_name: '',
      formErrbusiness_owner_name: '',
      country: '',
      formErrcountry: '',
      city: '',
      formErrcity: '',
      country_code: '+1',
      formErrcountry_code: '',
      mobile_no: '',
      formErrmobile_no: '',
      email: '',
      formErremail: '',
      slotOpenHour: "01",
      slotOpenMin: "00",
      // slotOpenFormat: "AM",
      slotCloseHour: "01",
      slotCloseMin: "00",
      // slotCloseFormat: "PM",
      open_time: '',
      close_time: '',
      location: '',
      formErropen_time: '',
      formErrclose_time: '',
      formErrlocation: '',

      happyHourstartHour: '01',
      happyHourstartMin: '00',
      // happyHourstartFormat:'AM',
      happyHourStartTime: '',
      formErrhappyHourStartTime: '',

      happyHourEndHour: '01',
      happyHourEndMin: '00',
      // happyHourEndFormat:'AM',
      happyHourEndTime: '',
      formErrhappyHourEndTime: '',

      // paymentMethod: {},
      Card: false,
      Cash: false,
      Cheque: false,


      // formErrpaymentMethod: '',

      serviceConnectFee: '',
      formErrserviceConnectFee: '',

      servicetax: '',
      formErrservicetax: '',

      tax: '',
      formErrtax: '',

      serviceFees: '',
      formErrserviceFees: '',

      processingFees: '',
      formErrprocessingFees: '',

      centFees: '',
      formErrcentFees: '',

      happyHourDiscount: '',
      formErrhappyHourDiscount: '',

      currency_symbol: '',
      formErrcurrency_symbol: '',

      longitude: 23.53,
      latitude: 53.65,


      min_charge: 1,
      formErrmin_charge: '',
      per_mile_charge: 1,
      formErrper_mile_charge: '',
      per_min_charge: 1,
      formErrper_min_charge: '',

      min: 1,
      sec: 1,
      cents: 1,

      formErrmin: '',
      formErrsec: '',
      formErrcents: '',

      additional_charge: 1,
      formErradditional_charge: '',
      empty_driver_seat: 1,
      formErrempty_driver_seat: '',
      picture: '',
      formErrpicture: '',
      desc: '',
      formErrdesc: '',
      file: '',
      ride_share: 'none',
      ride_share_id: '',
      country_list: [],
      country_name: '',
      business_id: '',
      businessId: '',
      empty_driver_per_mile_charge: '',
      formErrempty_driver_per_mile_charge: '',
      tip: '',
      formErrtip: '',
      cancellation_fees: '',
      formErrcancellation_fees: '',
      business_logo: '',
      formErrbusiness_logo: '',
      website: '',
      formErrwebsite: '',
      delivery: false,
      rideshare: false,
      category_product_service: false,
      report: false,
      happy_hour_discount: false,
      schedule: false,
      file1: '',
      delivery_type: false,
      pickup: false,
      shipping: false,
      isEdit1: false,
      isEdit2: false,
      isEdit3: false,
      about_us: '',
      privacy_policy: '',
      terms_condition: '',
      fb: '',
      twitter: '',
      tiktok: '',
      Instagram: '',

      monday_open_time_hour: '01',
      monday_open_time_min: '00',
      monday_open_time_am_pm: 'AM',
      monday_close_time_hour: '01',
      monday_close_time_min: '00',
      monday_close_time_am_pm: 'AM',

      is_monday_Open: false,

      tuesday_open_time_hour: '01',
      tuesday_open_time_min: '00',
      tuesday_open_time_am_pm: 'AM',
      tuesday_close_time_hour: '01',
      tuesday_close_time_min: '00',
      tuesday_close_time_am_pm: 'AM',

      is_tuesday_Open: false,

      wednesday_open_time_hour: '01',
      wednesday_open_time_min: '00',
      wednesday_open_time_am_pm: 'AM',
      wednesday_close_time_hour: '01',
      wednesday_close_time_min: '00',
      wednesday_close_time_am_pm: 'AM',

      is_wednesday_Open: false,

      thursday_open_time_hour: '01',
      thursday_open_time_min: '00',
      thursday_open_time_am_pm: 'AM',
      thursday_close_time_hour: '01',
      thursday_close_time_min: '00',
      thursday_close_time_am_pm: 'AM',

      is_thursday_Open: false,

      friday_open_time_hour: '01',
      friday_open_time_min: '00',
      friday_open_time_am_pm: 'AM',
      friday_close_time_hour: '01',
      friday_close_time_min: '00',
      friday_close_time_am_pm: 'AM',

      is_friday_Open: false,

      saturday_open_time_hour: '01',
      saturday_open_time_min: '00',
      saturday_open_time_am_pm: 'AM',
      saturday_close_time_hour: '01',
      saturday_close_time_min: '00',
      saturday_close_time_am_pm: 'AM',

      is_saturday_Open: false,
      sunday_open_time_hour: '01',
      sunday_open_time_min: '00',
      sunday_open_time_am_pm: 'AM',
      sunday_close_time_hour: '01',
      sunday_close_time_min: '00',
      sunday_close_time_am_pm: 'AM',

      is_sunday_Open: false,
      description: '',
      formErrdescription: '',

    }
    this.handleUploadFile = this.handleUploadFile.bind(this)
    this.updateState = this.updateState.bind(this);
    this.handleUploadFile1 = this.handleUploadFile1.bind(this)
    this.handleEditorChange1 = this.handleEditorChange1.bind(this);
    this.handleEditorChange2 = this.handleEditorChange2.bind(this);
    this.handleEditorChange3 = this.handleEditorChange3.bind(this);
  }

  handleEditorChange1(data) {
    this.setState({
      privacy_policy: data,

    })
  }
  handleEditorChange2(data) {
    this.setState({
      about_us: data,

    })
  }
  handleEditorChange3(data) {
    this.setState({
      terms_condition: data,

    })
  }
  async update() {
    await this.props.editBusinessAction(this.state.businessId, {
      about_us: this.state.about_us,
      privacy_policy: this.state.privacy_policy,
      terms_condition: this.state.terms_condition
    })
    await this.setState({
      isEdit1: false,
      isEdit2: false,
      isEdit3: false,
    })
  }
  async updateState(data) {
    // await this.setState(data);
    setTimeout(async () => {
      console.log('Our data is fetched');
      await this.setState(data)
    }, 1000)
  }
  handleUploadFile = async (event) => {
    if (this.state.ride_share_id === '') {
      await this.setState({
        file: URL.createObjectURL(event.target.files[0]), picture: event.target.files[0],
        formErrpicture: ''
      })
    }
    else {
      await this.setState({
        file: URL.createObjectURL(event.target.files[0]), picture: event.target.files[0],
        formErrpicture: ''
      })
      const data = await new FormData();
      await data.append('picture', this.state.picture);

      await this.props.editrideshareImageAction(this.state.ride_share_id, data)
    }
  }
  handleUploadFile1 = async (event) => {
    await this.setState({
      file1: URL.createObjectURL(event.target.files[0]), business_logo: event.target.files[0],
      formErrbusiness_logo: ''
    })
  }
  onChangeAddName = async (key, value) => {
    console.log(key)
    console.log(value)

    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })

    if (key === "country") {
      // await this.props.fetchcity("600921c703e76716847fac0c")
      // await this.props.fetchcity(value)
      let result = await this.state.country_list.filter(function (item) {
        if (item.sortname == value) return item.name;
      });
      if (result.length) {
        await this.setState({
          country_name: result[0].name
        })

      }
    }
    if (key === "slotOpenHour" || key === "slotOpenMin" || key === "slotOpenFormat") {
      if (this.state.businessId === '') {

      }
      else {
        this.setState({
          open_time: '',
        })
      }
      await self.setState({
        open_time: this.state.slotOpenHour + ":" + this.state.slotOpenMin + ":00",
        // + this.state.slotOpenFormat,
        formErropen_time: ''
      })
    }
    if (key === "slotCloseHour" || key === "slotCloseMin" || key === "slotCloseFormat") {
      if (this.state.businessId === '') {

      }
      else {
        this.setState({
          close_time: '',
        })
      }
      await self.setState({
        close_time: this.state.slotCloseHour + ":" + this.state.slotCloseMin + ":00",
        // + this.state.slotCloseFormat,
        formErrclose_time: ''
      })
    }

    if (key === "happyHourstartHour" || key === "happyHourstartMin") {
      if (this.state.businessId === '') {

      }
      else {
        this.setState({
          happyHourStartTime: '',
        })
      }
      await self.setState({
        happyHourStartTime: this.state.happyHourstartHour + ":" + this.state.happyHourstartMin + ":00",
        // + this.state.happyHourstartFormat,
        formErrhappyHourStartTime: ''
      })
    }

    if (key === "happyHourEndHour" || key === "happyHourEndMin") {
      if (this.state.businessId === '') {

      }
      else {
        this.setState({
          happyHourEndTime: '',
        })
      }
      await self.setState({
        happyHourEndTime: this.state.happyHourEndHour + ":" + this.state.happyHourEndMin + ":00",
        // + this.state.happyHourEndFormat,
        formErrhappyHourEndTime: ''
      })
    }

  }
  handleSubmit = async () => {
    // console.log((this.state.processingFees.split("+")).length)
    // return
    await this.setState({
      formErrname: (this.state.name === '') ? "Please provide this field" : '',
      formErrbusiness_owner_name: (this.state.business_owner_name == '') ? "Please provide this field" : '',
      formErrdescription: this.state.description === '' ? 'Please provide this field' : '',
      formErrcountry: (this.state.country === '') ? "Please provide this field" : '',
      formErrcity: (this.state.city === '') ? "Please provide this field" : '',
      formErrcountry_code: (this.state.country_code === '') ? "Please provide this field" : '',
      formErrmobile_no: (this.state.mobile_no === '') ? "Please provide this field" : '',
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      formErropen_time: (this.state.open_time === '') ? "Please provide this field" : '',
      formErrclose_time: (this.state.close_time === '') ? "Please provide this field" : '',
      formErrlocation: (this.state.location === '') ? "Please provide this field" : '',

      formErrserviceConnectFee: (this.state.serviceConnectFee === '') ? "Please provide this field" : '',
      formErrservicetax: (this.state.servicetax === '') ? "Please provide this field" : '',

      formErrtax: (this.state.tax === '') ? "Please provide this field" : '',
      formErrserviceFees: (this.state.serviceFees === '') ? "Please provide this field" : '',
      formErrprocessingFees: (this.state.processingFees === '') ? "Please provide processing and cent fees field also" : '',
      // formErrprocessingFees: ((this.state.processingFees.split("+")).length !== 2) ? "Please provide processing and cent fees field also" : '',
      formErrcentFees: (this.state.centFees === '') ? "Please provide processing and cent fees field also" : '',

      formErrhappyHourEndTime: (this.state.happyHourEndTime === '') ? "Please provide this field" : '',
      formErrhappyHourStartTime: (this.state.happyHourStartTime === '') ? "Please provide this field" : '',
      formErrhappyHourDiscount: (this.state.happyHourDiscount === '') ? "Please provide this field" : '',
      // formErrpaymentMethod: (this.state.paymentMethod === '') ? "Please provide this field" : '',
      formErrcurrency_symbol: (this.state.currency_symbol === '') ? "Please provide this field" : '',
      // formErrtip: (this.state.tip === '') ? "Please provide this field" : '',
      formErrcancellation_fees: (this.state.cancellation_fees === '') ? "Please provide this field" : '',


      formErrmin_charge: (this.state.ride_share !== 'none' && this.state.min_charge === '') ? "Please provide this field" : '',
      formErrper_mile_charge: (this.state.ride_share !== 'none' && this.state.per_mile_charge === '') ? "Please provide this field" : '',
      formErrper_min_charge: (this.state.ride_share !== 'none' && this.state.per_min_charge === '') ? "Please provide this field" : '',
      formErrmin: (this.state.ride_share !== 'none' && this.state.min === '') ? "Please provide this field" : '',
      formErrsec: (this.state.ride_share !== 'none' && this.state.sec === '') ? "Please provide this field" : '',
      formErrcents: (this.state.ride_share !== 'none' && this.state.cents === '') ? "Please provide this field" : '',

      formErradditional_charge: (this.state.ride_share !== 'none' && this.state.additional_charge === '') ? "Please provide this field" : '',
      formErrempty_driver_seat: (this.state.ride_share === 'ride_share' && this.state.empty_driver_seat === '') ? "Please provide this field" : '',

      formErrpicture: (this.state.ride_share !== 'none' && this.state.picture === '') ? "Please provide this field" : '',
      formErrdesc: (this.state.ride_share !== 'none' && this.state.desc === '') ? "Please provide this field" : '',
      formErrempty_driver_per_mile_charge: (this.state.ride_share !== 'none' && this.state.empty_driver_per_mile_charge === '') ? "Please provide this field" : '',

    })
    // const data_tax = this.state.processingFees.split("+")
    // if ((this.state.processingFees.split("+")).length === 2) {
    //   this.setState({
    //     centFees: data_tax[1]
    //   })
    // }
    if (!((this.state.name === '') && (this.state.business_owner_name === '') && (this.state.country === '') && (this.state.city === '') && (this.state.country_code === '') && (this.state.mobile_no === '') && (this.state.email === '') && (this.state.open_time === '') && (this.state.close_time === '') && (this.state.location === '') && (this.state.serviceConnectFee === '') && (this.state.servicetax === '') && (this.state.tax === '') && (this.state.serviceFees === '') && (this.state.processingFees === '') && (this.state.centFees === '') && (this.state.centFees === '') && (this.state.happyHourEndTime === '') && (this.state.happyHourStartTime === '') && (this.state.happyHourDiscount === '') && (this.state.ride_share !== 'none' && (this.state.min_charge === '') && (this.state.per_mile_charge === '') && (this.state.per_min_charge === '') && (this.state.min === '') && (this.state.sec === '') && (this.state.cents === '') && ((this.state.additional_charge === '' && this.state.ride_share === 'delivery') || (this.state.ride_share === 'ride_share' && this.state.empty_driver_seat === '')) && (this.state.description === '') && (this.state.picture === '') && (this.state.desc === '') && (this.state.empty_driver_per_mile_charge === '')))) {
      if (this.state.businessId === '') {
        const data = new FormData();
        data.append('country_code', this.state.country_code);
        data.append('mobile_no', this.state.mobile_no);
        data.append('user_type', "business_user");
        data.append('name', this.state.name);
        data.append('city', this.state.city);
        data.append('business_owner_name', this.state.business_owner_name);
        data.append('email', this.state.email);
        data.append('country', this.state.country);
        data.append('description', this.state.description);
        data.append('password', 'pass@123');
        data.append(
          'capabilities',
          JSON.stringify({
            delivery: this.state.delivery,
            ride_share: this.state.rideshare,
            category_product_service: this.state.category_product_service,
            report: this.state.report,
            happy_hour_discount: this.state.happy_hour_discount,
            schedule: this.state.schedule,
          })
        );
        data.append(
          'social_media',
          JSON.stringify({
            fb: this.state.fb,
            twitter: this.state.twitter,
            tiktok: this.state.tiktok,
            Instagram: this.state.Instagram,
          })
        );
        data.append(
          'delivery_type',
          JSON.stringify({
            delivery: (this.state.delivery_type === '') ? false : this.state.delivery_type,
            pickup: (this.state.pickup === '') ? false : this.state.pickup,
            shipping: (this.state.shipping === '') ? false : this.state.shipping
          })
        );
        data.append('bussiness_info', JSON.stringify({
          happy_hours: {
            start_time: this.state.happyHourStartTime,
            end_time: this.state.happyHourEndTime,
            discount: this.state.happyHourDiscount
          },
          open_time: this.state.open_time,
          close_time: this.state.close_time,
          location: this.state.location,
          servicehubConnetFees: this.state.serviceConnectFee,
          servicetax: this.state.servicetax,
          tax: this.state.tax,
          serviceFees: this.state.serviceFees,
          processingFees: this.state.processingFees,//data_tax[0],
          centFees: this.state.centFees,//data_tax[1],
          paymentMethod: {
            Card: this.state.Card,
            Cash: this.state.Cash,
            Cheque: this.state.Cheque
          },
          currency_symbol: this.state.currency_symbol,
          tip: [10, 20, 30],
          cancellation_fees: this.state.cancellation_fees,
        }));
        data.append('business_weekdays', JSON.stringify({
          monday: {
            open_time_hour: this.state.monday_open_time_hour,
            open_time_min: this.state.monday_open_time_min,
            open_time_am_pm: this.state.monday_open_time_am_pm,
            close_time_hour: this.state.monday_close_time_hour,
            close_time_min: this.state.monday_close_time_min,
            close_time_am_pm: this.state.monday_close_time_am_pm,
          },
          is_monday_Open: this.state.is_monday_Open,
          tuesday: {
            open_time_hour: this.state.tuesday_open_time_hour,
            open_time_min: this.state.tuesday_open_time_min,
            open_time_am_pm: this.state.tuesday_open_time_am_pm,
            close_time_hour: this.state.tuesday_close_time_hour,
            close_time_min: this.state.tuesday_close_time_min,
            close_time_am_pm: this.state.tuesday_close_time_am_pm,
          },
          is_tuesday_Open: this.state.is_tuesday_Open,
          wednesday: {
            open_time_hour: this.state.wednesday_open_time_hour,
            open_time_min: this.state.wednesday_open_time_min,
            open_time_am_pm: this.state.wednesday_open_time_am_pm,
            close_time_hour: this.state.wednesday_close_time_hour,
            close_time_min: this.state.wednesday_close_time_min,
            close_time_am_pm: this.state.wednesday_close_time_am_pm,
          },
          is_wednesday_Open: this.state.is_wednesday_Open,
          thursday: {
            open_time_hour: this.state.thursday_open_time_hour,
            open_time_min: this.state.thursday_open_time_min,
            open_time_am_pm: this.state.thursday_open_time_am_pm,
            close_time_hour: this.state.thursday_close_time_hour,
            close_time_min: this.state.thursday_close_time_min,
            close_time_am_pm: this.state.thursday_close_time_am_pm,
          },
          is_thursday_Open: this.state.is_thursday_Open,
          friday: {
            open_time_hour: this.state.friday_open_time_hour,
            open_time_min: this.state.friday_open_time_min,
            open_time_am_pm: this.state.friday_open_time_am_pm,
            close_time_hour: this.state.friday_close_time_hour,
            close_time_min: this.state.friday_close_time_min,
            close_time_am_pm: this.state.friday_close_time_am_pm,
          },
          is_friday_Open: this.state.is_friday_Open,

          saturday: {
            open_time_hour: this.state.saturday_open_time_hour,
            open_time_min: this.state.saturday_open_time_min,
            open_time_am_pm: this.state.saturday_open_time_am_pm,
            close_time_hour: this.state.saturday_close_time_hour,
            close_time_min: this.state.saturday_close_time_min,
            close_time_am_pm: this.state.saturday_close_time_am_pm,
          },
          is_saturday_Open: this.state.is_saturday_Open,
          sunday: {
            open_time_hour: this.state.sunday_open_time_hour,
            open_time_min: this.state.sunday_open_time_min,
            open_time_am_pm: this.state.sunday_open_time_am_pm,
            close_time_hour: this.state.sunday_close_time_hour,
            close_time_min: this.state.sunday_close_time_min,
            close_time_am_pm: this.state.sunday_close_time_am_pm,
          },
          is_sunday_Open: this.state.is_sunday_Open,
        }));
        if (this.state.ride_share === 'ride_share') {
          data.append('ride_share', this.state.ride_share);

          data.append('min_charge', this.state.min_charge);
          data.append('per_mile_charge', this.state.per_mile_charge);
          data.append('per_min_charge', this.state.per_min_charge);
          data.append('min', this.state.min)
          data.append('sec', this.state.sec)
          data.append('cents', this.state.cents)
          data.append('empty_driver_seat', this.state.empty_driver_seat);
          data.append('picture', this.state.picture);
          data.append('description', this.state.desc);
          data.append('empty_driver_per_mile_charge', this.state.empty_driver_per_mile_charge);


        }
        else if (this.state.ride_share === 'delivery') {
          data.append('ride_share', this.state.ride_share);

          data.append('min_charge', this.state.min_charge);
          data.append('per_mile_charge', this.state.per_mile_charge);
          data.append('additional_charge', this.state.additional_charge);
          data.append('picture', this.state.picture);
          data.append('description', this.state.desc);
        }
        data.append('local_city', this.state.location);
        data.append('latitude', this.state.latitude)
        data.append('longitude', this.state.longitude)

        if (
          this.state.business_logo !== ''
        ) {
          const datas = new FormData();
          datas.append('business_logo', this.state.business_logo);
          datas.append('website', this.state.website);

          this.props.addBusinessAction(data, this.props.history, datas)
        }
        else {
          // console.log(data,'============data===')
          this.props.addBusinessAction(data, this.props.history, {})
        }

      }
      else {

        if (
          this.state.file1
        ) {
          // console.log("isme aa rha hai",this.state.file1)
          const datas = new FormData();
          datas.append('business_logo', this.state.business_logo);
          datas.append('website', this.state.website);

          this.props.updateBusinessLogoAction(datas, this.state.businessId)
        }
        else {
          // this.props.updateBusinessLogoAction(data,this.state.businessId)
        }
        // console.log("bahar aaya hai")
        this.props.editBusinessAction(this.state.businessId, {
          country_code: this.state.country_code,

          mobile_no: this.state.mobile_no,
          name: this.state.name,
          city: this.state.city,
          business_owner_name: this.state.business_owner_name,
          // password: 'pass@123',
          // email:this.state.email,
          description: this.state.description,
          country: this.state.country,

          social_media:
            JSON.stringify({
              fb: this.state.fb,
              twitter: this.state.twitter,
              tiktok: this.state.tiktok,
              Instagram: this.state.Instagram,
            }),

          capabilities: JSON.stringify({
            delivery: this.state.delivery,
            rideshare: this.state.rideshare,
            category_product_service: this.state.category_product_service,
            report: this.state.report,
            happy_hour_discount: this.state.happy_hour_discount,
            schedule: this.state.schedule,
          }),

          delivery_type:
            JSON.stringify({
              delivery: (this.state.delivery_type === '') ? false : this.state.delivery_type,
              pickup: (this.state.pickup === '') ? false : this.state.pickup,
              shipping: (this.state.shipping === '') ? false : this.state.shipping
            }),
          business_weekdays: JSON.stringify({
            monday: {
              open_time_hour: this.state.monday_open_time_hour,
              open_time_min: this.state.monday_open_time_min,
              open_time_am_pm: this.state.monday_open_time_am_pm,
              close_time_hour: this.state.monday_close_time_hour,
              close_time_min: this.state.monday_close_time_min,
              close_time_am_pm: this.state.monday_close_time_am_pm,
            },
            is_monday_Open: this.state.is_monday_Open,
            tuesday: {
              open_time_hour: this.state.tuesday_open_time_hour,
              open_time_min: this.state.tuesday_open_time_min,
              open_time_am_pm: this.state.tuesday_open_time_am_pm,
              close_time_hour: this.state.tuesday_close_time_hour,
              close_time_min: this.state.tuesday_close_time_min,
              close_time_am_pm: this.state.tuesday_close_time_am_pm,
            },
            is_tuesday_Open: this.state.is_tuesday_Open,
            wednesday: {
              open_time_hour: this.state.wednesday_open_time_hour,
              open_time_min: this.state.wednesday_open_time_min,
              open_time_am_pm: this.state.wednesday_open_time_am_pm,
              close_time_hour: this.state.wednesday_close_time_hour,
              close_time_min: this.state.wednesday_close_time_min,
              close_time_am_pm: this.state.wednesday_close_time_am_pm,
            },
            is_wednesday_Open: this.state.is_wednesday_Open,
            thursday: {
              open_time_hour: this.state.thursday_open_time_hour,
              open_time_min: this.state.thursday_open_time_min,
              open_time_am_pm: this.state.thursday_open_time_am_pm,
              close_time_hour: this.state.thursday_close_time_hour,
              close_time_min: this.state.thursday_close_time_min,
              close_time_am_pm: this.state.thursday_close_time_am_pm,
            },
            is_thursday_Open: this.state.is_thursday_Open,
            friday: {
              open_time_hour: this.state.friday_open_time_hour,
              open_time_min: this.state.friday_open_time_min,
              open_time_am_pm: this.state.friday_open_time_am_pm,
              close_time_hour: this.state.friday_close_time_hour,
              close_time_min: this.state.friday_close_time_min,
              close_time_am_pm: this.state.friday_close_time_am_pm,
            },
            is_friday_Open: this.state.is_friday_Open,

            saturday: {
              open_time_hour: this.state.saturday_open_time_hour,
              open_time_min: this.state.saturday_open_time_min,
              open_time_am_pm: this.state.saturday_open_time_am_pm,
              close_time_hour: this.state.saturday_close_time_hour,
              close_time_min: this.state.saturday_close_time_min,
              close_time_am_pm: this.state.saturday_close_time_am_pm,
            },
            is_saturday_Open: this.state.is_saturday_Open,
            sunday: {
              open_time_hour: this.state.sunday_open_time_hour,
              open_time_min: this.state.sunday_open_time_min,
              open_time_am_pm: this.state.sunday_open_time_am_pm,
              close_time_hour: this.state.sunday_close_time_hour,
              close_time_min: this.state.sunday_close_time_min,
              close_time_am_pm: this.state.sunday_close_time_am_pm,
            },
            is_sunday_Open: this.state.is_sunday_Open,
          }),
          bussiness_info: JSON.stringify({
            happy_hours: {
              start_time: this.state.happyHourStartTime,
              end_time: this.state.happyHourEndTime,
              discount: this.state.happyHourDiscount
            },
            open_time: this.state.open_time,
            close_time: this.state.close_time,
            location: this.state.location,
            servicehubConnetFees: this.state.serviceConnectFee,
            servicetax: this.state.servicetax,
            tax: this.state.tax,
            serviceFees: this.state.serviceFees,
            processingFees: this.state.processingFees,//data_tax[0],
            centFees: this.state.centFees,//data_tax[1],
            paymentMethod: {
              Card: this.state.Card,
              Cash: this.state.Cash,
              Cheque: this.state.Cheque
            },
            currency_symbol: this.state.currency_symbol,
            tip: [10, 20, 30],
            cancellation_fees: this.state.cancellation_fees,

          }),
          local_city: this.state.location,
          latitude: this.state.latitude,
          longitude: this.state.longitude

        }, this.props.history)

        if (this.state.ride_share_id !== '') {
          if (this.state.ride_share === 'ride_share') {
            this.props.editrideshareAction(this.state.ride_share_id, {
              min_charge: this.state.min_charge,
              per_mile_charge: this.state.per_mile_charge,
              per_min_charge: this.state.per_min_charge,
              min: this.state.min,
              sec: this.state.sec,
              cents: this.state.cents,
              empty_driver_seat: this.state.empty_driver_seat,
              // picture:this.state.picture,
              description: this.state.desc,
              empty_driver_per_mile_charge: this.state.empty_driver_per_mile_charge
            })

          }
          else if (this.state.ride_share === 'delivery') {
            this.props.editrideshareAction(this.state.ride_share_id, {

              ride_share: this.state.ride_share,

              min_charge: this.state.min_charge,
              per_mile_charge: this.state.per_mile_charge,
              additional_charge: this.state.additional_charge,
              // picture:this.state.picture,
              description: this.state.desc
            })
          }

        }
        else {

          if (this.state.ride_share === 'ride_share') {
            const data = new FormData();
            data.append('ride_share', this.state.ride_share);
            data.append('min_charge', this.state.min_charge);
            data.append('per_mile_charge', this.state.per_mile_charge);
            data.append('per_min_charge', this.state.per_min_charge);
            data.append('min', this.state.min)
            data.append('sec', this.state.sec)
            data.append('cents', this.state.cents)
            data.append('empty_driver_seat', this.state.empty_driver_seat);
            data.append('picture', this.state.picture);
            data.append('description', this.state.desc);
            data.append('empty_driver_per_mile_charge', this.state.empty_driver_per_mile_charge);
            data.append('business_id', this.state.business_id);

            this.props.addrideshareDataAction(data, this.props.history)
          }
          else if (this.state.ride_share === 'delivery') {
            const data = new FormData();
            data.append('ride_share', this.state.ride_share);

            data.append('min_charge', this.state.min_charge);
            data.append('per_mile_charge', this.state.per_mile_charge);
            data.append('additional_charge', this.state.additional_charge);
            data.append('picture', this.state.picture);
            data.append('description', this.state.desc);
            data.append('business_id', this.state.business_id);

            this.props.addrideshareDataAction(data, this.props.history)

          }
          // this.props.addBusinessAction(data, this.props.history)
        }
      }
    }
  }
  UNSAFE_componentWillMount = async () => {
    // await this.props.fetchcountry()
    // await this.props.fetchcountry()
    // await this.setState({
    //   country_list:worldMapData.getAllCountries()
    // })
  }
  async componentDidMount() {
    // await this.props.fetchcountry()
    const country_array = await worldMapData.getAllCountries()
    await this.setState({
      country_list: country_array
    })
  }
  componentDidMount = async () => {
    // console.log(this.props.match,"match data_bussiness");
    let business_id = await this.props.match.params.id;

    if (business_id) {
      // console.log(business_id);
      await this.setState({
        business_id: business_id,
        // category_name:this.props.match.params.name
      });
      await this.props.getbusinessOnIdAction(business_id);
      const getData = await this.props.data_bussiness;
      this.props.getbusinessOnIdAction(business_id);

      // console.log(this.state.workingDay);
    }
  };
  componentDidUpdate(prevProps, prevState) {
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: 'success' });
    error && enqueueSnackbar(error, { variant: 'error' });
  }
  handlelatlong = (value) => {
    // const formvalue = { ...formValues }
    // formvalue.address.x_coordinate = value.latitude
    // formvalue.address.y_coordinate = value.longitude
    // setFormValue(formvalue)
    this.setState({
      latitude: value.latitude,
      longitude: value.longitude
    })
    console.log(this.state.latitude)
    // console.log(this.state.longitude)

  }
  handleAddress = (value) => {
    console.log(value)
    this.setState({
      location: value
    })
  }
  handlepincode = (value) => {

  }
  handlecityname = (value) => {

  }
  async editPrivacyPolicy() {
    await this.setState({ isEdit1: true })
    console.log(this.state.isEdit1)
  }

  async editabout_us() {
    await this.setState({ isEdit2: true })
    // console.log(this.state.isEdit2)
  }
  async editterms_condition() {
    await this.setState({ isEdit3: true })
    console.log(this.state.isEdit3)
  }
  cancelUpdate() {
    this.setState({ isEdit1: false })
  }

  cancelUpdate2() {
    this.setState({ isEdit2: false })
  }
  cancelUpdate3() {
    this.setState({ isEdit3: false })
  }

  render() {

    let business_id = this.props.match.params.id;
    let getData = this.props.data_bussiness;
    if (
      business_id
      &&
      (!this.state.businessId || this.state.businessId !== getData._id) &&
      this.props.data_bussiness &&
      this.props.data_bussiness._id
    ) {

      // console.log(getData.location,'=================dtaa=======',this.state.latitude)
      this.updateState({
        // {
        businessId: getData._id,
        country_code: getData.country_code,
        description: getData.description,
        mobile_no: getData.mobile_no,
        user_type: "business_user",
        name: getData.name,
        city: getData.city,
        business_owner_name: getData.business_owner_name,
        email: getData.email,
        country: getData.country,
        business_logo: getData.business_logo,
        website: getData.website,
        file1: getData.business_logo,
        about_us: getData.about_us,
        privacy_policy: getData.privacy_policy,
        terms_condition: getData.terms_condition,
        fb: (getData.social_media == undefined) ? '' : getData.social_media.fb,
        twitter: (getData.social_media == undefined) ? '' : getData.social_media.twitter,
        tiktok: (getData.social_media == undefined) ? '' : getData.social_media.tiktok,
        Instagram: (getData.social_media == undefined) ? '' : getData.social_media.Instagram,


        monday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_hour,
        monday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_min,
        monday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.open_time_am_pm,
        monday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_hour,
        monday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_min,
        monday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.monday.close_time_am_pm,

        is_monday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_monday_Open,

        tuesday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_hour,
        tuesday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_min,
        tuesday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.open_time_am_pm,
        tuesday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_hour,
        tuesday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_min,
        tuesday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.tuesday.close_time_am_pm,

        is_tuesday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_tuesday_Open,

        wednesday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_hour,
        wednesday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_min,
        wednesday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.open_time_am_pm,
        wednesday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_hour,
        wednesday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_min,
        wednesday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.wednesday.close_time_am_pm,

        is_wednesday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_wednesday_Open,

        thursday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_hour,
        thursday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_min,
        thursday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.open_time_am_pm,
        thursday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_hour,
        thursday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_min,
        thursday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.thursday.close_time_am_pm,

        is_thursday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_thursday_Open,

        friday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_hour,
        friday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_min,
        friday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.open_time_am_pm,
        friday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_hour,
        friday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_min,
        friday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.friday.close_time_am_pm,

        is_friday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_friday_Open,

        saturday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_hour,
        saturday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_min,
        saturday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.open_time_am_pm,
        saturday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_hour,
        saturday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_min,
        saturday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.saturday.close_time_am_pm,

        is_saturday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_saturday_Open,
        sunday_open_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_hour,
        sunday_open_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_min,
        sunday_open_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.open_time_am_pm,
        sunday_close_time_hour: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_hour,
        sunday_close_time_min: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_min,
        sunday_close_time_am_pm: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.sunday.close_time_am_pm,

        is_sunday_Open: (getData.business_weekdays == undefined) ? '' : getData.business_weekdays.is_sunday_Open,

        // bussiness_info: {
        //   happy_hours: {
        // start_time: getData.bussiness_info.happy_hours.happyHourStartTime,
        // this.state.happyHourEndHour + ":" + this.state.happyHourEndMin + ":00",

        happyHourStartTime: getData.bussiness_info.happy_hours.start_time,
        happyHourEndTime: getData.bussiness_info.happy_hours.end_time,
        happyHourDiscount: getData.bussiness_info.happy_hours.discount,
        happyHourstartHour: getData.bussiness_info.happy_hours.start_time.split(":")[0],
        happyHourstartMin: getData.bussiness_info.happy_hours.start_time.split(":")[1],

        happyHourEndHour: getData.bussiness_info.happy_hours.end_time.split(":")[0],
        happyHourEndMin: getData.bussiness_info.happy_hours.end_time.split(":")[1],

        happyHourDiscount: getData.bussiness_info.happy_hours.discount,
        // },
        open_time: getData.bussiness_info.open_time,
        close_time: getData.bussiness_info.close_time,
        slotOpenHour: getData.bussiness_info.open_time.split(":")[0],
        slotOpenMin: getData.bussiness_info.open_time.split(":")[1],
        slotCloseHour: getData.bussiness_info.close_time.split(":")[0],
        slotCloseMin: getData.bussiness_info.close_time.split(":")[1],
        // serviceConnectFee:
        location: getData.bussiness_info.location,
        serviceConnectFee: getData.bussiness_info.servicehubConnetFees,
        servicetax: getData.bussiness_info.servicetax,
        tax: getData.bussiness_info.tax,
        serviceFees: getData.bussiness_info.serviceFees,
        processingFees: getData.bussiness_info.processingFees,
        centFees: getData.bussiness_info.centFees,
        centFees: getData.bussiness_info.centFees,
        Cash: getData.bussiness_info.paymentMethod ? getData.bussiness_info.paymentMethod.Cash : false,
        Card: getData.bussiness_info.paymentMethod ? getData.bussiness_info.paymentMethod.Card : false,
        Cheque: getData.bussiness_info.paymentMethod ? getData.bussiness_info.paymentMethod.Cheque : false,

        currency_symbol: getData.bussiness_info.currency_symbol,
        // tip:getData.bussiness_info.tip[2],
        cancellation_fees: getData.bussiness_info.cancellation_fees,

        // },
        local_city: getData.local_city,
        longitude: getData.location ? getData.location.coordinates[0] : this.state.longitude,
        latitude: getData.location ? getData.location.coordinates[1] : this.state.latitude,
        ride_share: getData.ride_share,
        min_charge: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].min_charge : '',

        per_mile_charge: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].per_mile_charge : '',
        per_min_charge: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].per_min_charge : '',
        min: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? (getData.rideshareinfo[0].waiting_time) ? getData.rideshareinfo[0].waiting_time.min : '' : '',
        sec: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? (getData.rideshareinfo[0].waiting_time) ? getData.rideshareinfo[0].waiting_time.sec : '' : '',
        cents: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? (getData.rideshareinfo[0].waiting_time) ? getData.rideshareinfo[0].waiting_time.cents : '' : 1,
        additional_charge: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].additional_charge : '',
        empty_driver_seat: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].empty_driver_seat : '',
        picture: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].picture : "",
        file: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].picture : "",
        desc: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].description : '',
        ride_share_id: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0]._id : '',
        empty_driver_per_mile_charge: (getData.ride_share !== 'none' && getData.rideshareinfo.length > 0) ? getData.rideshareinfo[0].empty_driver_per_mile_charge : '',
        delivery: (getData.capabilities) ? getData.capabilities.delivery : '',
        rideshare: (getData.capabilities) ? getData.capabilities.rideshare : '',
        category_product_service: (getData.capabilities) ? getData.capabilities.category_product_service : '',
        report: (getData.capabilities) ? getData.capabilities.report : '',
        happy_hour_discount: (getData.capabilities) ? getData.capabilities.happy_hour_discount : '',
        schedule: (getData.capabilities) ? getData.capabilities.schedule : '',

        delivery_type: (getData.delivery_type) ? getData.delivery_type.delivery : '',
        pickup: (getData.delivery_type) ? getData.delivery_type.pickup : '',
        shipping: (getData.delivery_type) ? getData.delivery_type.shipping : '',

      });
    }
    // console.log(this.state.longitude,'locationnnnnnnn',this.state.latitude)

    // slotOpenHour: getData.bussiness_info.open_time.split(":")[0],
    // slotOpenMin: getData.bussiness_info.open_time.split(":")[1],
    // slotCloseHour: getData.bussiness_info.close_time.split(":")[0],
    // slotCloseMin: getData.bussiness_info.close_time.split(":")[1],
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Add Business</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col-xl-4 order-xl-2'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-12'>
                      <h3 className='mb-0'>Happy Hours </h3>
                    </div>
                  </div>
                </div>

                <div className='card-body'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Happy hour start Time</label>
                        <Input
                          value={this.state.happyHourstartHour}
                          type="select"
                          onChange={(e) => this.onChangeAddName('happyHourstartHour', e.target.value)}
                        >
                          {data.hours.map((option) => (
                            <option value={option}>{option}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>&nbsp;</label>

                        <Input
                          value={this.state.happyHourstartMin}
                          type="select"
                          onChange={(e) => this.onChangeAddName('happyHourstartMin', e.target.value)}
                        >
                          {data.minutes_second.map((option) => (
                            <option value={option}>{option}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Happy hour end Time</label>
                        <Input
                          value={this.state.happyHourEndHour}
                          type="select"
                          onChange={(e) => this.onChangeAddName('happyHourEndHour', e.target.value)}
                        >
                          {data.hours.map((option) => (
                            <option value={option}>{option}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>&nbsp;</label>

                        <Input
                          value={this.state.happyHourEndMin}
                          // value={this.state.businessName}
                          type="select"
                          onChange={(e) => this.onChangeAddName('happyHourEndMin', e.target.value)}
                        >
                          {data.minutes_second.map((option) => (
                            <option value={option}>{option}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Happy hour discount</label>
                        <div className='input-group'>
                          <div className='input-group-prepend'>
                            <span className='input-group-text' id='basic-addon1'>%</span>
                          </div>
                          <input type='text' className='form-control' placeholder='30'
                            value={this.state.happyHourDiscount}
                            onChange={(e) => this.onChangeAddName('happyHourDiscount', e.target.value)}
                          />

                          <span style={{ color: 'red' }}>{this.state.formErrhappyHourDiscount}</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Currency Symbol</label>
                        <div className='input-group'>
                          <Input
                            value={this.state.currency_symbol}
                            type="select"
                            onChange={(e) => this.onChangeAddName('currency_symbol', e.target.value)}
                          >
                            {data.currency.map((option) => (
                              <option value={option.symbol}>{option.code + " " + option.symbol}</option>
                            ))}
                          </Input>
                          <span style={{ color: 'red' }}>{this.state.formErrcurrency_symbol}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label mr-5' for='input-first-name'>Payment Method</label>
                        <div className='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck1'
                            checked={this.state.Card === true}
                            onChange={(e) =>
                              this.onChangeAddName('Card', !this.state.Card)} />
                          <label className='custom-control-label' for='customCheck1'>Card</label>
                        </div>
                        <div className='custom-control custom-checkbox mb-2'>

                          <input type='checkbox' class='custom-control-input' id='customCheck2'
                            checked={this.state.Cash === true}
                            onChange={(e) =>
                              this.onChangeAddName('Cash', !this.state.Cash)} />

                          <label className='custom-control-label' for='customCheck2'>Cash</label>
                        </div>
                        <div className='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck3'
                            checked={this.state.Cheque === true}
                            onChange={(e) =>
                              this.onChangeAddName('Cheque', !this.state.Cheque)} />

                          <label className='custom-control-label' for='customCheck3'>Cheque</label>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Delivery Type</label>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck111' checked={this.state.delivery_type === true}
                            onChange={(e) =>
                              this.onChangeAddName('delivery_type', !this.state.delivery_type)
                            } />
                          <label class='custom-control-label' for='customCheck111'>Delivery</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck211'
                            checked={this.state.pickup === true}
                            onChange={(e) =>
                              this.onChangeAddName('pickup', !this.state.pickup)
                            }
                          />
                          <label class='custom-control-label' for='customCheck211'>Pick up</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck311'
                            checked={this.state.shipping === true}
                            onChange={(e) =>
                              this.onChangeAddName('shipping', !this.state.shipping)
                            }
                          />
                          <label class='custom-control-label' for='customCheck311'>Shipping</label>
                        </div>
                      </div>
                      {/* <span style={{ color: 'red' }}>{this.state.formErrpaymentMethod}</span> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Capabilities</label>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck11' checked={this.state.delivery === true}
                            onChange={(e) =>
                              this.onChangeAddName('delivery', !this.state.delivery)
                            } />
                          <label class='custom-control-label' for='customCheck11'>Delivery</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck21'
                            checked={this.state.rideshare === true}
                            onChange={(e) =>
                              this.onChangeAddName('rideshare', !this.state.rideshare)
                            }
                          />
                          <label class='custom-control-label' for='customCheck21'>Ride Share</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck31'
                            checked={this.state.category_product_service === true}
                            onChange={(e) =>
                              this.onChangeAddName('category_product_service', !this.state.category_product_service)
                            }
                          />
                          <label class='custom-control-label' for='customCheck31'>Category , Product & Service</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck41'
                            checked={this.state.report === true}
                            onChange={(e) =>
                              this.onChangeAddName('report', !this.state.report)
                            }
                          />
                          <label class='custom-control-label' for='customCheck41'>Report</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck51'
                            checked={this.state.happy_hour_discount === true}
                            onChange={(e) =>
                              this.onChangeAddName('happy_hour_discount', !this.state.happy_hour_discount)
                            } />
                          <label class='custom-control-label' for='customCheck51'>Happy Hour Discount</label>
                        </div>
                        <div class='custom-control custom-checkbox mb-2'>
                          <input type='checkbox' class='custom-control-input' id='customCheck61'
                            checked={this.state.schedule === true}
                            onChange={(e) =>
                              this.onChangeAddName('schedule', !this.state.schedule)
                            } />
                          <label class='custom-control-label' for='customCheck61'>Schedule</label>
                        </div>

                      </div>
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Servicehubconnect Fee %</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Servicehubconnect Fee %'
                          value={this.state.serviceConnectFee}
                          onChange={(e) => this.onChangeAddName('serviceConnectFee', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrserviceConnectFee}</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Service Tax (%)</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Servicehubconnect Fee %'
                          value={this.state.servicetax}
                          onChange={(e) => this.onChangeAddName('servicetax', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrservicetax}</span>
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Product Tax (%)</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Tax (%)'
                          value={this.state.tax}
                          onChange={(e) => this.onChangeAddName('tax', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrtax}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Service Fee (%)</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Service Fee (%)'
                          value={this.state.serviceFees}
                          onChange={(e) => this.onChangeAddName('serviceFees', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrserviceFees}</span>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Processing Fee (%)</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Processing Fee (%)'
                          value={this.state.processingFees}
                          onChange={(e) => this.onChangeAddName('processingFees', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrprocessingFees}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Processing Fee ($)</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Processing Fee ($)'
                          value={this.state.centFees}
                          onChange={(e) => this.onChangeAddName('centFees', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrcentFees}</span>
                      </div>
                    </div>
                    <div className='col-lg-6'></div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Tip</label>
                        <div className='input-group'>
                          {/* <input type='text' className='form-control' placeholder='Tip'
                            value={this.state.tip}
                            onChange={(e) => this.onChangeAddName('tip', e.target.value)}
                          /> */}
                          <div className="form-check">
                            <input type="radio" className="btn-check d-none" name="inlineRadioOptions1" id="inlineRadioOptions11" autocomplete="off" value={this.state.tip}
                            // onClick={(e) => this.onChangeAddName('tip', '10')}
                            />
                            <label className="btn btn-outline-primary" htmlFor="inlineRadioOptions11">10%</label>
                          </div>
                          <div className="form-check">
                            <input type="radio" className="btn-check d-none" name="inlineRadioOptions2" id="inlineRadioOptions12" autocomplete="off" value={this.state.tip}
                            // onClick={(e) => this.onChangeAddName('tip', '20')}
                            />
                            <label className="btn btn-outline-primary" htmlFor="inlineRadioOptions12">20%</label>
                          </div>
                          <div className="form-check">
                            <input type="radio" className="btn-check d-none" name="inlineRadioOptions3" id="inlineRadioOptions13" autocomplete="off" value={this.state.tip}
                            // onClick={(e) => this.onChangeAddName('tip', '30')}
                            />
                            <label className="btn btn-outline-primary" htmlFor="inlineRadioOptions13">30%</label>
                          </div>

                          {/* <div class="ml-3">               
                            <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Enter Custom Amout" value={this.state.tip}
                            onChange={(e) => this.onChangeAddName('tip', e.target.value)}
                            />
                          </div> */}

                          {/* <span style={{ color: 'red' }}>{this.state.formErrtip}</span> */}
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Cancellation Fees</label>
                        <div className='input-group'>
                          <input type='text' className='form-control' placeholder='Cancellation Fees'
                            value={this.state.cancellation_fees}
                            onChange={(e) => this.onChangeAddName('cancellation_fees', e.target.value)}
                          />

                          <span style={{ color: 'red' }}>{this.state.formErrcancellation_fees}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Facebook Link</label>
                        <div className='input-group'>
                          <input type='text' className='form-control' placeholder='Facebook Link'
                            value={this.state.fb}
                            onChange={(e) => this.onChangeAddName('fb', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Twitter Link</label>
                        <div className='input-group'>
                          <input type='text' className='form-control' placeholder='Twitter Link'
                            value={this.state.twitter}
                            onChange={(e) => this.onChangeAddName('twitter', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Tik-tok Link</label>
                        <div className='input-group'>
                          <input type='text' className='form-control' placeholder='Tik-tok Link'
                            value={this.state.tiktok}
                            onChange={(e) => this.onChangeAddName('tiktok', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-first-name'>Instagram Link</label>
                        <div className='input-group'>
                          <input type='text' className='form-control' placeholder='Instagram Link'
                            value={this.state.Instagram}
                            onChange={(e) => this.onChangeAddName('Instagram', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_monday_Open', !this.state.is_monday_Open)} >{this.state.is_monday_Open ? 'Hide to remove monday timing' : 'Show to add monday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_monday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday open hour</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday open hour' value={this.state.monday_open_time_hour} onChange={(e) => this.onChangeAddName('monday_open_time_hour', e.target.value)} /> */}
                              <Input
                                type='select'
                                value={this.state.monday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday open min</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday open min'
                                value={this.state.monday_open_time_min}
                                onChange={(e) => this.onChangeAddName('monday_open_time_min', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.monday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday open am/pm</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday open am/pm'
                                value={this.state.monday_open_time_am_pm}
                                onChange={(e) => this.onChangeAddName('monday_open_time_am_pm', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.monday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday close hour</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday close hour'
                                value={this.state.monday_close_time_hour}
                                onChange={(e) => this.onChangeAddName('monday_close_time_hour', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.monday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday close min</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday close min'
                                value={this.state.monday_close_time_min}
                                onChange={(e) => this.onChangeAddName('monday_close_time_min', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.monday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Monday close am/pm</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='Monday close am/pm'
                                value={this.state.monday_close_time_am_pm}
                                onChange={(e) => this.onChangeAddName('monday_close_time_am_pm', e.target.value)}
                              /> */}

                              <Input
                                type='select'
                                value={this.state.monday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'monday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    : <div></div>
                  }
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_tuesday_Open', !this.state.is_tuesday_Open)} >{this.state.is_tuesday_Open ? 'Hide to remove tuesday timing' : 'Show to add tuesday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_tuesday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday open hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.tuesday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday open min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.tuesday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday open am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.tuesday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday close hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.tuesday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday close min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.tuesday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Tuesday close am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.tuesday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'tuesday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    : <div></div>
                  }
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_wednesday_Open', !this.state.is_wednesday_Open)} >{this.state.is_wednesday_Open ? 'Hide to remove wednesday timing' : 'Show to add wednesday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_wednesday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday open hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.wednesday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday open min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.wednesday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday open am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.wednesday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday close hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.wednesday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday close min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.wednesday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Wednesday close am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.wednesday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'wednesday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    : <div></div>
                  }
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_thursday_Open', !this.state.is_thursday_Open)} >{this.state.is_thursday_Open ? 'Hide to remove thursday timing' : 'Show to add thursday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_thursday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday open hour</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday open hour' value={this.state.thursday_open_time_hour} onChange={(e) => this.onChangeAddName('thursday_open_time_hour', e.target.value)} /> */}
                              <Input
                                type='select'
                                value={this.state.thursday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday open min</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday open min'
                                value={this.state.thursday_open_time_min}
                                onChange={(e) => this.onChangeAddName('thursday_open_time_min', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.thursday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday open am/pm</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday open am/pm'
                                value={this.state.thursday_open_time_am_pm}
                                onChange={(e) => this.onChangeAddName('thursday_open_time_am_pm', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.thursday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday close hour</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday close hour'
                                value={this.state.thursday_close_time_hour}
                                onChange={(e) => this.onChangeAddName('thursday_close_time_hour', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.thursday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday close min</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday close min'
                                value={this.state.thursday_close_time_min}
                                onChange={(e) => this.onChangeAddName('thursday_close_time_min', e.target.value)}
                              /> */}
                              <Input
                                type='select'
                                value={this.state.thursday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>thursday close am/pm</label>
                            <div className='input-group'>
                              {/* <input type='text' className='form-control' placeholder='thursday close am/pm'
                                value={this.state.thursday_close_time_am_pm}
                                onChange={(e) => this.onChangeAddName('thursday_close_time_am_pm', e.target.value)}
                              /> */}

                              <Input
                                type='select'
                                value={this.state.thursday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'thursday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    : <div></div>
                  }
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_friday_Open', !this.state.is_friday_Open)} >{this.state.is_friday_Open ? 'Hide to remove friday timing' : 'Show to add friday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_friday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday open hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.friday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday open min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.friday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday open am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.friday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday close hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.friday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday close min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.friday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>friday close am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.friday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'friday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>

                    : <div></div>
                  }
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_saturday_Open', !this.state.is_saturday_Open)} >{this.state.is_saturday_Open ? 'Hide to remove saturday timing' : 'Show to add saturday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_saturday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday open hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.saturday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday open min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.saturday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday open am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.saturday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday close hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.saturday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday close min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.saturday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>saturday close am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.saturday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'saturday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    : <div></div>
                  }

                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <button className="btn btn-primary float-right" onClick={(e) => this.onChangeAddName('is_sunday_Open', !this.state.is_sunday_Open)} >{this.state.is_sunday_Open ? 'Hide to remove sunday timing' : 'Show to add sunday timing'}</button>
                      </div>
                    </div>
                  </div>
                  {this.state.is_sunday_Open ?
                    <div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday open hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.sunday_open_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_open_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday open min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.sunday_open_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_open_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday open am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.sunday_open_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_open_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday close hour</label>
                            <div className='input-group'>
                              <Input
                                type='select'
                                value={this.state.sunday_close_time_hour}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_close_time_hour',
                                    e.target.value
                                  )
                                }
                              >
                                {data.hours.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday close min</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.sunday_close_time_min}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_close_time_min',
                                    e.target.value
                                  )
                                }
                              >
                                {data.minutes_second.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>sunday close am/pm</label>
                            <div className='input-group'>

                              <Input
                                type='select'
                                value={this.state.sunday_close_time_am_pm}
                                onChange={(e) =>
                                  this.onChangeAddName(
                                    'sunday_close_time_am_pm',
                                    e.target.value
                                  )
                                }
                              >
                                {data.format.map((option) => (
                                  <option value={option}>{option}</option>
                                ))}
                              </Input>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    : <div></div>
                  }
                </div>
                {this.state.ride_share === 'none' ? <div>
                  <div className='card-body'>
                    <button
                      onClick={(e) => this.onChangeAddName('ride_share', this.state.ride_share === 'none' ? 'ride_share' : 'none')}
                    >
                      {this.state.ride_share === 'ride_share' ? "Remove" : "Add"} ride share
                    </button>
                  </div>

                  <div className='card-body'>
                    <button
                      onClick={(e) => this.onChangeAddName('ride_share', this.state.ride_share === 'none' ? 'delivery' : 'none')}
                    >
                      {this.state.ride_share === 'delivery' ? "Remove" : "Add"} delivery
                    </button>
                  </div>
                </div> : this.state.ride_share === 'ride_share' ? <div className='card-body'>
                  <button
                    onClick={(e) => this.onChangeAddName('ride_share', this.state.ride_share === 'none' ? 'ride_share' : 'none')}
                  >
                    {this.state.ride_share === 'ride_share' ? "Remove" : "Add"} ride share
                  </button>
                </div> : this.state.ride_share === 'delivery' ? <div className='card-body'>
                  <button
                    onClick={(e) => this.onChangeAddName('ride_share', this.state.ride_share === 'none' ? 'delivery' : 'none')}
                  >
                    {this.state.ride_share === 'delivery' ? "Remove" : "Add"} delivery
                  </button>
                </div> : ''}


                {this.state.ride_share === 'ride_share' ? <div className='card-body'>
                  <h1>
                    RideShare
                  </h1>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Minimum charges</label>
                        <input type='number' id='input-username' className='form-control' placeholder='Minimum charges'
                          value={this.state.min_charge} min="1"
                          onChange={(e) => this.onChangeAddName('min_charge', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrmin_charge}</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>1 Mile charge</label>
                        <input type='number' id='input-username' className='form-control' placeholder='1 Mile charge'
                          value={this.state.per_mile_charge} min="1"
                          onChange={(e) => this.onChangeAddName('per_mile_charge', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrper_mile_charge}</span>
                      </div>
                    </div>

                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>1 minute charge</label>
                        <input type='number' id='input-username' className='form-control' placeholder='per_min_charge'
                          value={this.state.per_min_charge} min="1"
                          onChange={(e) => this.onChangeAddName('per_min_charge', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrper_min_charge}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>

                    <div className='col-lg-6'>

                      <img src={this.state.picture === '' ? '../assets/img/theme/img-1-1000x600.jpg' : this.state.file} alt='Image placeholder' className='card-img-top' />
                      <span style={{ color: 'red' }}>{this.state.formErrpicture}</span>
                      <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                        <Input
                          type="file"
                          // id="File-2"
                          className='btn btn-default float-right'
                          onChange={this.handleUploadFile}
                        >Upload</Input>
                      </div>
                    </div>

                    <div className='col-lg-6'>

                      <label className='form-control-label' for='input-username'>Description</label>
                      <input type='text' id='input-username' className='form-control' placeholder='Vehicle description'
                        value={this.state.desc}
                        onChange={(e) => this.onChangeAddName('desc', e.target.value)}
                      />
                      <span style={{ color: 'red' }}>{this.state.formErrdesc}</span>
                    </div>
                  </div>
                  <div className='row'>

                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Wating time</label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder='minutes'
                          value={this.state.min}
                          onChange={(e) => this.onChangeAddName('min', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrmin}</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'></label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder='seconds'
                          value={this.state.sec}
                          onChange={(e) => this.onChangeAddName('sec', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrsec}</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'></label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder='cents'
                          value={this.state.cents}
                          onChange={(e) => this.onChangeAddName('cents', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrcents}</span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>

                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Empty driver seat</label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder=''
                          value={this.state.empty_driver_seat}
                          onChange={(e) => this.onChangeAddName('empty_driver_seat', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Empty driver per mile charge</label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder='minutes'
                          value={this.state.empty_driver_per_mile_charge}
                          onChange={(e) => this.onChangeAddName('empty_driver_per_mile_charge', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                  : ''}
                {this.state.ride_share === 'delivery' ? <div className='card-body'>
                  <h1>
                    Delivery
                  </h1>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Minimum charges</label>
                        <input type='number' id='input-username' className='form-control' placeholder='Minimum charges'
                          value={this.state.min_charge} min="1"
                          onChange={(e) => this.onChangeAddName('min_charge', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrmin_charge}</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>1 Mile charge</label>
                        <input type='number' id='input-username' className='form-control' placeholder='1 Mile charge'
                          value={this.state.per_mile_charge} min="1"
                          onChange={(e) => this.onChangeAddName('per_mile_charge', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrper_mile_charge}</span>
                      </div>
                    </div>

                    {/* <div className='col-lg-4'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>1 minute charge</label>
                        <input type='text' id='input-username' className='form-control' placeholder='Tax (%)'
                        value={this.state.tax} 
                        onChange={(e) => this.onChangeAddName('tax', e.target.value)}
                        />

                        <span style={{ color: 'red' }}>{this.state.formErrtax}</span>
                      </div>
                    </div> */}
                  </div>
                  <div className='row'>

                    <div className='col-lg-6'>

                      <img src={this.state.picture === '' ? '../assets/img/theme/img-1-1000x600.jpg' : this.state.file} alt='Image placeholder' className='card-img-top' />
                      <span style={{ color: 'red' }}>{this.state.formErrpicture}</span>
                      <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                        <Input
                          type="file"
                          // id="File-2"
                          className='btn btn-default float-right'
                          onChange={this.handleUploadFile}
                        >Upload</Input>
                      </div>
                    </div>

                    <div className='col-lg-6'>

                      <label className='form-control-label' for='input-username'>Description</label>
                      <input type='text' id='input-username' className='form-control' placeholder='Vehicle description'
                        value={this.state.desc}
                        onChange={(e) => this.onChangeAddName('desc', e.target.value)}
                      />
                      <span style={{ color: 'red' }}>{this.state.formErrdesc}</span>
                    </div>
                  </div>
                  <div className='row'>

                    <div className='col-lg-12'>
                      <div className='form-group'>
                        <label className='form-control-label' for='input-username'>Additional delivery charges</label>
                        <input type='number' min="1" id='input-username' className='form-control' placeholder='minutes'
                          value={this.state.additional_charge}
                          onChange={(e) => this.onChangeAddName('additional_charge', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                  : ''}

              </div>
            </div>

            <div className='col-xl-8 order-xl-1'>
              <div className='card'>
                <div className='card-body'>
                  <form>
                    <div className='pl-lg-4'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Business Name</label>
                            <input type='text' id='input-username' className='form-control'
                              value={this.state.name} onChange={(e) => this.onChangeAddName('name', e.target.value)}
                              placeholder='Business Name' />

                            <span style={{ color: 'red' }}>{this.state.formErrname}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-email'>Business Owner Name</label>
                            <input type='email' id='input-email' className='form-control' placeholder='Business Owner Name'
                              value={this.state.business_owner_name} onChange={(e) => this.onChangeAddName('business_owner_name', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrbusiness_owner_name}</span>

                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>
                              Business Description
                            </label>
                            <textarea
                              rows='4'
                              className='form-control'
                              placeholder='A few words about you ...'
                              onChange={(e) =>
                                this.onChangeAddName(
                                  'description',
                                  e.target.value
                                )
                              }
                              required
                              value={this.state.description}
                            >
                              {this.state.description}
                            </textarea>
                            <span style={{ color: 'red' }}>
                              {this.state.formErrdescription}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Country</label>
                            {/* <Input
                              value={this.state.country}
                              type="select"
                              onChange={(e) => this.onChangeAddName('country', e.target.value)}
                            >
                              <option value="">Country Names</option>
                              {this.props.countrylisting.map((option) => (
                                <option value={option._id}>{option.country_name}</option>
                              ))}
                            </Input> */}
                            {/* <select
                              value={this.state.country}
                              // type="select"
                              // className="form-control"
                              onChange={(e) => this.onChangeAddName('country', e.target.value)}
                            >
                              <option value="">Country Names</option>
                              {this.state.country_list.map((option) => (
                                <option value={option.sortname}>{option.name}</option>
                              ))}
                            </select> */}
                            <input type='text' id='input-first-name' className='form-control' placeholder='country'
                              value={this.state.country}
                              onChange={(e) => this.onChangeAddName('country', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcountry}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-last-name'>City</label>
                            {/* <input type='text' id='input-last-name' className='form-control' placeholder='Business owner Email' /> */}
                            {/* <Input
                              value={this.state.city}
                              type="select"
                              onChange={(e) => this.onChangeAddName('city', e.target.value)}
                            >
                              <option value="">City Names</option>
                              {this.props.citylisting.map((option) => (
                                <option value={option._id}>{option.city_name}</option>
                              ))}
                            </Input> */}
                            {/* <select
                              value={this.state.city}
                              // type="select"
                              onChange={(e) => this.onChangeAddName('city', e.target.value)}
                            >
                              <option value="">City Names</option>
                              {worldMapData.getAllStatesFromCountry(this.state.country_name).map((option) => (
                                <option value={option.sortname}>{option.name}</option>
                              ))}
                            </select> */}
                            <input type='text' id='input-first-name' className='form-control' placeholder='city'
                              value={this.state.city}
                              onChange={(e) => this.onChangeAddName('city', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcity}</span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Business owner Contact</label>
                            <PhoneInput
                              country={'us'}
                              // value={this.state.country_code}
                              // onChange={(e) => this.onChangeAddName('country_code', e.target.value)}
                              onChange={country_code => this.setState({ country_code: '+' + country_code })}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcountry_code}</span>
                            <input type='text' id='input-first-name' className='form-control' placeholder='Business owner Contact'
                              value={this.state.mobile_no}
                              onChange={(e) => this.onChangeAddName('mobile_no', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrmobile_no}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-last-name'>Business owner Email</label>
                            <input type='text' id='input-last-name' className='form-control' placeholder='Business owner Email'
                              value={this.state.email} onChange={(e) => this.onChangeAddName('email', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErremail}</span>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Business Open Time</label>
                            <Input
                              value={this.state.slotOpenHour}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotOpenHour', e.target.value)}
                            >
                              {data.hours.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>&nbsp;</label>
                            <Input
                              value={this.state.slotOpenMin}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotOpenMin', e.target.value)}
                            >
                              {data.minutes_second.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        {/* <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>&nbsp;</label>
                            <Input
                              // value={this.state.businessName}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotOpenFormat', e.target.value)}
                            >
                              {data.format.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div> */}
                        {/* <span style={{ color: 'red' }}>{this.state.formErropen_time}</span> */}
                      </div>
                      <div className='row'>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>Business Close Time</label>
                            <Input
                              value={this.state.slotCloseHour}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotCloseHour', e.target.value)}
                            >
                              {data.hours.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>&nbsp;</label>
                            <Input
                              value={this.state.slotCloseMin}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotCloseMin', e.target.value)}
                            >
                              {data.minutes_second.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <img
                              src={
                                this.state.business_logo === ''
                                  ? '../assets/img/theme/img-1-1000x600.jpg'
                                  : this.state.file1
                              }
                              alt='Business logo here'
                              className='card-img-top'
                            />
                            <span style={{ color: 'red' }}>
                              {this.state.formErrbusiness_logo}
                            </span>
                            <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                              <Input
                                type='file'
                                // id="File-2"
                                className='btn btn-default float-right'
                                onChange={this.handleUploadFile1}
                              >
                                Upload
                              </Input>
                            </div>

                          </div>
                          <div className='col-lg-6'>
                            <label className='form-control-label' for='input-last-name'>Business Website url</label>
                            <input type='text' id='input-last-name' className='form-control' placeholder='Business Website url'
                              value={this.state.website} onChange={(e) => this.onChangeAddName('website', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrwebsite}</span>

                          </div>
                        </div>

                        {/* <div className='col-lg-4'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-first-name'>&nbsp;</label>
                            <Input
                              // value={this.state.businessName}
                              type="select"
                              onChange={(e) => this.onChangeAddName('slotCloseFormat', e.target.value)}
                            >
                              {data.format.map((option) => (
                                <option value={option}>{option}</option>
                              ))}
                            </Input>
                          </div>
                        </div> */}
                        {/* <span style={{ color: 'red' }}>{this.state.formErrclose_time}</span> */}
                      </div>

                      <div className='row'>
                        <div className='col-lg-6'>
                          {/* <div className='form-group'> */}
                          <label className='form-control-label' for='input-first-name'>Business Location</label>
                          <input type='text' id='input-first-name' className='form-control' placeholder='Business Location'
                            value={this.state.location} onChange={(e) => this.onChangeAddName('location', e.target.value)}
                          />
                          {/* <span style={{ color: 'red' }}>{this.state.formErrlocation}</span> */}
                          {/* </div> */}
                          <div
                            style={{
                              height: '500px',
                              width: '500px'
                            }}
                          >
                            <Map
                              center={{ lat: this.state.latitude, lng: this.state.longitude }}
                              handlelatlong={(e) => this.handlelatlong(e)}
                              handleAddress={(e) => this.handleAddress(e)}
                              handlepincode={(e) => this.handlepincode(e)}
                              handlecityname={(e) => this.handlecityname(e)}
                              height='400px'
                              value={this.state.location}
                              // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                              zoom={15}
                            />
                          </div>
                          <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-header'>
                                <div className='row align-items-center'>
                                  <div className='col-6'>
                                    <h3 className='mb-0'>Privacy Policy</h3>
                                  </div>
                                  <div className='col-6'>
                                    {/* <h3 className='mb-0'>About Us</h3> */}
                                    {/* <Button
                                      className="btn-default text-white mr-10 float-right" 

                                      onClick={() => this.update()}
                                      >Update</Button> */}
                                    {this.state.isEdit1 ?
                                      <div>
                                        <button onClick={() => this.update()} className="btn btn-primary float-right" type="button">
                                          Update
                                        </button>
                                        <button onClick={() => this.cancelUpdate()} className="btn btn-light float-right" style={{ position: "relative`", right: '10px' }} type="button">
                                          Cancel
                                        </button>
                                      </div>
                                      :
                                      <button onClick={() => this.editPrivacyPolicy()} className="btn btn-primary float-right" type="button">
                                        Edit Privacy Policy
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>

                              {this.state.isEdit1 ?
                                <div className='card-body'>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.privacy_policy}
                                    //  onChange={this.handleEditorChange1}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.handleEditorChange1(data)
                                    }}

                                  />
                                </div>
                                :
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="card">
                                      <div className="card-header border-0">
                                        <div className="row align-items-center">
                                          <div className="col">
                                            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.privacy_policy }}></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-header'>
                                <div className='row align-items-center'>
                                  <div className='col-6'>
                                    <h3 className='mb-0'>About us</h3>
                                  </div>
                                  <div className='col-6'>
                                    {/* <h3 className='mb-0'>About Us</h3> */}
                                    {/* <Button
                                      className="btn-default text-white mr-10 float-right" 

                                      onClick={() => this.update()}
                                      >Update</Button> */}
                                    {this.state.isEdit2 ?
                                      <div>
                                        <button onClick={() => this.update()} className="btn btn-primary float-right" type="button">
                                          Update
                                        </button>
                                        <button onClick={() => this.cancelUpdate2()} className="btn btn-light float-right" style={{ position: "relative`", right: '10px' }} type="button">
                                          Cancel
                                        </button>
                                      </div>
                                      :
                                      <button onClick={() => this.editabout_us()} className="btn btn-primary float-right" type="button">
                                        Edit About us
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>

                              {this.state.isEdit2 ?
                                <div className='card-body'>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.about_us}
                                    //  onChange={this.handleEditorChange1}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.handleEditorChange2(data)
                                    }}

                                  />
                                </div>
                                :
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="card">
                                      <div className="card-header border-0">
                                        <div className="row align-items-center">
                                          <div className="col">
                                            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.about_us }}></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='col'>
                            <div className='card'>
                              <div className='card-header'>
                                <div className='row align-items-center'>
                                  <div className='col-6'>
                                    <h3 className='mb-0'>Terms and condition</h3>
                                  </div>
                                  <div className='col-6'>
                                    {/* <h3 className='mb-0'>About Us</h3> */}
                                    {/* <Button
                                      className="btn-default text-white mr-10 float-right" 

                                      onClick={() => this.update()}
                                      >Update</Button> */}
                                    {this.state.isEdit3 ?
                                      <div>
                                        <button onClick={() => this.update()} className="btn btn-primary float-right" type="button">
                                          Update
                                        </button>
                                        <button onClick={() => this.cancelUpdate3()} className="btn btn-light float-right" style={{ position: "relative`", right: '10px' }} type="button">
                                          Cancel
                                        </button>
                                      </div>
                                      :
                                      <button onClick={() => this.editterms_condition()} className="btn btn-primary float-right" type="button">
                                        Edit Terms and condition
                                      </button>
                                    }
                                  </div>
                                </div>
                              </div>

                              {this.state.isEdit3 ?
                                <div className='card-body'>
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.terms_condition}
                                    //  onChange={this.handleEditorChange1}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.handleEditorChange3(data)
                                    }}

                                  />
                                </div>
                                :
                                <div className="row">
                                  <div className="col-xl-12">
                                    <div className="card">
                                      <div className="card-header border-0">
                                        <div className="row align-items-center">
                                          <div className="col">
                                            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.terms_condition }}></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                    <button
                      className='btn btn-success float-right'
                      type='button'
                      onClick={(e) => this.handleSubmit()}
                    >Save</button>
                    <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                  &copy; 2021  ServiceHubConnect
                </div>
              </div>
            </div>
          </footer>
        </div>

      </div>
    )
  }
}

const mapPropsToState = (state) => {
  return {
    success: state.bussiness.success,
    error: state.bussiness.error,
    alllisting: state.subCategory.alllisting,
    bussinesslisting: state.bussiness.bussinesslisting,
    countrylisting: state.country.countrylisting,
    citylisting: state.city.citylisting,
    data_bussiness: state.bussiness.data_bussiness,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    addBusinessAction: (data, history, datas) => dispatch(actions.addBusiness(data, history, datas)),
    updateBusinessLogoAction: (data, business_id) => dispatch(actions.updateBusinessLogo(data, business_id)),
    fetchallsubCategory: () => dispatch(actions.fetchallsubCategoryListAction()),
    fetchbussiness: () => dispatch(actions.fetchbussinessListAction()),
    fetchcountry: () => dispatch(actions.fetchcountrysListAction()),
    fetchcity: (data) => dispatch(actions.fetchcitysListAction(data)),
    getbusinessOnIdAction: (data) => dispatch(actions.getbusinessOnId(data)),
    editBusinessAction: (business_id, data, history) => dispatch(actions.editBusiness(business_id, data, history)),
    editrideshareAction: (id, data) => dispatch(actions.editrideshare(id, data)),
    editrideshareImageAction: (id, data) => dispatch(actions.editrideshareImage(id, data)),

    addrideshareDataAction: (data, history) => dispatch(actions.addrideshareData(data, history)),

  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(withRouter(AddBusiness)))

