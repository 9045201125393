import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";

class AdminUser extends React.Component {
  state ={
    adminUserId : "",
    open: false
  }
  componentWillMount() {
    this.props.fetchAdminUserList();
  }
  componentDidMount (){
    this.props.fetchAdminUserList();
  }
  deleteUserPermanently() {
		if(this.state.adminUserId) {
			this.props.deleteAdminUser(this.state.adminUserId);
      this.close()
		}
  }
  
  handleOpenDelete = (id) => {
    
		if(id) {

      this.setState({ 
        adminUserId : id,
        open:true 
      });
		}
	
	}
  open() {
    this.setState({ open: true });
  }

  close() {
      this.setState({ open: false });
  }
  changeActiveStatus (id,is_active) {
    
    if(id){
      this.props.editAdminUser(id,{is_active:!is_active});
    }
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(this.props);
    const { enqueueSnackbar, error, success } = this.props;
    success && enqueueSnackbar(success, { variant: "success" });
    error && enqueueSnackbar(error, { variant: "error" });
  }
  render(){
    
    var sno = 1

    let adminUserList = [];
    let adminUserTable = <Spinner />;
    
    {this.props.adminUserListing.length > 0?
      this.props.adminUserListing.map(datas=>{
        if(datas._id !== localStorage.getItem("userId")){
          adminUserList.push(
            {
          
              s_no:(sno++),
              name:datas.name,
              email:datas.email,
              mobile_no:datas.mobile_no,
              action:<div>
            {datas.is_active == true?<button class='btn btn-icon btn-success btn-sm' type='button' 
            onClick={(id) =>this.changeActiveStatus(datas._id,datas.is_active)}
            >
                <span class='btn-inner--icon'><i class='ni ni-check-bold' /></span>
              </button>:
              <button class='btn btn-icon btn-danger btn-sm' type='button' 
              onClick={(id) =>this.changeActiveStatus(datas._id,datas.is_active)}
              >
                <span class='btn-inner--icon'><i class='ni ni-fat-remove' /></span>
              </button>}
              
              <button class='btn btn-icon btn-danger btn-sm' type='button' 
              onClick={(id) =>this.handleOpenDelete(datas._id)}
              >
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </button>
              <NavLink className='btn btn-icon btn-default btn-sm' to={'/editadminuser/'+datas._id} >
                <span className='btn-inner--icon'><i className='fas fa-pencil-alt' /></span>
              </NavLink>
            </div>
          
            }
          )
        }
        
      })
      
      :adminUserList.push(
        ["No record"]
        )}
        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Admin User Name",
              field: "name",
              sort: "asc",
            },
            {
              label: "Email",
              field: "email",
              sort: "asc",
            },
            {
              label: "mobile_no",
              field: "mobile_no",
              sort: "asc",
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
            },
          ],
          rows: adminUserList,
        };
    
        adminUserTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Admin User</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
            <Dialog
                      open={this.state.open}
                      onClose={() => this.close()}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                  <DialogTitle id="alert-dialog-title">Do you want to delete Business Owner?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.close()}>
                              No
                    </button>
                    <button class='btn btn-icon btn-success btn-sm' onClick={()=>this.deleteUserPermanently()} type='button'>
                              Yes
                    </button>
                  </DialogActions>
              </Dialog>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6'>
                    </div>
                    <div className='col-lg-6'>
                      <Link class='btn btn-primary float-right' to='/addadminuser'>Add User</Link>
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  {adminUserTable}
                </div>
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
  
}
const mapPropsToState = (state) => {
  return {
    success: state.adminUser.success,
    error: state.adminUser.error,
    adminUserListing: state.adminUser.adminUserListing
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminUserList: () => dispatch(actions.fetchAdminUserListAction()),
    editAdminUser: (data,is_active) => dispatch(actions.editAdminUserAction(data,is_active)),
    deleteAdminUser: (data) => dispatch(actions.deleteAdminUserAction(data)),
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(AdminUser));
