import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import ReactPlayer from 'react-player'
import LinearProgress from "@material-ui/core/LinearProgress";
import ReactLoading from "react-loading";
import {
  Input,
} from 'reactstrap'

import { withRouter } from "react-router-dom";
import worldMapData from 'city-state-country';

class AddCouponRankingPicture extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      picture: '',
      formErrpicture: '',
      business_id:'',
      video:'',
      videoFile:'',
      formErrVideo: '',
      uploadingPercentage:0
    }
    this.handleUploadFile = this.handleUploadFile.bind(this)
    // this.handleUploadFile1 = this.handleUploadFile1.bind(this)
  }

  async updateState(data) {
    // await this.setState(data);
    setTimeout(async () => {
      await this.setState(data)
    }, 1000)
  }
  
  componentDidUpdate(prevProps, prevState) {
    
    const { enqueueSnackbar, error, success,loading } = this.props;
    success && enqueueSnackbar(success, { variant: 'success' });
    error && enqueueSnackbar(error, { variant: 'error' });
    // loading && enqueueSnackbar (loading,{variant:'loading'})
  }

  handleUploadFile = async (event) => {
      await this.setState({
        file: URL.createObjectURL(event.target.files[0]), picture: event.target.files[0],
        formErrpicture: ''
      })
      
    }

    uploadPicture = async(event)=>{
       this.setState({
        formErrpicture:  this.state.picture === '' ? "Please provide this field" : '',
      })
      if(this.state.picture !== ''){
        const data = await new FormData();
        await data.append('picture', this.state.picture);
        await data.append('businessId', this.state.business_id)
        console.log(data,'what is erehyhh')
        await this.props.businessPictureUpload(data)
        await this.setState({picture:''})
      }
     
      
    }

    handleUploadVideoFile = async (event) => {
      await this.setState({
        videoFile: URL.createObjectURL(event.target.files[0]), video: event.target.files[0],
        formErrVideo: ''
      })
      
    }

    uploadVideo = async(event)=>{
      this.setState({
        formErrVideo:  this.state.video === '' ? "Please provide this field" : '',
     })
     if(this.state.video !== ''){
     
       const data = await new FormData();
       await data.append('video', this.state.video);
       await data.append('type', 'video')
       await data.append('businessId', this.state.business_id)
       await this.props.businessVideoUpload(data)
     }
    }
    
    deletePicture = async(event)=>{
      const data = {
        businessId:this.state.business_id,
        pictureUrl:event.pictureUrl
      }
      await this.props.businessPictureDelete(data)
    }
  componentDidMount = async () => {
    let business_id = await this.props.match.params.id;
    if (business_id) {
      await this.setState({
        business_id: business_id,
      });
      await this.props.getbusinessMediaOnId(business_id);
      await this.setState({picture:''})
    }
  };
 

  render() {
     console.log(this.props.businessPicture,'hello')
    let images =this.props.businessPicture? this.props.businessPicture.length ?
    this.props.businessPicture.map((e)=>
    <div>
     <img src = {e.pictureUrl} width='200px' height='200px'/>
      <button onClick={(event)=> this.deletePicture(e)} color='red'>Delete</button>
    </div>
     
    )
    :'':''

    return (
      <div>
        <div className='header bg-primary pb-5'>
        </div>
          <div className='container-fluid py-1' >
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-xl-6 order-xl-2'>
                  <div className='card'>
                    <div className='card-header'>
                      <div className='row align-items-center'>
                        <div className='col-12'>
                          <h3 className='mb-0'>Business Picture </h3>
                        </div>
                      </div>
                    </div>

                    <div className='card-body'>
                      <img
                        src={
                          this.state.picture === ''
                            ? '../assets/img/theme/img-1-1000x600.jpg'
                            : this.state.file
                        }
                        alt='Image placeholder'
                        className='card-img-top'
                        style={{'maxHeight':'100px','maxWidth':'100px'}}
                      />
                      <span style={{ color: 'red' }}>
                        {this.state.formErrpicture}
                      </span>
                      <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                        <Input
                          type='file'
                          // id="File-2"
                          className='btn btn-default float-right'
                          onChange={this.handleUploadFile}
                        >
                          Upload
                        </Input>
                        <button onClick={this.uploadPicture}>Upload Picture</button>
                      </div>
                      
                      {this.props.progress > 0 ?<LinearProgress variant="determinate" value={this.props.progress} />:''}
                    </div>
                  <div>
                    { images}
                  </div>
                  </div>
                </div>
              </div>
            </div>
            {
                            this.props.loading ?
                                <div className='loader-wrapper'>
                                    <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                </div> : ''
                        }
          </div>
        <footer className='footer pt-0'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6'>
              <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021 ServiceHubConnect
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }

}

const mapPropsToState = (state) => {
  return {
    success: state.bussinessMedia.success,
    error: state.bussinessMedia.error,
    businessPicture:state.bussinessMedia.businessPicture,
    loading:state.bussinessMedia.loading,
    progress:state.bussinessMedia.progress,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getbusinessMediaOnId: (data) => dispatch(actions.getbusinessMediaOnId(data)),
    businessPictureUpload: (data) => dispatch(actions.couponRankingBusinessPictureUpload(data)),
    businessPictureDelete :(data) => dispatch(actions.couponRankingBusinessPictureDelete(data))
  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(withRouter(AddCouponRankingPicture)))

