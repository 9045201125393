import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";
import {useHistory} from 'react-router-dom';



export const startAdvertiseSubscription = () => {
  return {
    type: actionTypes.START_ADVERTISEMENT_SUBSCRIPTION_PLAN
  };
};

export const advertiseSubscriptionFail = message => {
  return {
    type: actionTypes.ADVERTISEMENT_SUBSCRIPTION_PLAN_FAIL,
    message
  };
};

export const advertiseSubscriptionSuccess = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_SUCCESS,
      subscriptionPlanlist: data,
    };
  };
  
  export const advertiseSubscriptionList = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/get-advertisement-subscription-listing" ,data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionSuccess(response.data.data,response.data.message))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };
  export const advertiseSubscriptionPlanDetail = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_PLAN_DETAILS,
      subscriptionPlanDetails: data,
    };
  };

export const getAdvertiseSubscriptionPlanDetails = (Id) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("admin/get-advertisement-subscription-details/"+Id, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionPlanDetail(response.data.data,response.data.message))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

export const editAdvertiseSubscriptionPlanDetails = (data,history) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/edit-advertisement-subscription",data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionPlanDetail(response.data.data,response.data.message))
                const load = () => { history.push(`/edit-advertise-subscription/${response.data.data._id}`) }
                setTimeout(load, 2000);
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };  
  

  export const deleteSubscriptionPlan = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/delete-advertisement-subscription" ,data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionList({subscription_type:"monthly"}))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };
  // ADD_ADVERTISEMENT_SUBSCRIPTION_PLAN

  export const addSubscriptionPlan = (data,history) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/create-advertisement-subscription" ,data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscriptionList({subscription_type:"monthly"}));
                history.push('/advertise-subscription')
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };


  export const advertiseSubscribedUserListSuccess = (data) => {
    return {
      type: actionTypes.GET_ADVERTISEMENT_SUBSCRIBED_USER_LIST,
      subscribedUserList: data,
    };
  };


  export const advertiseSubscribedUserList = () => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("admin/get-advertisement-subscribed-user-list" , {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscribedUserListSuccess(response.data.data,response.data.message))
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };

  export const deactivateBusinessUserPlan = (data) => {
    return dispatch => {
      dispatch(startAdvertiseSubscription());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("admin/deactivate-business-user-plan" ,data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) {
                dispatch(advertiseSubscribedUserList())
                window.location.reload(true)
            } else {
              dispatch(advertiseSubscriptionFail(response.data.message));
            }
          } else {
            dispatch(advertiseSubscriptionFail(response.message));
          }
        })
        .catch(err => {
          dispatch(advertiseSubscriptionFail(err.message));
        });
    };
  };