import React, { Component } from 'react'
import { Redirect } from "react-router-dom";

import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
class Dashboard extends Component  {
  componentWillMount() {
    this.props.fetchDashboardAction();
    this.props.fetchpagesList({
      page:"about_us"
    });
    this.props.getConactUsAction();
  }
  render() {
    const userToken = localStorage.getItem('token')
    // console.log(userToken)
    let authRedirect = null
    if (!userToken) { 
      authRedirect = <Redirect from="./Dashboard" to= "/"/>
    }
    return (
      <div>
        {authRedirect}
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Dashboard</h6>
                </div>
              </div>
              <div className='row'>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total numbers of <br />order per day</h5>
                          <span className='h2 font-weight-bold mb-0'>{(this.props.data_dashboard !== undefined ?this.props.data_dashboard.data:0)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total amount generated <br />per day</h5>
                          <span className='h2 font-weight-bold mb-0'>{this.props.data_dashboard !== undefined ?((this.props.data_dashboard.amount[0].total === null)?0:this.props.data_dashboard.amount[0].total).toFixed(2):0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total number of cancelled order per day</h5>
                          <span className='h2 font-weight-bold mb-0'>{this.props.data_dashboard !== undefined ?this.props.data_dashboard.cancelled:0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total number of connected customers</h5>
                          <span className='h2 font-weight-bold mb-0'>{this.props.data_dashboard !== undefined ?this.props.data_dashboard.customer:0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total number of connected professionals </h5>
                          <span className='h2 font-weight-bold mb-0'>{this.props.data_dashboard !== undefined ?this.props.data_dashboard.professional:0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-2 col-md-4'>
                  <div className='card card-stats'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>Total number of available professional per day</h5>
                          <span className='h2 font-weight-bold mb-0'>{this.props.data_dashboard !== undefined ?this.props.data_dashboard.professional:0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h3 className='mb-0'>Map</h3>
                    </div>
                  </div>
                </div>
                <div style={{ height: '400px' }} />
              </div>
            </div>

          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
  )
}
}

const mapPropsToState = (state) => {
  return {
    data_dashboard: state.auth.data_dashboard,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDashboardAction: () => dispatch(actions.fetchDashboard()),
    fetchpagesList: (data) => dispatch(actions.fetchpagesListAction(data)),
    getConactUsAction: () => dispatch(actions.getConactUs()),

  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(Dashboard));
