import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const progressBar = (progress) => {
    return {
        type: actionTypes.PROGRESS_BAR,
        progress: progress,
    };
};
//   startAdvertisementPicture
//uploading pictures
export const startBlogPriceAdvertiseMedia = () => {
    return {
        type: actionTypes.START_BLOGPRICE_ADVERTISEMENT_MEDIA
    };
};
//   AdvertisementPictureFail
export const blogPriceAdvertiseMediaFail = message => {
    return {
        type: actionTypes.ADVERTISEMENT_PICTURE_FAIL,
        message
    };
};

//   getAdvertismentPictureSuccess

export const getBlogPriceAdvertisePictureSuccess = (data) => {
    return {
        type: actionTypes.GET_BLOGPRICE_ADVERTISEMENT_PICTURE_SUCCESS,
        blogPriceAdvertisePicture: data,
    };
};
export const getBlogPriceAdvertisementPictureOnId = (data) => {
    
    return dispatch => {
        dispatch(startBlogPriceAdvertiseMedia());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/admin/get-blog-price-advertise-media", data, {
                headers: { Authorization: authCode }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getBlogPriceAdvertisePictureSuccess(response.data.data))
                    } else {
                        dispatch(blogPriceAdvertiseMediaFail(response.data.message));
                    }
                } else {
                    dispatch(blogPriceAdvertiseMediaFail(response.message));
                }
            })
            .catch(err => {
                dispatch(blogPriceAdvertiseMediaFail(err.message));
            });
    };
};

//   getAdvertisementPictureUploadSucess

export const getBlogPriceAdvertisePictureUploadSucess = (data, message) => {
    return {
        type: actionTypes.BLOGPRICE_ADVERTISEMENT_PICTURE_UPLOAD_SUCCESS,
        blogPriceAdvertisePicture: data,
        message
    };
};

export const blogPriceAdvertisePictureUpload = (data) => {

    return dispatch => {
        dispatch(startBlogPriceAdvertiseMedia());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/admin/upload-blog-price-advertise-media", data, {
                headers: { Authorization: authCode, 'content-type': 'multipart/form-data' },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    let progress = Math.round((100 * data.loaded) / data.total)
                    dispatch(progressBar(progress))
                },
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getBlogPriceAdvertisePictureUploadSucess(response.data.data, response.data.message))
                        dispatch(progressBar(0))
                    } else {
                        dispatch(blogPriceAdvertiseMediaFail(response.data.message));
                        dispatch(progressBar(0))
                    }
                } else {
                    dispatch(blogPriceAdvertiseMediaFail(response.message));
                    dispatch(progressBar(0))
                }
            })
            .catch(err => {
                dispatch(blogPriceAdvertiseMediaFail(err.message));
                dispatch(progressBar(0))
            });
    };
};

export const getBlogPriceAdvertisementPictureDeleteSuccess = (data, message) => {
    return {
        type: actionTypes.BLOGPRICE_ADVERTISEMENT_PICTURE_DELETE_SUCCESS,
        blogPriceAdvertisePicture: data,
        message
    };
};

export const blogPriceAdvertisementPictureDelete = (data) => {
    return dispatch => {
        dispatch(startBlogPriceAdvertiseMedia());
        const authCode = "Bearer " + localStorage.getItem("token");
        axios
            .post("/admin/delete-blog-price-advertise-media", data, {
                headers: { Authorization: authCode },
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.status === 200) {
                        dispatch(getBlogPriceAdvertisementPictureDeleteSuccess(response.data.data, response.data.message))
                    } else {
                        dispatch(blogPriceAdvertiseMediaFail(response.data.message));
                    }
                } else {
                    dispatch(blogPriceAdvertiseMediaFail(response.message));
                }
            })
            .catch(err => {
                dispatch(blogPriceAdvertiseMediaFail(err.message));
            });
    };
};

//blog and price video get upload and delete

export const getBlogPriceAdvertismentVideoSuccess = (data) => {
    return {
      type: actionTypes.GET_BLOGPRICE_ADVERTISEMENT_VIDEO_SUCCESS,
      blogPriceAdvertiseVideo: data,
    };
};

export const getBlogPriceAdvertisementVideoOnId = (data) => {
  return dispatch => {
    dispatch(startBlogPriceAdvertiseMedia());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/get-blog-price-advertise-media" ,data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) { 
            dispatch(getBlogPriceAdvertismentVideoSuccess(response.data.data))
          } else {
            dispatch(blogPriceAdvertiseMediaFail(response.data.message));
          }
        } else {
          dispatch(blogPriceAdvertiseMediaFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogPriceAdvertiseMediaFail(err.message));
      });
  };
};


export const getAdvertisementVideoUploadSucess = (data,message) => {
  return {
    type: actionTypes.BLOGPRICE_ADVERTISEMENT_VIDEO_UPLOAD_SUCCESS,
    blogPriceAdvertiseVideo: data,
    message
  };
};

export const blogPriceAdvertisementVideoUpload = (data) => { 
  return dispatch => {
    dispatch(startBlogPriceAdvertiseMedia());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/upload-blog-price-advertise-media" , data, {
        headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          let progress = Math.round((100 * data.loaded) / data.total)
          dispatch(progressBar(progress))
        },
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) { 
            dispatch(getAdvertisementVideoUploadSucess(response.data.data,response.data.message))
            dispatch(progressBar(0))
          } else {
            dispatch(blogPriceAdvertiseMediaFail(response.data.message));
            dispatch(progressBar(0))
          }
        } else {
          dispatch(blogPriceAdvertiseMediaFail(response.message));
          dispatch(progressBar(0))
        }
      })
      .catch(err => {
        dispatch(blogPriceAdvertiseMediaFail(err.message));
        dispatch(progressBar(0))
      });
  };
};


export const getAdvertisementVideoDeleteSuccess = (data,message) => {
  return {
    type: actionTypes.BLOGPRICE_ADVERTISEMENT_VIDEO_DELETE_SUCCESS,
    blogPriceAdvertiseVideo: data,
    message
  };
};

export const blogPriceAdvertisementVideoDelete = (data) => {
  return dispatch => {
    dispatch(startBlogPriceAdvertiseMedia());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/admin/delete-blog-price-advertise-media", data, {
        headers: { Authorization: authCode },
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getAdvertisementVideoDeleteSuccess(response.data.data,response.data.message))
          } else {
            dispatch(blogPriceAdvertiseMediaFail(response.data.message));
          }
        } else {
          dispatch(blogPriceAdvertiseMediaFail(response.message));
        }
      })
      .catch(err => {
        dispatch(blogPriceAdvertiseMediaFail(err.message));
      });
  };
};


