import React from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
import LinearProgress from "@material-ui/core/LinearProgress";
import ReactLoading from "react-loading";
import { Input } from "reactstrap";
import { withRouter } from "react-router-dom";

class AddCouponRankingBusinessAdPicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      picture: "",
      formErrpicture: "",
      business_id: "",
      uploadingPercentage: 0,
    };
  }

  handleUploadFile = async (event, imageType) => {
    this.setState({
      [imageType]: URL.createObjectURL(event.target.files[0]),
      picture: event.target.files[0],
      formErrpicture: "",
    });
  };
  onDelete  = async (imageType) => {
    const data = {
        "picture": this.state.picture,
        "imageType": imageType,
        "businessId": this.state.business_id,
      };
      await this.props.businessPictureDelete(data);
      await this.props.getbusinessMediaOnId(this.state.business_id);

      this.setState({ picture: "" });
    
  };
  uploadPicture = async (imageType) => {
    this.setState({
      formErrpicture: this.state.picture === "" ? "Please provide this field" : "",
    });
    if (this.state.picture !== "") {
      const data = new FormData();
      data.append("picture", this.state.picture);
      data.append("imageType", imageType); // Send image type to differentiate (e.g., banner, slide a, etc.)
      data.append("businessId", this.state.business_id);
      await this.props.businessPictureUpload(data);
      await this.props.getbusinessMediaOnId(this.state.business_id);

      this.setState({ picture: "" });
    }
  };

  componentDidMount = async () => {
    let business_id = this.props.match.params.id;
    if (business_id) {
      await this.setState({ business_id });
      await this.props.getbusinessMediaOnId(business_id);
    }
  };

  render() {
    console.log(this.props, "this.props")
    const { slide } = this.props;
    console.log(slide)
    const bannerImage = slide?.banner;
    const slideAdsLeft = [slide?.slideA, slide?.slideC, slide?.slideE];
    const slideAdsRight = [slide?.slideB, slide?.slideD, slide?.slideF];;

    return (
      <div className="container">
        <div className="header bg-primary pb-5"></div>

        {/* Banner Image */}
        <div className="banner-section text-center">
          <h3 className="mb-3">Banner Image</h3>
          <img
            src={slide?.banner || "../assets/img/theme/img-1-1000x600.jpg"}
            alt="Banner"
            className="img-fluid"
            style={{ maxHeight: "200px", maxWidth: "100%" }}
          />
          <Input
            type="file"
            onChange={(event) => this.handleUploadFile(event, "banner")}
          />
          <button onClick={() => this.uploadPicture("banner")}>Upload Banner</button>
          <button
            onClick={() => this.onDelete(`banner`)}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
          </button>
        </div>

        <div className="row mt-5">
          {/* Left Column */}
          <div className="col-md-4">
            <h3 className="mb-3">Slide Ads A, C, E</h3>
              <div key={"slideA"} className="mb-3">
                <img
                  src={slide?.slideA || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide A`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideA`)}
                />
                <button onClick={() => this.uploadPicture(`slideA`)}>
                  Upload Slide A
                </button>
                <button
                  onClick={() => this.onDelete(`slideA`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>

              </div>
              <div key={"slideC"} className="mb-3">
                <img
                  src={slide?.slideC || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide C`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideC`)}
                />
                <button onClick={() => this.uploadPicture(`slideC`)}>
                  Upload Slide C
                </button>
                <button
                  onClick={() => this.onDelete(`slideC`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>
              </div>
              <div key={"slideE"} className="mb-3">
                <img
                  src={slide?.slideE || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide E`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideE`)}
                />
                <button onClick={() => this.uploadPicture(`slideE`)}>
                  Upload Slide E
                </button>
                <button
                  onClick={() => this.onDelete(`slideE`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>
              </div>
          </div>

          {/* Right Column */}
          <div className="col-md-4 offset-md-4">
            <h3 className="mb-3">Slide Ads B, D, F</h3>
              <div key={"slideB"} className="mb-3">
                <img
                  src={slide?.slideB || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide B`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideB`)}
                />
                <button onClick={() => this.uploadPicture(`slideB`)}>
                  Upload Slide B
                </button>
                <button
                  onClick={() => this.onDelete(`slideB`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>
              </div>
              <div key={"slideD"} className="mb-3">
                <img
                  src={slide?.slideD || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide D`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideD`)}
                />
                <button onClick={() => this.uploadPicture(`slideD`)}>
                  Upload Slide D
                </button>
                <button
                  onClick={() => this.onDelete(`slideD`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>
              </div>
              <div key={"slideF"} className="mb-3">
                <img
                  src={slide?.slideF || "../assets/img/theme/img-1-1000x600.jpg"}
                  alt={`Slide F`}
                  className="img-fluid"
                  style={{ maxHeight: "200px", maxWidth: "100%" }}
                />
                <Input
                  type="file"
                  onChange={(event) => this.handleUploadFile(event, `slideF`)}
                />
                <button onClick={() => this.uploadPicture(`slideF`)}>
                  Upload Slide F
                </button>
                <button
                  onClick={() => this.onDelete(`slideF`)}
                  style={{
                    border: "none",
                    background: "none",
                    cursor: "pointer",
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red", fontSize: "20px" }}></i>
                </button>
              </div>
          </div>
        </div>

        {/* Loader */}
        {this.props.loading && (
          <div className="loader-wrapper">
            <ReactLoading
              type="spin"
              color="#0074d9"
              className="loader-spiner"
              height={100}
              width={70}
            />
          </div>
        )}

        {/* Footer */}
        <footer className="footer pt-4">
          <div className="text-center text-muted">
            &copy; 2025 ServiceHubConnect
          </div>
        </footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.bussinessMedia.success,
  error: state.bussinessMedia.error,
  businessPicture: state.bussinessMedia.businessPicture,
  loading: state.bussinessMedia.loading,
  progress: state.bussinessMedia.progress,
  slide:state.bussinessMedia.slide,
});

const mapDispatchToProps = (dispatch) => ({
  getbusinessMediaOnId: (data) => dispatch(actions.getbusinessMediaOnId(data)),
  businessPictureUpload: (data) => dispatch(actions.couponRankingBusinessAdUpload(data)),
  businessPictureDelete: (data) => dispatch(actions.couponRankingBusinessAdDelete(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(AddCouponRankingBusinessAdPicture)));
