import React from 'react'
import 'react-day-picker/lib/style.css'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import {
    Input,
} from 'reactstrap'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";


class AddAdvSubscriptionPlan extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            subscription_type: 'monthly',
            formErrsubscriptionType: '',
            plan_day: '',
            formErrplanDay: '',
            advertisement_type: '',
            formErradvertisementType: '',
            price: '',
            formErrprice: '',
            planDetail: '',
            fromErrplanDetail: '',
            original_price:'',
            fromErrOriginalPrice:'',
            description_of_original_price:'',
            fromErrDesciptionofOriginalPrice:''
        }

    }


    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })

        if (key === "subscription_type") {
            await self.setState({
                subscription_type: value,
                formErrsubscriptionType: ''
            })
        }
        if (key === "plan_day") {
            await self.setState({
                plan_day: value,
                formErrplanDay: ''
            })
        }
        if (key === "advertisement_type") {
            await self.setState({
                advertisement_type: value,
                formErradvertisementType: ''
            })
        }

        if (key === "price") {
            await self.setState({
                price: value,
                formErrprice: ''
            })
        }

        if (key === "planDetail") {
            await self.setState({
                planDetail: value,
                fromErrplanDetail: ''
            })
        }
        if (key === "original_price") {
            await self.setState({
                original_price: value,
                fromErrOriginalPrice:''
            })
        }

        if (key === "description_of_original_price") {
            await self.setState({
                description_of_original_price: value,
                fromErrDesciptionofOriginalPrice:''
            })
        }

    }
    handleSubmit = async () => {
        await this.setState({
            formErrsubscriptionType: (this.state.subscription_type === '') ? "Please provide this field" : '',
            formErrplanDay: (this.state.plan_day === '') ? "Please provide this field" : '',
            formErradvertisementType: (this.state.advertisement_type === '') ? "Please provide this field" : '',
            formErrprice: (this.state.price === '') ? "Please provide this field" : '',
            fromErrplanDetail: (this.state.planDetail === '') ? "Please provide this field" : '',
            fromErrOriginalPrice: (this.state.original_price === '') ? "Please provide this field" : '',
            fromErrDesciptionofOriginalPrice: (this.state.description_of_original_price === '') ? "Please provide this field" : ''
        })

        if (this.state.subscription_type !== '' && this.state.plan_day !== ''
            && this.state.advertisement_type !== '' && this.state.price !== '' && this.state.planDetail!== '') {

            const data = {
                advertisement_type: this.state.advertisement_type,
                subscription_type: this.state.subscription_type,
                price: this.state.price,
                plan_day: this.state.plan_day,
                planDetail:this.state.planDetail,
                original_price: this.state.original_price,
                description_of_original_price: this.state.description_of_original_price,
            }
            await this.props.addSubscriptionPlan(data, this.props.history);
            await this.setState({
                subscription_type: '',
                advertisement_type: '',
                price: '',
                plan_day: '',
                planDetail:'',
                original_price:'',
                description_of_original_price:''
            })

        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success } = this.props;
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
    }
    cancelPlan() {
        this.setState({
            subscription_type: 'monthly',
            advertisement_type: '',
            price: '',
            plan_day: '',
            planDetail:'',
            original_price:'',
            description_of_original_price:''
        })
    }

    render() {

        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Add Advertisment Subscription Plan</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='clearfix'>
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Subscription Type</label>
                                                        <Input
                                                            value={this.state.subscription_type}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('subscription_type', e.target.value)}
                                                        >
                                                            {['monthly', 'yearly', 'onetime','quote'].map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrsubscriptionType}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Plan Day</label>
                                                        <Input
                                                            value={this.state.plan_day}
                                                            type="number"
                                                            min="0"
                                                            onChange={(e) => this.onChangeAddName('plan_day', e.target.value)}
                                                        >
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrplanDay}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Advertisement Description Type</label>
                                                        <Input
                                                            value={this.state.advertisement_type}
                                                            type="textarea"
                                                            onChange={(e) => this.onChangeAddName('advertisement_type', e.target.value)}
                                                        >
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErradvertisementType}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Price in Doller</label>
                                                        <Input
                                                            value={this.state.price}
                                                            type="number"
                                                            min="0"
                                                            onChange={(e) => this.onChangeAddName('price', e.target.value)}
                                                        >
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrprice}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Original Price</label>
                                                        <Input
                                                            value={this.state.original_price}
                                                            type="number"
                                                            min="0"
                                                            onChange={(e) => this.onChangeAddName('original_price', e.target.value)}
                                                        >
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.fromErrOriginalPrice}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>{this.state.subscription_type !== 'quote'?'Description Of Original Price':'Quote'}</label>
                                                        <Input
                                                            value={this.state.description_of_original_price}
                                                            type="textarea"
                                                            onChange={(e) => this.onChangeAddName('description_of_original_price', e.target.value)}
                                                        >
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.fromErrDesciptionofOriginalPrice}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form-group'>
                                                <label className='form-control-label' for='input-first-name'>Subscription Plan Details</label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data="<p>Hello from CKEditor 5!</p>"
                                                    onReady={editor => {
                                                        // You can store the "editor" and use when it is needed.
                                                    }}
                                                    config={{
                                                        link: {
                                                            decorators: {
                                                                openInNewTab: {
                                                                    mode: 'manual',
                                                                    label: 'Open in a new tab',
                                                                    defaultValue: true,			// This option will be selected by default.
                                                                    attributes: {
                                                                        target: '_blank',
                                                                        rel: 'noopener noreferrer'
                                                                    }
                                                                }
                                                            },
                                                        },
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        this.onChangeAddName('planDetail', data)
                                                    }}
                                                    value={this.state.planDetail}
                                                    onBlur={(event, editor) => {
                                                    }}
                                                    onFocus={(event, editor) => {
                                                    }}
                                                />
                                                <span style={{ color: 'red' }}>{this.state.fromErrplanDetail}</span>
                                            </div>

                                            <button
                                                className='btn btn-success float-right'
                                                type='button'
                                                onClick={(e) => this.handleSubmit()}
                                            >Save</button>
                                            <button className='btn btn-default float-right mr-4' type='button' onClick={(e) => this.cancelPlan()}>Cancel</button>
                                        </form>
                                    </div>
                                    {
                                            this.props.loading ?
                                                <div className='loader-wrapper'>
                                                    <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                                                </div> : ''
                                        }
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
        )
    }
}

const mapPropsToState = (state) => {
    return {
        success: state.advertiseSubscription.success,
        error: state.advertiseSubscription.error,
        loading: state.advertiseSubscription.loading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addSubscriptionPlan: (data, history) => dispatch(actions.addSubscriptionPlan(data, history))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(AddAdvSubscriptionPlan)))

