import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startCategoryMedia= () => {
  return {
    type: actionTypes.START_CATEGORY_MEDIA
  };
};

export const categoryMediaFail = message => {
  return {
    type: actionTypes.CATEGORY_MEDIA_FAIL,
    message
  };
};

export const getCategoryMediaSUCCESS = (data) => {
    return {
      type: actionTypes.GET_CATEGORY_MEDIA_SUCCESS,
      categoryPicture: data.categoryPicture,
      categoryVideo:data.categoryVideo
    };
  };
export const progressBar = (progress) => {
    return {
      type: actionTypes.CATEGORY_PROGRESS_BAR,
      progress: progress,
    };
  };
  // export const pictureProgressBar = (pictureProgress) => {
  //   console.log(pictureProgress,'====================================================')
  //   return {
  //     type: actionTypes.CATEGORY_PICTURE_PROGRESS,
  //     pictureProgress: pictureProgress,
  //   };
  // };

  
export const getCategoryMediaOnId = (data) => {
    return dispatch => {
      dispatch(startCategoryMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .get("/admin/get-business-user/"+data, {
          headers: { Authorization: authCode }
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              
              dispatch(getCategoryMediaSUCCESS(response.data.data))
            } else {
              dispatch(categoryMediaFail(response.data.message));
            }
          } else {
            dispatch(categoryMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(categoryMediaFail(err.message));
        });
    };
  };


export const getCategoryPictureUploadSucess = (data,message) => {
    return {
      type: actionTypes.CATEGORY_PICTURE_UPLOAD_SUCCESS,
      categoryPicture: data.categoryPicture,
      message
    };
  };

export const categoryPictureUpload = (data) => {
    return dispatch => {
      dispatch(startCategoryMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/upload-category-photo-video" , data, {
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            dispatch(progressBar(progress))
          },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getCategoryPictureUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(categoryMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(categoryMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(categoryMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };


  export const getCategoryVideoUploadSucess = (data,message) => {
    return {
      type: actionTypes.CATEGORY_VIDEO_UPLOAD_SUCCESS,
      categoryVideo: data.categoryVideo,
      message
    };
  };

  export const categoryVideoUpload = (data) => {
    
    return dispatch => {
      dispatch(startCategoryMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      
      axios
        .post("/admin/upload-category-photo-video" , data,{
          headers: { Authorization: authCode,'content-type': 'multipart/form-data' },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            let progress = Math.round((100 * data.loaded) / data.total)
            
           dispatch(progressBar(progress))
          },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getCategoryVideoUploadSucess(response.data.data,response.data.message))
              dispatch(progressBar(0))
            } else {
              dispatch(categoryMediaFail(response.data.message));
              dispatch(progressBar(0))
            }
          } else {
            dispatch(categoryMediaFail(response.message));
            dispatch(progressBar(0))
          }
        })
        .catch(err => {
          dispatch(categoryMediaFail(err.message));
          dispatch(progressBar(0))
        });
    };
  };



  export const getCategoryPictureDeleteSucess = (data,message) => {
    return {
      type: actionTypes.CATEGORY_PICTURE_DELETE_SUCCESS,
      categoryPicture: data.categoryPicture,
      message
    };
  };

  export const categoryPictureDelete = (data) => {
    return dispatch => {
      dispatch(startCategoryMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-category-photo-video" , data, {
          headers: { Authorization: authCode },
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getCategoryPictureDeleteSucess(response.data.data,response.data.message))
            } else {
              dispatch(categoryMediaFail(response.data.message));
            }
          } else {
            dispatch(categoryMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(categoryMediaFail(err.message));
        });
    };
  };


  export const getCategoryVideoDeleteSucess = (data,message) => {
    return {
      type: actionTypes.CATEGORY_VIDEO_DELETE_SUCCESS,
      categoryVideo: data.categoryVideo,
      message
    };
  };

  export const categoryVideoDelete = (data) => {
    return dispatch => {
      // dispatch(startbussinessMedia());
      const authCode = "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/delete-category-photo-video" , data, {
          headers: { Authorization: authCode},
          
        })
        .then(response => {
          if (response.status === 200) {
            if (response.data.status === 200) { 
              dispatch(getCategoryVideoDeleteSucess(response.data.data,response.data.message))
            } else {
              dispatch(categoryMediaFail(response.data.message));
            }
          } else {
            dispatch(categoryMediaFail(response.message));
          }
        })
        .catch(err => {
          dispatch(categoryMediaFail(err.message));
        });
    };
  };




