import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import {
  Button
} from 'reactstrap'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
// import { Editor } from '@tinymce/tinymce-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class PrivacyPolicy extends React.Component  {
  constructor(props) {
    super(props)
    this.state ={
      content:'',
      errAbout:'',
      isEdit : false
    }
    this.handleEditorChange=this.handleEditorChange.bind(this);
  }
  
  async editTermAndCondition(){
    await this.setState({isEdit : true})
    console.log(this.state.isEdit)
  }
  handleEditorChange(data){
    // console.log('Content was updated:', e.target.getContent());
    // this.setState({content: e.target.getContent()});
    this.setState({
      content : data,
      
    })
  }
  cancelUpdate(){
    this.setState({isEdit :false})
  }
  async componentWillMount() {
    await this.props.fetchpagesList({
      page:"privacy_policy"
    });
    
    if(this.props.pages !== null && this.props.pages.privacy_policy !== undefined){
      console.log(this.props.pages.privacy_policy)
      await this.setState({
        content:this.props.pages.privacy_policy
      })
    }
    
  }
  update () {
      this.props.addPageAction({
        privacy_policy:this.state.content
      },{
        page:"privacy_policy"
      });
      this.cancelUpdate() 
  }
  render(){
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Settings</h6>
                </div>
              </div>

              <ul className='nav nav-pills nav-fill flex-column flex-sm-row' id='tabs-text' role='tablist'>
                <li className='nav-item'>
                  <Link to='/aboutus' className='nav-link mb-sm-3 mb-md-0 ' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>About Us</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/privacypolicy' className='nav-link mb-sm-3 mb-md-0 active' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Privacy Policy</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/termandcondition' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Term & Condition</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contactus' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Contact Us</Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to='/settings' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-1-tab' data-toggle='tab' href='#tabs-text-1' role='tab' aria-controls='tabs-text-1' aria-selected='true'>Multi City Settings</Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='/promocode' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Promo Code</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/subscription' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Subscription</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-6'>
                        <h3 className='mb-0'>Privacy Policy</h3>
                      </div>
                      <div className='col-6'>
                        {/* <h3 className='mb-0'>About Us</h3> */}
                        {/* <Button
                        className="btn-default text-white mr-10 float-right" 

                        onClick={() => this.update()}
                        >Update</Button> */}
                        {this.state.isEdit ? 
                            <div>
                            <button onClick={() =>this.update()}   className="btn btn-primary float-right" type="button">
                            Update
                            </button>
                            <button onClick={() =>this.cancelUpdate()}  className="btn btn-light float-right" style= {{position : "relative`", right : '10px'}} type="button">
                            Cancel
                            </button>
                            </div>
                            :
                          <button onClick={() =>this.editTermAndCondition()} className="btn btn-primary float-right" type="button">
                            Edit Privacy Policy
                          </button>
                          }
                      </div>
                  </div>
                </div>

                {this.state.isEdit ?
                    <div className='card-body'> 
                        <CKEditor
                            editor={ ClassicEditor }
                            data= {this.props.pages.privacy_policy}
                            //  onChange={this.handleEditorChange}
                            onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              this.handleEditorChange(data)
                            }}
                
                        />
                        </div>
                        : 
                   <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-header border-0">
                            <div className="row align-items-center">
                              <div className="col">
                                <div className="content" dangerouslySetInnerHTML={{__html: this.props.pages.privacy_policy}}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    success: state.pages.success,
    error: state.pages.error,
    pages: state.pages.pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchpagesList: (data) => dispatch(actions.fetchpagesListAction(data)),
    addPageAction: (data1,data2) => dispatch(actions.addPage(data1,data2)),
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(PrivacyPolicy));
