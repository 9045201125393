import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { MDBDataTable } from 'mdbreact';
import * as actions from './store/actions/index';

const CouponRankingReport = (props) => {
    const { getCouponRankingReportList, couponRankingReportlisting, getDateWiseReportList, loading } = props;
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [initialLoad, setInitialLoad] = useState(true);
    
    useEffect(() => {
        if (initialLoad) {
            getCouponRankingReportList();
            setInitialLoad(false);
        }
    }, [initialLoad, getCouponRankingReportList]);
    
    useEffect(() => {
        if (Array.isArray(couponRankingReportlisting)) {
            updateData();
        } else {
            console.error('couponRankingReportlisting is not an array', couponRankingReportlisting);
        }
    }, [couponRankingReportlisting]);

    let orderTable = '';



    let updateData = async () => {
        // Debug log
        let SrNo = 1
        let dataList = []
        await couponRankingReportlisting.map((item, index) => {
            dataList.push({
                SrNo: SrNo++,
                bName: item.name,
                bEmail: item.email,
                bContact: item.country_code + item.mobile_no,
                planType: item.PlanName,
                cmClick: item.currentMonthClickCount,
                cmAmount: item.currentMonthClickAmount,
                tCount: item.totalClickCount,
                tAmount: item.totalClickAmount,
                cardSave: item.cardInfo?.isCardSave ? (
                    <button className='btn btn-icon btn-success btn-sm'>
                        <span className='btn-inner--icon'>Yes</span>
                    </button>
                ) : (
                    <button className='btn btn-icon btn-danger btn-sm'>
                        <span className='btn-inner--icon'>No</span>
                    </button>
                ),
                last4: item.cardInfo?.last4 ?? 'xxxx',
                cardExpiry: item.cardInfo ? `${item.cardInfo.exp_month}/${item.cardInfo.exp_year}` : 'xx/xxxx'
            });
        });
        setData(dataList);
    }
    
    const customerData = {
        columns: [
            { label: "Sr.No", field: "SrNo" },
            { label: "Business Name", field: "bName", sort: 'asc' },
            { label: "Business Email", field: "bEmail", sort: 'asc' },
            { label: "Business Contact", field: "bContact", sort: 'asc' },
            { label: "Plan Type", field: "planType", sort: 'asc' },
            { label: "Current month Click", field: "cmClick", sort: 'asc' },
            { label: "Current month amount(in $)", field: "cmAmount", sort: 'asc' },
            { label: "Total Count", field: "tCount", sort: 'asc' },
            { label: "Total amount (in $)", field: "tAmount", sort: 'asc' },
            { label: "Is Card Saved", field: "cardSave" },
            { label: "Last 4", field: "last4", sort: 'asc' },
            { label: "Card Expiry", field: "cardExpiry", sort: 'asc' },
        ],
        rows: data
    };

    orderTable = (
        <MDBDataTable striped bordered hover entries={10} data={customerData} className='table-flush table align-items-center' />
    );

    const handleSubmit = async () => {
        let data = {
            "startDate": startDate,
            "endDate": endDate
        }
        await getDateWiseReportList(data)
    }

    // console.log(couponRankingReportlisting,'====hello====',data)

    return (
        <div className='CouponRankingReport'>
            <div className='header bg-primary pb-6'>
                <div className='container-fluid'>
                    <div className='header-body'>
                        <div className='row align-items-center py-4'>
                            <div className='col-lg-6 col-7'>
                                <h6 className='h2 text-white d-inline-block mb-0'>Coupon Ranking Report</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid mt--6'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row' style={{ maxWidth: '800px', margin: '0 auto' }}>
                            <div className='col-md-5 d-flex flex-column flex-md-row align-items-md-center p-0'>
                                <label className='form-control-label mr-3 mb-1 mb-md-0' htmlFor='from'>From</label>
                                <input type='date' id='from' className='form-control' onChange={(e) => setStartDate(e.target.value)} />
                            </div>

                            <div className='col-md-5 d-flex flex-column flex-md-row align-items-md-center p-0 px-md-3 my-3 my-md-0'>
                                <label className='form-control-label text-nowrap mr-3 mb-1 mb-md-0' htmlFor='to'>To Date</label>
                                <input type='date' id='to' className='form-control' onChange={(e) => setEndDate(e.target.value)} />
                            </div>

                            <div className='col-md-2 d-flex flex-column flex-md-row align-items-md-center p-0'>
                                <button className='w-100 h-100 btn btn-success float-right' type='button' onClick={handleSubmit}>Get Data</button>
                            </div>
                        </div>
                    </div>

                    <div className='card-body'>
                        <div className='table-responsive'>
                            {orderTable}
                        </div>
                    </div>
                    {
                        props.loading ?
                            <div className='loader-wrapper'>
                                <ReactLoading type="spin" color="#0074d9" className='loader-spiner' height={100} width={70} />
                            </div> : ''
                    }
                </div>


                <footer className='footer pt-0'>
                    <div className='row align-items-center justify-content-lg-between'>
                        <div className='col-lg-6'>
                            <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                &copy; 2021  ServiceHubConnect
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    )
}


const mapPropsToState = (state) => {
    return {
        success: state.couponRanking.success,
        error: state.couponRanking.error,
        loading: state.couponRanking.loading,
        couponRankingReportlisting: state.couponRanking.couponRankingReportlisting || []
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCouponRankingReportList: () => dispatch(actions.getCouponRankingReportList()),
        getDateWiseReportList: (data) => dispatch(actions.getDateWiseReportList(data))
    }
}

export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(CouponRankingReport)))
