import React from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import * as actions from './store/actions/index'
import {
    Input,
} from 'reactstrap'
import LinearProgress from "@material-ui/core/LinearProgress";
import PhoneInput from 'react-phone-input-2'
import data from './data.json'
import { withRouter } from "react-router-dom";
import Map from './map'
import worldMapData from 'city-state-country'
import ReactPlayer from 'react-player'



class AdvVideo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            business_id: '',
            page_type: '',
            formErrpageType: '',
            video_type:'',
            formErrvideoType: '',
            subCategoryId: '',
            formErrsubcategory: '',
            location: '',
            formErrlocation: '',
            longitude: 23.53,
            latitude: 53.65,
            video: '',
            formErrvideo: '',
            file: '',

        }
        this.deleteVideo = this.deleteVideo.bind(this)
        this.handlelatlong = this.handlelatlong.bind(this);
        this.handleUploadFile1 = this.handleUploadFile1.bind(this);
    }

    async updateState(data) {
        // await this.setState(data);
        setTimeout(async () => {
            console.log('Our data is fetched');
            await this.setState(data)
        }, 1000)
    }
    handleUploadFile1 = async (event) => {
        await this.setState({
            file: URL.createObjectURL(event.target.files[0]),
            video: event.target.files[0],
            formErrvideo: ''
        })
    }
    onChangeAddName = async (key, value) => {
        var self = this;
        await self.setState({
            [key]: value,
            ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
        })

        if (key === "page_type") {
            await self.setState({
                page_type: value,
                formErrpageType: ''
            })
        }
        if (key === "subcategory") {
            await self.setState({
                subCategoryId: value,
                formErrsubcategory: ''
            })
        }

        if (key === "country") {
            // await this.props.fetchcity("600921c703e76716847fac0c")
            // await this.props.fetchcity(value)
            let result = await this.state.country_list.filter(function (item) {
                if (item.sortname == value) return item.name;
            });
            if (result.length) {
                await this.setState({
                    country_name: result[0].name
                })

            }
        }

    }
    handleSubmit = async () => {
        await this.setState({
            formErrvideo: (this.state.video === '') ? "Please provide this field" : '',
            formErrvideoType: (this.state.video_type === '') ? "Please provide this field" : '',
            formErrlocation: (this.state.location === '') ? "Please provide this field" : '',
            formErrpageType: (this.state.page_type === '') ? "Please provide this field" : '',
            formErrsubcategory: (this.state.subCategoryId === '') ? "Please provide this field" : '',
        })

        if (this.state.video !== '' && this.state.subCategoryId !== '' &&
         this.state.page_type !== '' && this.state.business_id !== '' && this.state.video_type) {

            const data = new FormData();
            data.append('businessId', this.state.business_id);
            data.append('page_type', this.state.page_type);
            data.append('video_type', this.state.video_type);
            data.append('subCategoryId', this.state.subCategoryId);
            data.append('latitude', this.state.latitude);
            data.append('longitude', this.state.longitude);
            data.append('video', this.state.video);
            await this.props.advertisementVideoUpload(data);
            await this.setState({
                page_type: '',
                video: '',
                file: '',
            })

        }
    }
    UNSAFE_componentWillMount = async () => {
        // await this.props.fetchcountry()
        // await this.props.fetchcountry()
        // await this.setState({
        //   country_list:worldMapData.getAllCountries()
        // })
    }

    handleAddress = (value) => {
        this.setState({
            location: value
        })
    }

    async componentDidMount() {
        // await this.props.fetchcountry()
        const country_array = await worldMapData.getAllCountries()

        await this.setState({
            country_list: country_array
        })
    }
    componentDidMount = async () => {
        let business_id = await this.props.match.params.id;
        if (business_id) {
            // console.log(business_id);
            await this.setState({
                business_id: business_id,
                // category_name:this.props.match.params.name
            });
            await this.props.getsubCategoryList(business_id)
            await this.props.getAdvertisementPictures(business_id)
            if (this.props.subCategoryList.length === 1) {
                this.setState({
                    subCategoryId: this.props.subCategoryList[0].subcatgoryInfo._id
                })
            }
        }
    };
    componentDidUpdate(prevProps, prevState) {
        const { enqueueSnackbar, error, success } = this.props;
        success && enqueueSnackbar(success, { variant: 'success' });
        error && enqueueSnackbar(error, { variant: 'error' });
    }
    handlelatlong = (value) => {
        // const formvalue = { ...formValues }
        // formvalue.address.x_coordinate = value.latitude
        // formvalue.address.y_coordinate = value.longitude
        // setFormValue(formvalue)
        this.setState({
            latitude: value.latitude,
            longitude: value.longitude
        })
  console.log(value.latitude,value.longitude)
    }
    handlepincode = (value) => {

    }
    handlecityname = (value) => {
  
    }

    deleteVideo = async (e, videoId) => {
        e.preventDefault()
        let data = {
            videoId: videoId,
            businessId: this.state.business_id
        }
        await this.props.advertisementVideoDelete(data)
    }


    cancelUpdate() {
        this.setState({ isEdit1: false })
    }

    render() {

        let business_id = this.props.match.params.id;
        // console.log(this.props.advertisementVideo,'ppppppppppppp')
        // slotOpenHour: getData.bussiness_info.open_time.split(":")[0],
        // slotOpenMin: getData.bussiness_info.open_time.split(":")[1],
        // slotCloseHour: getData.bussiness_info.close_time.split(":")[0],
        // slotCloseMin: getData.bussiness_info.close_time.split(":")[1],
        return (
            <div>
                <div className='header bg-primary pb-6'>
                    <div className='container-fluid'>
                        <div className='header-body'>
                            <div className='row align-items-center py-4'>
                                <div className='col-lg-6 col-7'>
                                    <h6 className='h2 text-white d-inline-block mb-0'>Add Advertisment Videos</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-fluid mt--6'>
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='clearfix'>
                                        <form>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Select Page Type</label>
                                                        <Input
                                                            value={this.state.page_type}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('page_type', e.target.value)}
                                                        >
                                                            {['first', 'second', 'third'].map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrpageType}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Select Subcategory</label>
                                                        <Input
                                                            value={this.state.subCategoryId}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('subcategory', e.target.value)}
                                                        >
                                                            {this.props.subCategoryList.map((option) => (

                                                                <option value={option.subcatgoryInfo._id}>{option.subcatgoryInfo.name}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrsubcategory}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Select Video Type</label>
                                                        <Input
                                                            value={this.state.video_type}
                                                            type="select"
                                                            onChange={(e) => this.onChangeAddName('video_type', e.target.value)}
                                                        >
                                                            {['banner','square'].map((option) => (
                                                                <option value={option}>{option}</option>
                                                            ))}
                                                        </Input>
                                                        <span style={{ color: 'red' }}>{this.state.formErrvideoType}</span>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='text-center'>
                                                        <img style={{ maxWidth: "350px", margin: "0 auto" }}
                                                            src={
                                                                this.state.file === ''
                                                                    ? '../assets/img/theme/img-1-1000x600.jpg'
                                                                    : this.state.file
                                                            }
                                                            alt='upload video here'
                                                            className='card-img-top'
                                                        />
                                                    </div>
                                                    <span style={{ color: 'red' }}>
                                                        {this.state.formErrvideo}
                                                    </span>
                                                    <div className='d-flex justify-content-between pt-8 pt-md-4 pb-0 pb-md-4'>
                                                        <Input
                                                            type='file'
                                                            // id="File-2"
                                                            className='btn btn-default float-right'
                                                            onChange={this.handleUploadFile1}
                                                        >
                                                            Upload
                                                        </Input>

                                                    </div>
                                                    <div>
                                                    {this.props.progress > 0 ? <LinearProgress variant="determinate" value={this.props.progress} /> : ''}
                                                    </div>

                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='form-group'>
                                                        <label className='form-control-label' for='input-first-name'>Business Location</label>
                                                        <input type='text' id='input-first-name' className='form-control' placeholder='Business Location'
                                                            value={this.state.location} onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                                        />
                                                        <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: '500px',
                                                            width: '100%'
                                                        }}
                                                    >
                                                        <Map
                                                            center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                            handlelatlong={(e) => this.handlelatlong(e)}
                                                            handleAddress={(e) => this.handleAddress(e)}
                                                            handlepincode={(e) => this.handlepincode(e)}
                                                            handlecityname={(e) => this.handlecityname(e)}
                                                            height='400px'
                                                            value={this.state.location}
                                                            // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                                                            zoom={15}
                                                        />
                                                    </div>
                                                    <span style={{ color: 'red' }}>{this.state.formErrlocation}</span>
                                                </div>
                                            </div>
                                            <button
                                                className='btn btn-success float-right'
                                                type='button'
                                                onClick={(e) => this.handleSubmit()}
                                            >Save</button>
                                            <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                                        </form>
                                    </div>
                                    <div className='img-history my-4'>
                                        <h1 className='mb-4'>Uploaded Videos</h1>
                                        <div className='row'>
                                            {this.props.advertisementVideo.map((key) => (
                                                  <div className='col-md-6'>
                                                  <div className='image-cover overflow-hidden position-relative'>
                                                  <ReactPlayer url= {key.video_url} width='100%' height='300px' className='video-cover' controls = {true}/>
                                                        <div className='d-flex justify-content-between w-100 bg-primary bg-gradient text-white p-3 position-sticky bottom-0 start-0'>
                                                          <span className='mr-2'>Page No:- {key.page_type}</span> 
                                                          <span className='mr-2'>Video Type:- {key.video_type}</span> 
                                                          <button class="btn btn-icon btn-danger btn-sm" type="button"><span class="btn-inner--icon"onClick={(e) => this.deleteVideo(e, key._id)}><i class="fas fa-trash"></i></span></button>
                                                        </div> 
                                                  </div>
                                              </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className='footer pt-0'>
                        <div className='row align-items-center justify-content-lg-between'>
                            <div className='col-lg-6'>
                                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                                    &copy; 2021  ServiceHubConnect
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
        )

    }
}



const mapPropsToState = (state) => {
    return {
        success: state.advertisementPicture.success,
        error: state.advertisementPicture.error,
        subCategoryList: state.advertisementPicture.subcategoryList,
        advertisementVideo: state.advertisementPicture.advertisementVideo,
        progress: state.advertisementPicture.progress,
        // countrylisting: state.country.countrylisting,
        // citylisting: state.city.citylisting,
        // data_bussiness: state.bussiness.data_bussiness,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getsubCategoryList: (data) => dispatch(actions.getSubcategoryList(data)),
        getAdvertisementPictures: (data) => dispatch(actions.getAdvertisementVideoOnId(data)),
        advertisementVideoUpload: (data) => dispatch(actions.advertisementVideoUpload(data)),
        advertisementVideoDelete: (data) => dispatch(actions.advertisementVideoDelete(data)),

        // addBusinessAction: (data, history, datas) => dispatch(actions.addBusiness(data, history, datas)),
        // updateBusinessLogoAction: (data, business_id) => dispatch(actions.updateBusinessLogo(data, business_id)),
        // fetchallsubCategory: () => dispatch(actions.fetchallsubCategoryListAction()),
        // fetchbussiness: () => dispatch(actions.fetchbussinessListAction()),
        // fetchcountry: () => dispatch(actions.fetchcountrysListAction()),
        // fetchcity: (data) => dispatch(actions.fetchcitysListAction(data)),
        // getbusinessOnIdAction: (data) => dispatch(actions.getbusinessOnId(data)),
        // editBusinessAction: (business_id, data, history) => dispatch(actions.editBusiness(business_id, data, history)),
        // editrideshareAction: (id, data) => dispatch(actions.editrideshare(id, data)),
        // editrideshareImageAction: (id, data) => dispatch(actions.editrideshareImage(id, data)),

        // addrideshareDataAction: (data, history) => dispatch(actions.addrideshareData(data, history)),

    }
}


export default connect(
    mapPropsToState,
    mapDispatchToProps
)(withSnackbar(withRouter(AdvVideo)))

