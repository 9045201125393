import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import {
  Button
} from 'reactstrap'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as actions from "./store/actions/index";
import { Editor } from '@tinymce/tinymce-react';
import Map from './map'
import PhoneInput from 'react-phone-input-2'

class ConatctUs extends React.Component {
  constructor(props) {
    super(props)
    this.state ={
      longitude:0,
      latitude:0,
      address: "",
      email: "",
      country_code: "",
      contact_Number: "",
      formErrlongitude:'',
      formErrlatitude:'',
      formErraddress: "",
      formErremail: "",
      formErrcountry_code: "",
      formErrcontact_Number: ""
  }
  }
  handlelatlong = (value) => {
    // const formvalue = { ...formValues }
    // formvalue.address.x_coordinate = value.latitude
    // formvalue.address.y_coordinate = value.longitude
    // setFormValue(formvalue)
    this.setState({
      latitude:value.latitude,
      longitude:value.longitude
    })
  }
  handleAddress = (value) => {
    console.log(value)
    this.setState({
      address:value
    })
  }
  handlepincode = (value)=>{
    
  }
  handlecityname = (value)=>{
    
  }
  componentWillMount() {
    this.props.getConactUsAction();
    
    if(this.props.contact !== null){
      console.log(this.props.contact)
      const data = this.props.contact
      this.setState({
        longitude:data.location.coordinates[0],
        latitude:data.location.coordinates[1],
        address: data.address,
        email: data.email,
        country_code: data.country_code,
       contact_Number: data.contact_Number
    }  )
    }
    
  }
  onChangeAddName = async (key, value) => {

    var self = this;
    await self.setState({
      [key]: value,
      ['formErr' + key]: (value === '') ? 'Please provide this field' : ''
    })
  }
  handleSubmit = async () => {
    await this.setState({
      formErrlongitude: (this.state.longitude === '') ? "Please provide this field" : '',
      formErrlatitude: (this.state.latitude === '') ? "Please provide this field" : '',
      formErraddress: (this.state.address === '') ? "Please provide this field" : '',
      formErremail: (this.state.email === '') ? "Please provide this field" : '',
      formErrcountry_code: (this.state.country_code === '') ? "Please provide this field" : '',
      formErrcontact_Number: (this.state.contact_Number === '') ? "Please provide this field" : '',
    })

    if (!((this.state.longitude === '') && (this.state.latitude === '') && (this.state.address === '') && (this.state.email === '') && (this.state.country_code === '') && (this.state.contact_Number === ''))) {
      const data = {
        longitude:this.state.longitude,
        latitude:this.state.latitude,
        address: this.state.address,
        email: this.state.email,
        country_code: this.state.country_code,
        contact_Number: this.state.contact_Number
    }
      this.props.addConactUsAction(data)
    }
  }
  render(){
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Settings</h6>
                </div>
              </div>
  
              <ul className='nav nav-pills nav-fill flex-column flex-sm-row' id='tabs-text' role='tablist'>
                <li className='nav-item'>
                  <Link to='/aboutus' className='nav-link mb-sm-3 mb-md-0 ' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>About Us</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/privacypolicy' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Privacy Policy</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/termandcondition' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Term & Condition</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contactus' className='nav-link mb-sm-3 mb-md-0 active' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Contact Us</Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to='/settings' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-1-tab' data-toggle='tab' href='#tabs-text-1' role='tab' aria-controls='tabs-text-1' aria-selected='true'>Multi City Settings</Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='/promocode' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Promo Code</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/subscription' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Subscription</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-6'>
                      <h3 className='mb-0'>Contact Us</h3>
                    </div>
                  </div>
                </div>
  
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div style={{ height: 400, width: '100%', border: '1px solid #000' }}>
                          <Map
                            center={{ lat: this.state.latitude, lng: this.state.longitude }}
                            handlelatlong={(e)=>this.handlelatlong(e)}
                            handleAddress={(e)=>this.handleAddress(e)}
                            handlepincode={(e)=>this.handlepincode(e)}
                            handlecityname={(e)=>this.handlecityname(e)}
                            height='400px'
                            value={this.state.address}
                            // onChange={(e) => this.onChangeAddName('location', e.target.value)}
                            zoom={15}
                          />
                      </div>
                      <span style={{ color: 'red' }}>{this.state.formErrlongitude}</span>
                      <span style={{ color: 'red' }}>{this.state.formErrlatitude}</span>

                    </div>
  
                    <div className='col'>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-username'>Phone Number</label>
                            <PhoneInput
                              country={'us'}
                              value={this.state.country_code}
                              // onChange={(e) => this.onChangeAddName('country_code', e.target.value)}
                              onChange={country_code => this.setState({ country_code:'+'+country_code })}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcountry_code}</span>
                            <input type='text' id='input-username' className='form-control' placeholder='Phone Number' 
                              value={this.state.contact_Number}
                              onChange={(e) => this.onChangeAddName('contact_Number', e.target.value)}
                            />
                            <span style={{ color: 'red' }}>{this.state.formErrcontact_Number}</span>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className='form-group'>
                            <label className='form-control-label' for='input-email'>Email</label>
                            <input type='email' id='input-email' className='form-control' placeholder='Email'
                              value={this.state.email}
                              onChange={(e) => this.onChangeAddName('email', e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='form-group'>
                            <label className='form-control-label'>Professional Description</label>
                            {/* <input rows='4' className='form-control' placeholder='A few words about you ...'
                            value={this.state.address}
                            /> */}
                            <input type='text' id='input-first-name' className='form-control' placeholder='Business Location' 
                            value={this.state.address} 
                            onChange={(e) => this.onChangeAddName('address', e.target.value)}
                            />
                          <span style={{ color: 'red' }}>{this.state.formErraddress}</span>

                          </div>
                        </div>
                      </div>
  
                      <button className='btn btn-success float-right' type='button'
                        onClick={(e) => this.handleSubmit()}
                      >Update</button>
                      <button className='btn btn-default float-right mr-4' type='button'>Cancel</button>
                    </div>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }

}
const mapPropsToState = (state) => {
  return {
    success: state.pages.success,
    error: state.pages.error,
    contact: state.pages.contact
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConactUsAction: () => dispatch(actions.getConactUs()),
    addConactUsAction: (data1) => dispatch(actions.addConactUs(data1)),
    
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(ConatctUs));
