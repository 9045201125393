import React from 'react'
import * as actions from './store/actions/index'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
import { 
  Button , 
  Modal ,
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  FormGroup,
	Label,
	Input,
} from 'reactstrap'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class SubCategory extends React.Component {
  constructor(props){
    super(props)
    this.state={
      subCategoryName : '',
      open:false,
      formErr:'',
      category_id:'',
      category_name:'',
      openDialgue:false,
      subcategoryId:'',
      formErrImage:'',
      file:'',
      sub_category_images:'',
      locality:'',
      errLocality:'',
      short_name:'',
      formErrshort_name:''
    }
		this.handleUploadFile = this.handleUploadFile.bind(this)
  }
  componentWillMount () {
    this.props.fetchSubCategory(this.state.category_id)
  }

  componentDidMount() {
    let category_id = this.props.match.params.id;
    if (category_id) {
        
        this.props.fetchSubCategory(category_id)
        this.setState({ 
            category_id:category_id,
            category_name:this.props.match.params.name
        });
    }
  }

  onChangeAddName= async (key,value)=>{
    var self=this;
    await self.setState({
      [key]: value
    })
    console.log(key)
    if(key == "locality"){
      await this.setState({
        errLocality:""
      })
    }
    else if(key == "subCategoryName"){
      await this.setState({
        formErr:""
      })
    }
  }
  handleSubmit = async()=>{
    if(this.state.sub_category_images == ''){
      await this.setState({
        formErrImage:"Please provide a Image"
      })
    }
    else if(this.state.locality == ''){
      await this.setState({
        errLocality:"Please provide locality"
      })
    }
    else if(this.state.subCategoryName == ''){
      await this.setState({
        formErr:"Please provide a valid category"
      })
    }
    else if(this.state.subCategoryName !== ''){
      await this.setState({
        formErr:""
      })
      
      if(this.state.subcategoryId){
        console.log("Update code here")
        this.props.subCategoryUpdate({subCategoryId:this.state.subcategoryId,name:this.state.subCategoryName,locality:this.state.locality,short_name:this.state.short_name},this.state.category_id);

      }
      else{
        // this.props.addSubCategory({name:this.state.subCategoryName,category_id:this.state.category_id})
        
        const data = new FormData();
        
        if(this.state.sub_category_images && this.state.subCategoryName){
          data.append('name', this.state.subCategoryName);
          data.append('category_id', this.state.category_id);
          data.append('sub_category_images', this.state.sub_category_images);
			    data.append('locality', this.state.locality);
			    data.append('short_name', this.state.short_name);
          this.props.addSubCategory(data,this.state.category_id);
        }
      } 
      this.close()
    }
  }
  // handleOpen = () => {
    
  //   this.setState({
  //     open:true 
  //   });
  
  // }
  handleOpen = (id,name,sub_category_images,locality,short_name) => {
    if(id !== ""){
      this.setState({
        subcategoryId:id,
        subCategoryName:name,
        sub_category_images:sub_category_images,
        file:sub_category_images,
        locality:locality,
        short_name:short_name
      })
    }else{
      this.setState({
        subcategoryId:"",
        subCategoryName:"",
        sub_category_images:"",
        file:'',
        locality:'',
        short_name:''
      })
    }
    this.setState({
      open:true 
    });
  
  }
  async close() {
    await this.setState({ 
      open: false,
      subCategoryName:'',
      formErr:'',
      formErrImage:'',
      sub_category_images:"",
      file:'',
      locality:'',
      short_name:''
    }); 
  }
  handleOpenDelete = (id) => {
    
		if(id) {

      this.setState({ 
        subcategoryId : id,
        openDialgue:true 
      });
		}
	
  }
  closeDialgue() {
    this.setState({ openDialgue: false });
  }
  deleteUserPermanently() {
		if(this.state.subcategoryId) {
			this.props.subCategoryDelete(this.state.subcategoryId,this.state.category_id);
      this.closeDialgue()
      this.setState({ subcategoryId: '' });
		}
  }
  handleUploadFile = async(event) => {
      await this.setState({file: URL.createObjectURL(event.target.files[0]),sub_category_images:event.target.files[0],formErrImage:''})
      
      if(this.state.subcategoryId) {
        const data = new FormData();  
        data.append('sub_category_images', this.state.sub_category_images);
        // name:this.state.categoryName,categoryId:this.state.categoryId
        this.props.subCategoryUpdateImage(data,this.state.category_id,this.state.subcategoryId)
        
      }
  }
  render () {
    const { open } = this.state;
    var sno = 1
    let categoryList = [];
    let categoryTable = <Spinner />;
    this.props.subcategorylisting.length > 0
      ? this.props.subcategorylisting.map(datas => {
        categoryList.push(
          {
            s_no:(sno++),
            cimage:
            <img src={datas.sub_category_images} className="rounded-circle mb-15" width="100" height="100"></img>,
            locality:(datas.locality == "global")?"Global":(datas.locality == "local")?"Local":"",
            subCategoryName:datas.name,
            short_name:datas.short_name,
            action:<div>
              <Button className='btn btn-icon btn-info btn-sm' onClick={(data_id,name,sub_category_images,locality) =>this.handleOpen(datas._id,datas.name,datas.sub_category_images,datas.locality,datas.short_name)}>
                <span class='btn-inner--icon'><i class='fas fa-pencil-alt' /></span>
              </Button>
              <Button className='btn btn-icon btn-danger btn-sm' onClick={(id) =>this.handleOpenDelete(datas._id)}>
                <span class='btn-inner--icon'><i class='fas fa-trash' /></span>
              </Button>
            </div>

          }
        )
      })

      : categoryList.push(
        ['No record']
      )
    const data = {
      columns: [
        {
          label: "Sr.No",
          field: "s_no",
          sort: "asc",
        },
        {
          label: "Sub Category Image",
          field: "cimage",
          sort: "asc",
        },
        {
          label: "short name",
          field: "short_name",
          sort: "asc",
        },
        
        {
          label: "Locality",
          field: "locality",
          sort: "asc",
        },
        {
          label: "Sub Category Name",
          field: "subCategoryName",
          sort: "asc",
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
        },
      ],
      rows: categoryList,
    };

    categoryTable = (
      <MDBDataTable striped bordered hover entries={10} data={data} />
    );
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Manage Business Sub Categories- {this.state.category_name}</h6>
                </div>
              </div>
              <Dialog
                open={this.state.openDialgue}
                onClose={() => this.closeDialgue()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">Do you want to delete {this.state.category_name} Sub Category?</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <button class='btn btn-icon btn-danger btn-sm' type='button' onClick={() => this.closeDialgue()}>
                            No
                  </button>
                  <button class='btn btn-icon btn-success btn-sm' onClick={()=>this.deleteUserPermanently()} type='button'>
                            Yes
                  </button>
                </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>

        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header border-0'>
                  <div className='row align-items-center py-4'>
                    <div className='col-lg-6' />
                    <div className='col-lg-6'>
                      <Button className='btn btn-primary float-right' onClick={(id,name) =>this.handleOpen("","","","")}>Add Sub Category</Button>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {categoryTable}
                  </div>

                </div>
              </div>
            </div>
          </div>
                        <Modal
                            isOpen={open}
                            toggle={() => this.close()}
                        >
                            <ModalHeader toggle={() => this.close()}>
                              <Label for="title">Add Category</Label>      
                            </ModalHeader>
                            <ModalBody>
                                  <FormGroup>
                                    <img src={this.state.sub_category_images == ''?'../../assets/img/noimage.png':this.state.file}  alt="user prof" className="rounded-circle mb-15" width="100" height="100" />
                                    <br/>
                                    <Label for="File-2">Upload Image</Label>
                                    <Input type="file" name="sub_category_images" id="File-2" 
                                    onChange={this.handleUploadFile}
                                    />
                                    <span style={{color:'red'}}>{this.state.formErrImage}</span>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="about">Locality</Label>

                                  <Input value={this.state.locality} type="select" onChange={(e) => this.onChangeAddName('locality', e.target.value)}>
                                    <option value="">Select Locality</option>
                                    <option value="global" >Global</option>
                                    <option value="local">Local</option>
                                  </Input>
                                  <span style={{color:"red"}}>{this.state.errLocality}</span>
                              </FormGroup>
                                <FormGroup>
                                    <Input type="text" name="link" id="link" placeholder="Sub Category Name" value={this.state.subCategoryName} onChange={(e)=>this.onChangeAddName('subCategoryName',e.target.value)} />
																		<span style={{color:'red'}}>{this.state.formErr}</span>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="text" name="link" id="link" placeholder="short name" value={this.state.short_name} onChange={(e)=>this.onChangeAddName('short_name',e.target.value)} />
																		<span style={{color:'red'}}>{this.state.formErrshort_name}</span>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
															
                                <Button variant="raised" className="btn-success text-white mr-10"  onClick={(e)=>this.handleSubmit()}>{this.state.subcategoryId !== ''?"Update":"Save"}</Button>
                                    <Button variant="raised" onClick={()=>this.close()} className="btn-danger text-white mr-10">Cancel</Button>
                            </ModalFooter>
			            </Modal>

          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    subcategorylisting: state.subCategory.subcategorylisting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchSubCategory: (data) => dispatch(actions.fetchsubCategoryListAction(data)),
    addSubCategory: (data, category_id) => dispatch(actions.addsubCategoryAction(data,category_id)),
    subCategoryDelete: (data,category_id) =>dispatch(actions.subCategoryDeleteAction(data,category_id)),
    subCategoryUpdate: (data,category_id) =>dispatch(actions.subCategoryUpdateAction(data,category_id)),
    subCategoryUpdateImage: (data,category_id,subcategoryId) =>dispatch(actions.subCategoryUpdateImageAction(data,category_id,subcategoryId)),

  }
}

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(SubCategory))
