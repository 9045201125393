import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  subscriptionPlanlist:[],
  subscriptionPlanDetails:{},
  subscribedUserList:[]
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_ADVERTISEMENT_SUBSCRIPTION_PLAN:
      return {
          ...state,
          loading:true,
          error:null
      };
    case actionTypes.ADVERTISEMENT_SUBSCRIPTION_PLAN_FAIL :
        return {
            ...state,
            loading:null,
            error:action.message,
            success:null,
        }
    case actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_SUCCESS :
      return {
          ...state,
          loading:null,
          subscriptionPlanlist: action.subscriptionPlanlist,
          error:null,
          success:action.message,
          
      }
      case actionTypes.GET_ADVERTISEMENT_SUBSCRIPTION_PLAN_DETAILS :
        return {
            ...state,
            loading:null,
            subscriptionPlanDetails: action.subscriptionPlanDetails,
            error:null,
            success:action.message,
            
        }
      case actionTypes.GET_ADVERTISEMENT_SUBSCRIBED_USER_LIST :
      return {
          ...state,
          loading:null,
          subscribedUserList: action.subscribedUserList,
          error:null,
          success:action.message,
      }
    default:
      return state;
  }
};

export default reducer;
