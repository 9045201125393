import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import MUIDataTable from "mui-datatables";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Spinner from "./Spinner/Spinner";
import { MDBDataTable } from "mdbreact";
class FirstOrderDiscount extends React.Component {
  componentWillMount() {
    this.props.fetchprmocodeAction();
  }
  
  render(){
    var sno = 1

    let promoCodeList = [];
    let promoCodeTable = <Spinner />;
    
    {this.props.promoCode.length > 0?
      this.props.promoCode.map(datas=>{
        
        promoCodeList.push(
          {
        
            s_no:(sno++),
            promoCode:datas.promoCode,
            name:(datas.data)?datas.data.name:'',
            expiry_date:datas.expiry_date,
            read_status:datas.read_status,
            status:datas.expiry_date,
          // datas.country,
          type:(datas.type === null)?'':datas.type
          }
        )
      })
      
      :promoCodeList.push(
        ["No record"]
        )}
        const data = {
          columns: [
            {
              label: "Sr.No",
              field: "s_no",
              sort: "asc",
            },
            {
              label: "Promo Code",
              field: "promoCode",
              sort: "asc",
            },
            {
              label: "full name",
              field: "name",
              sort: "asc",
            },
            {
              label: "Expiry Date",
              field: "expiry_date",
              sort: "asc",
            },
            {
              label: "Status",
              field: "read_status",
              sort: "asc",
            },
            // {
            //   label: "READ STATUS",
            //   field: "read_status",
            //   sort: "asc",
            // },
            // {
            //   label: "Type",
            //   field: "type",
            //   sort: "asc",
            // },
            
          ],
          rows: promoCodeList,
        };
    
        promoCodeTable = (
          <MDBDataTable striped bordered hover entries={10} data={data} />
        );
    console.log(this.props.promoCode)
    return (
      <div>
        <div className='header bg-primary pb-6'>
          <div className='container-fluid'>
            <div className='header-body'>
              <div className='row align-items-center py-4'>
                <div className='col-lg-6 col-7'>
                  <h6 className='h2 text-white d-inline-block mb-0'>Settings</h6>
                </div>
              </div>
  
              <ul className='nav nav-pills nav-fill flex-column flex-sm-row' id='tabs-text' role='tablist'>
                <li className='nav-item'>
                  <Link to='/aboutus' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>About Us</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/privacypolicy' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Privacy Policy</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/termandcondition' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Term & Condition</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/contactus' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>Contact Us</Link>
                </li>
                {/* <li className='nav-item'>
                  <Link to='/settings' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-1-tab' data-toggle='tab' href='#tabs-text-1' role='tab' aria-controls='tabs-text-1' aria-selected='true'>Multi City Settings</Link>
                </li> */}
                <li className='nav-item'>
                  <Link to='/firstorderdiscount' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-2-tab' data-toggle='tab' href='#tabs-text-2' role='tab' aria-controls='tabs-text-2' aria-selected='false'>First Order Discount</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/promocode' className='nav-link mb-sm-3 mb-md-0 active' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Promo Code</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/subscription' className='nav-link mb-sm-3 mb-md-0' id='tabs-text-3-tab' data-toggle='tab' href='#tabs-text-3' role='tab' aria-controls='tabs-text-3' aria-selected='false'>Subscription</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
  
        <div className='container-fluid mt--6'>
          <div className='row'>
            <div className='col'>
              <div className='card'>
                <div className='card-header'>
                  <div className='row align-items-center'>
                    <div className='col-6'>
                      <h3 className='mb-0'>Promo Code</h3>
                    </div>
                  </div>
                </div>
  
                <div className='card-body'>
  
                  <div className='table-responsive'>
                    <table className='table align-items-center table-flush'>
                      {promoCodeTable}
                    </table>
                  </div>
                </div>
  
              </div>
            </div>
          </div>
  
          <footer className='footer pt-0'>
            <div className='row align-items-center justify-content-lg-between'>
              <div className='col-lg-6'>
                <div className='copyright text-center text-lg-left  text-muted mt-4 ml-4'>
                &copy; 2021  ServiceHubConnect 
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
const mapPropsToState = (state) => {
  return {
    success: state.pages.success,
    error: state.pages.error,
    promoCode: state.pages.promoCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchprmocodeAction: () => dispatch(actions.fetchprmocode()),
  };
};

export default connect(
  mapPropsToState,
  mapDispatchToProps
)(withSnackbar(FirstOrderDiscount));
